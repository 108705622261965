import React, {Component} from "react";

import {compose} from '../../../utils';
import {withTLService} from "../../hoc";
import {withTranslation} from "react-i18next";
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import {Button, Form, Alert} from 'react-bootstrap'
import {loginUser} from "../../../actions";
import {Redirect} from "react-router-dom";
import LangSet from "../../elements/lang-set";

import './login.css'

class Login extends Component {

    state = {
        login: '',
        password: '',
        remember: true,
        submitted: false,
    };

    componentDidMount() {

        /*
        const token = localStorage.getItem('token');
        if (token && token !== 'null') {
            // this.props.meUser(token);
        } else {
            // this.props.clearUser();
        }
        */
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        this.setState({submitted: true});
        this.setState({message: ''});
        const {login, password, remember} = this.state;
        if (login && password) {
            this.props.loginUser(login, password, remember);
        }
    };

    handleChange = (event) => {
        const {name, value} = event.target;
        this.setState({[name]: value});
        this.setState({submitted: false});
    };
    handleChangeCheckBox = () => {
        this.setState({remember: !this.state.remember});
        this.setState({submitted: false});
    };


    /*
        validateEmail(email) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        }
    */

    render() {
        const {login, password, submitted, remember} = this.state;
        const {t: translate, userData: {token, error }} = this.props;
        if (token) {
            return <Redirect to="/"/>;
        }
        return (
            <div className="container  h-100 my-auto mx-auto">
                <div className="row my-auto  h-100 mx-auto">
                    <div className="col-xl-8 col-lg-9 col-md-7 my-auto  mx-auto">
                        <div className="card o-hidden border-0 shadow-lg my-5">
                            <div className="card-body p-0">
                                <div className="p-5">
                                    <div className="ml-auto  col-3 ">
                                        <LangSet {...this.props}/>
                                    </div>
                                    <div className="text-center">
                                        <h1 className="h4 text-gray-900 mb-4">{translate('Sign In Form')}</h1>
                                    </div>
                                    <Form
                                        name="loginForm"
                                        className="user"
                                        onSubmit={this.handleSubmit}>
                                        <Form.Group controlId="loginLogin">
                                            <Form.Control
                                                isInvalid={submitted && !login && true}
                                                value={login}
                                                onChange={this.handleChange}
                                                name="login"
                                                type="text"
                                                placeholder={translate('Login login placeholder')}/>
                                        </Form.Group>
                                        <Form.Group controlId="loginPassword">
                                            <Form.Control
                                                isInvalid={submitted && !password && true}
                                                value={password}
                                                onChange={this.handleChange}
                                                name="password"
                                                type="password"
                                                placeholder={translate('Login password placeholder')}/>
                                        </Form.Group>

                                        <Form.Group controlId="loginRemember">
                                            <Form.Check
                                                checked={remember}
                                                onChange={this.handleChangeCheckBox}
                                                type="checkbox"
                                                name="remember"
                                                label={translate('Remember me')}/>
                                        </Form.Group>
                                        {submitted && !token &&
                                        <Alert variant="danger">{error && error.message && error.message}</Alert>}
                                        <Button
                                            type="submit"
                                            disabled={submitted ? "disabled" : false}
                                            className="btn btn-primary btn-user btn-block">
                                            {translate('Login button')}
                                        </Button>
                                    </Form>
                                    <hr/>
                                    <div className="text-center">
                                        <a className="small"
                                           href="/">{translate('Forgot Password?')}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({userData}) => {
    return {userData};
};

const mapDispatchToProps = (dispatch, {tlService}) => {
    return bindActionCreators({
        loginUser: loginUser(tlService)
    }, dispatch);
};

export default compose(
    withTranslation(),
    withTLService(),
    connect(mapStateToProps, mapDispatchToProps)
)(Login);
