import React from "react";
import {
    checkUserRight,
    compose,
    convertLanguagesToFull,
    createDefaultMultilangObject, getAllModalFunctions,
} from "../../../utils";

import {withTranslation} from "react-i18next";
import {withTLService} from "../../hoc";
import {connect} from "react-redux";
import {Button, Col, Row} from "react-bootstrap";
import DataViewer from "../../elements/data-viewer";
import {bindActionCreators} from "redux";


import {userGetAllTermCategories} from "../../../actions";
import ModalAddEditTermCategory from "../../modals/modal-add-edit-term-category";
import {Redirect} from "react-router-dom";
import ModalCloneTermCategory from "../../modals/modal-clone-term-category";
import backendLanguages from "../../../utils/backendlanguages.json";

class Terms extends DataViewer {

    /* table filters */
    tableFilters = {
        deleted: {
            name: 'Show deleted elements',
            type: 'integer',
            default: 0
        },
        category_id: {
            name: 'Category id',
            type: 'integer',
            default: undefined
        },
        name: {
            name: 'Category name',
            type: 'multilang',
            default: undefined
        },
        description: {
            name: 'Category description',
            type: 'multilang',
            default: undefined
        },
        category_type: {
            name: 'Category type',
            type: 'select',
            default: undefined,
            dir: [
                ['positive', 'positive'],
                ['negative', 'negative']
            ]
        },
    }

    /* functions */
    functions = {
        getAll: this.props.tlService.getFilteredTermCategories,
        massDelete: data => this.actionMassDelete(data)
    };

    /* modal components */
    modalComponents = {
        modalAdd: ModalAddEditTermCategory,
        modalEdit: ModalAddEditTermCategory,
        modalView: ModalAddEditTermCategory,
        modalClone: ModalCloneTermCategory
    };

    /*  define count of modal*/
    modalWindowsCount = 2


    componentDidMount() {

        const {
            userData: {token, user},
            t: translate
        } = this.props;

        /* default table columns config */
        this.columns_default = [
            {
                name: 'id',
                selector: 'id',
                sortable: true,
                grow: 0.3
            },
            {
                name: 'parent_id',
                selector: 'parent_id',
                grow: 0.3
            },
            {
                name: 'category_type',
                selector: 'category_type',
                sortable: true,
                grow: 0.3
            },
            {
                name: 'name',
                selector: 'name',
                cell: row => {
                    const name = row.name;
                    return (<ul>
                        {Object.entries(name).map(([key, val]) => <li key={key}>({key}) {val}</li>)}
                    </ul>)
                }
            },
            {
                name: 'description',
                selector: 'description',
                cell: row => {
                    // console.log('description', typeof row.description, row.description)
                    const description = row.description;
                    return (<ul>
                        {Object.entries(description).map(([key, val]) => <li key={key}>({key}) {val}</li>)}
                    </ul>)
                }
            },
            {

                name: 'Count of terms',
                selector: 'term_count',
                grow: 0.6,
                cell: row => {
                    return (
                        <Row className="w-100">
                            <Col className="my-auto text-right">{row['term_count']}</Col>
                            <Col className="my-auto">
                                <Button onClick={() => this.openTerms(row.id)} className="font-smaller"><i
                                    className="fas fa-bars"/></Button>
                            </Col>

                        </Row>
                    )
                }
            },
            {
                name: 'rights',
                cell: row => {
                    const right = row.rights;
                    return (
                        <table className="table table-sm font-smaller">
                            <thead>
                            <tr>
                                <th>{translate('user')}</th>
                                <th>{translate('group')}</th>
                                <th>{translate('company')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {right.map(val => {
                                if (val[0] && val[1] && val[2])
                                return <tr key={JSON.stringify(val)}>
                                    <td>{(val[0][0] > 0 ? `${val[0][1]} (${val[0][0]})` : '-')}</td>
                                    <td>{(val[1][0] > 0 ? `${val[1][1]} (${val[1][0]})` : '-')}</td>
                                    <td>{(val[2][0] > 0 ? `${val[2][1]} (${val[2][0]})` : '-')}</td>
                                </tr>;
                            }

                            )}
                            </tbody>

                        </table>)
                }
            },
            {
                name: 'edit',
                cell: row => {
                    return (
                        <div className="btn-group">
                            {checkUserRight(user, [601, 602]) &&
                            (<>
                                <Button onClick={() => this.openModal(0, row, 'modalEdit')} className="ml-2"><i
                                    className="fas fa-edit"/></Button>
                                <Button onClick={() => this.openModal(0, row, 'modalDelete')} className="ml-2"><i
                                    className="fas fa-trash"/></Button>
                            </>)

                            }
                        </div>
                    )
                }
            }
        ];

        // check for enable page
        this.renderIt = checkUserRight(user, [701, 702, 703]);

        // check add right
        this.addButton = checkUserRight(user, [701, 702, 703]);

        /*  get preloaded data */
        this.props.userGetAllTermCategories(token);


        /* config modal windows */
        this.modalSettings['modalAdd'] = {
            action: this.actionAdd,
            data: {
                id: false,
                category_type: "negative",
                replacement: {},
                name: createDefaultMultilangObject(),
                description: createDefaultMultilangObject(),
                rights: [],
                settings: {},
                locale_name: convertLanguagesToFull(this.props.i18n.language, backendLanguages),
                global_visible: 1,
                parent_id: 0
            },
            show: false,
            header: translate('Add Term Category'),
            footer: true,
            text: false,
            saveButton: true,
            saveButtonText: translate('Save'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        };
        this.modalSettings['modalEdit'] = {
            action: this.actionEdit,
            data: {},
            show: false,
            header: translate('Edit Term Category'),
            footer: true,
            text: false,
            saveButton: true,
            saveButtonText: translate('Save'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        };
        this.modalSettings['modalView'] = {
            action: false,
            data: {
                id: false,
                benchmark_template: 0,
                name: {},
                description: {},
                settings: {},
                text_type_id: "",
                calcelements: [],
                return_values: [],
                locale_name: "",
                rights: []
            },
            show: false,
            header: translate('View Term Category'),
            footer: false,
            text: '',
            saveButton: false,
            saveButtonText: translate('Save'),
            closeButton: false,
            closeButtonText: translate('Close'),
            ok: true
        }
        this.modalSettings['modalDelete'] = {
            action: this.actionDelete,
            data: {
                id: false,
            },
            show: false,
            header: translate('Delete Term Category'),
            footer: true,
            text: "Delete Term Category?",
            saveButton: true,
            saveButtonText: translate('Delete'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        }
        this.modalSettings['modalMassDelete'] = {
            action: this.actionDoMassDelete,
            data: {},
            show: false,
            header: translate('Delete Selected Term Categories'),
            footer: true,
            text: translate('Delete Selected Term Categories'),
            saveButton: true,
            saveButtonText: translate('Delete'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        }

        /* set initial states */
        this.setState({
            filters: {},
            limits: {
                limit: 100,
                offset: 0,
                order_by: 'id',
                order: 0
            },
            page: {
                pageHeader: translate('Term Categories'),
                addNewText: translate('Add new term category'),
            }
        });

        super.componentDidMount();

    }

    openTerms(id) {
        this.setState({Redirect: <Redirect to={`/terms_by_category/${id}`}/>});
    }

    /* after modal action!*/

    actionAdd = async (data) => {
        const {
            tlService,
            userData: {token},
        } = this.props;

        let id = false;

        console.log("actionAdd", data, typeof data.settings === "object");

        try {
            const ret = await tlService.addTermCategory(
                token,
                data.locale_name,
                JSON.stringify(data.name),
                JSON.stringify(data.description),
                data.category_type,
                typeof data.settings === "object" ? JSON.stringify(data.settings) :  data.settings,
                !!data.global_visible ? 1: 0,
                parseInt(data.parent_id)
            );
            id = ret.id
        } catch (e) {
            console.log('error ' + e);
            this.setState({error: e.message});
            return false;
        }

        //right
        if (data.rights.length > 0) {
            for (const right of data.rights) {
                try {
                    await tlService.addTermCategoryUGC(token, id,
                        right[0] ? parseInt(right[0][0]) : 0,
                        right[1] ? parseInt(right[1][0]) : 0,
                        right[2] ? parseInt(right[2][0]) : 0
                    );
                } catch (e) {
                    console.log('error ' + e);
                    this.setState({error: e.message});
                    return false;
                }
            }
        }
        return true;
    }

    actionEdit = async (data) => {
        const {
            tlService,
            userData: {token},
        } = this.props;

        console.log("actionEdit", data);

        try {
            await tlService.updateTermCategory(
                token,
                parseInt(data.id),
                data.locale_name,
                JSON.stringify(data.name),
                JSON.stringify(data.description),
                data.category_type,
                typeof data.settings === "object" ? JSON.stringify(data.settings) :  data.settings,
                !!data.global_visible? 1 :0
            );
        } catch (e) {
            console.log('error ' + e);
            this.setState({error: e.message});
            return false;
        }

        // update rights

        //const company = await tlService.getCompanyById(token, parseInt(data.id));
        let toDelete = [];
        let toAdd;
        if (data.old_rights && data.old_rights.length > 0) {
            toDelete = data.old_rights.filter(d => !data.rights.includes(d));
            toAdd = data.rights.filter(d => !data.old_rights.includes(d));
        } else {
            toAdd = data.rights;
        }

        // console.log('data.old_rights ', data.old_rights);
        // console.log('data.rights', data.rights);
        // console.log('toAdd');
        // console.log(toAdd);
        // console.log('toDelete');
        // console.log(toDelete);

        for (let right of toAdd) {
            try {
                await tlService.addTermCategoryUGC(token,
                    data.id,
                    right[0] ? parseInt(right[0][0]) : 0,
                    right[1] ? parseInt(right[1][0]) : 0,
                    right[2] ? parseInt(right[2][0]) : 0
                );
            } catch (e) {
                console.log('error ' + e);
                this.setState({error: e.message});
                return false;
            }
        }
        for (let right of toDelete) {
            try {
                await tlService.deleteTermCategoryUGC(token,
                    data.id,
                    right[0] ? parseInt(right[0][0]) : 0,
                    right[1] ? parseInt(right[1][0]) : 0,
                    right[2] ? parseInt(right[2][0]) : 0
                );
            } catch (e) {
                console.log('error ' + e);
                this.setState({error: e.message});
                return false;
            }
        }
        return true;
    }

    actionDelete = async (data) => {
        const {
            tlService,
            userData: {token},
        } = this.props;

        // delete  Category
        try {
            await tlService.deleteTermCategory(token, parseInt(data.id));
        } catch (e) {
            console.log('error ' + e);
            this.setState({error: e.message});
            return false;
        }
        return true;
    }

    /* end after modal action!*/

}

const mapStateToProps = ({userData, modalData, openData, adminData}) => {
    return {userData, openData, modalData, adminData};
};

const mapDispatchToProps = (dispatch, {tlService}) => {
    return bindActionCreators({
        ...getAllModalFunctions(),
        userGetAllTermCategories: userGetAllTermCategories(tlService)
    }, dispatch);
};

export default compose(
    withTranslation(),
    withTLService(),
    connect(mapStateToProps, mapDispatchToProps)
)(Terms);
