const config = {
    maxSize: 1000000,
    maxSizeInCharacters: 50000,
    termCategoryOptionSet: ["color"],
    meaningTypesList: {
        v: 'vovel',
        o: 'object',
        n: 'nonun'
    },
    messagesLevels: ['alert', 'warning', 'info'],
    messagesTemplates: ['text_message', 'text_message_no_answer', 'must_change_password', 'terms_and_conditions'],
    termSettings: ['color'],
    termsAndConditionsText: {
        en: `
Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Tincidunt arcu non sodales neque sodales. Bibendum enim facilisis gravida neque convallis a cras semper auctor. Habitant morbi tristique senectus et netus. Magna ac placerat vestibulum lectus mauris ultrices eros in cursus. Feugiat scelerisque varius morbi enim nunc faucibus a. Ultrices neque ornare aenean euismod elementum nisi quis eleifend quam. At quis risus sed vulputate. Magna ac placerat vestibulum lectus mauris. Ornare lectus sit amet est placerat in. Non pulvinar neque laoreet suspendisse interdum. Fames ac turpis egestas maecenas pharetra convallis. Enim ut tellus elementum sagittis vitae et leo duis ut. Feugiat sed lectus vestibulum mattis ullamcorper velit sed.
Eget duis at tellus at urna condimentum mattis. Condimentum lacinia quis vel eros donec ac odio tempor. Netus et malesuada fames ac turpis. Etiam erat velit scelerisque in dictum. Id diam vel quam elementum pulvinar etiam non quam. Pellentesque nec nam aliquam sem et tortor consequat. Congue mauris rhoncus aenean vel elit. Libero id faucibus nisl tincidunt eget nullam non nisi. Non curabitur gravida arcu ac tortor dignissim convallis aenean. Faucibus vitae aliquet nec ullamcorper. Libero nunc consequat interdum varius sit amet mattis vulputate enim. Faucibus vitae aliquet nec ullamcorper sit amet risus nullam. Fusce id velit ut tortor. Cras fermentum odio eu feugiat pretium nibh. Eu turpis egestas pretium aenean pharetra magna.
Magna fermentum iaculis eu non diam phasellus vestibulum. Est sit amet facilisis magna etiam tempor orci. Natoque penatibus et magnis dis parturient. Ultricies integer quis auctor elit sed vulputate mi sit amet. Eu tincidunt tortor aliquam nulla facilisi cras fermentum odio eu. Pellentesque dignissim enim sit amet venenatis urna. Vitae suscipit tellus mauris a diam maecenas. Mi sit amet mauris commodo. In egestas erat imperdiet sed euismod. At erat pellentesque adipiscing commodo elit at. Posuere lorem ipsum dolor sit amet consectetur. Suspendisse faucibus interdum posuere lorem ipsum dolor sit.
Condimentum mattis pellentesque id nibh. Ullamcorper eget nulla facilisi etiam. Iaculis eu non diam phasellus vestibulum lorem. Non quam lacus suspendisse faucibus interdum. Elit sed vulputate mi sit amet mauris commodo quis imperdiet. Condimentum vitae sapien pellentesque habitant morbi tristique senectus. Odio pellentesque diam volutpat commodo. Viverra mauris in aliquam sem fringilla ut morbi. Est velit egestas dui id. Mauris nunc congue nisi vitae suscipit tellus mauris. Ullamcorper a lacus vestibulum sed arcu. Faucibus vitae aliquet nec ullamcorper. Tortor posuere ac ut consequat semper viverra. Nec dui nunc mattis enim ut tellus. Faucibus et molestie ac feugiat sed lectus vestibulum mattis ullamcorper. Enim blandit volutpat maecenas volutpat blandit aliquam etiam erat. Est velit egestas dui id ornare arcu odio ut sem. Volutpat ac tincidunt vitae semper quis lectus. Euismod quis viverra nibh cras.
Netus et malesuada fames ac turpis egestas maecenas. Venenatis tellus in metus vulputate eu. Tortor id aliquet lectus proin nibh nisl condimentum. Sed egestas egestas fringilla phasellus faucibus scelerisque. Mauris ultrices eros in cursus turpis massa tincidunt dui. Mollis aliquam ut porttitor leo a diam sollicitudin. Cursus sit amet dictum sit amet justo donec. Egestas pretium aenean pharetra magna ac placerat vestibulum lectus mauris. Interdum posuere lorem ipsum dolor sit amet consectetur. Quam quisque id diam vel quam elementum. Dis parturient montes nascetur ridiculus mus. Praesent tristique magna sit amet. Et sollicitudin ac orci phasellus. Id cursus metus aliquam eleifend mi in nulla. Convallis posuere morbi leo urna molestie at elementum eu facilisis.`,
        de: `
<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Tincidunt arcu non sodales neque sodales. Bibendum enim facilisis gravida neque convallis a cras semper auctor. Habitant morbi tristique senectus et netus. Magna ac placerat vestibulum lectus mauris ultrices eros in cursus. Feugiat scelerisque varius morbi enim nunc faucibus a. Ultrices neque ornare aenean euismod elementum nisi quis eleifend quam. At quis risus sed vulputate. Magna ac placerat vestibulum lectus mauris. Ornare lectus sit amet est placerat in. Non pulvinar neque laoreet suspendisse interdum. Fames ac turpis egestas maecenas pharetra convallis. Enim ut tellus elementum sagittis vitae et leo duis ut. Feugiat sed lectus vestibulum mattis ullamcorper velit sed.</p>
<p>Eget duis at tellus at urna condimentum mattis. Condimentum lacinia quis vel eros donec ac odio tempor. Netus et malesuada fames ac turpis. Etiam erat velit scelerisque in dictum. Id diam vel quam elementum pulvinar etiam non quam. Pellentesque nec nam aliquam sem et tortor consequat. Congue mauris rhoncus aenean vel elit. Libero id faucibus nisl tincidunt eget nullam non nisi. Non curabitur gravida arcu ac tortor dignissim convallis aenean. Faucibus vitae aliquet nec ullamcorper. Libero nunc consequat interdum varius sit amet mattis vulputate enim. Faucibus vitae aliquet nec ullamcorper sit amet risus nullam. Fusce id velit ut tortor. Cras fermentum odio eu feugiat pretium nibh. Eu turpis egestas pretium aenean pharetra magna.</p>
<p>Magna fermentum iaculis eu non diam phasellus vestibulum. Est sit amet facilisis magna etiam tempor orci. Natoque penatibus et magnis dis parturient. Ultricies integer quis auctor elit sed vulputate mi sit amet. Eu tincidunt tortor aliquam nulla facilisi cras fermentum odio eu. Pellentesque dignissim enim sit amet venenatis urna. Vitae suscipit tellus mauris a diam maecenas. Mi sit amet mauris commodo. In egestas erat imperdiet sed euismod. At erat pellentesque adipiscing commodo elit at. Posuere lorem ipsum dolor sit amet consectetur. Suspendisse faucibus interdum posuere lorem ipsum dolor sit.</p>
<p>Condimentum mattis pellentesque id nibh. Ullamcorper eget nulla facilisi etiam. Iaculis eu non diam phasellus vestibulum lorem. Non quam lacus suspendisse faucibus interdum. Elit sed vulputate mi sit amet mauris commodo quis imperdiet. Condimentum vitae sapien pellentesque habitant morbi tristique senectus. Odio pellentesque diam volutpat commodo. Viverra mauris in aliquam sem fringilla ut morbi. Est velit egestas dui id. Mauris nunc congue nisi vitae suscipit tellus mauris. Ullamcorper a lacus vestibulum sed arcu. Faucibus vitae aliquet nec ullamcorper. Tortor posuere ac ut consequat semper viverra. Nec dui nunc mattis enim ut tellus. Faucibus et molestie ac feugiat sed lectus vestibulum mattis ullamcorper. Enim blandit volutpat maecenas volutpat blandit aliquam etiam erat. Est velit egestas dui id ornare arcu odio ut sem. Volutpat ac tincidunt vitae semper quis lectus. Euismod quis viverra nibh cras.</p>
<p>Netus et malesuada fames ac turpis egestas maecenas. Venenatis tellus in metus vulputate eu. Tortor id aliquet lectus proin nibh nisl condimentum. Sed egestas egestas fringilla phasellus faucibus scelerisque. Mauris ultrices eros in cursus turpis massa tincidunt dui. Mollis aliquam ut porttitor leo a diam sollicitudin. Cursus sit amet dictum sit amet justo donec. Egestas pretium aenean pharetra magna ac placerat vestibulum lectus mauris. Interdum posuere lorem ipsum dolor sit amet consectetur. Quam quisque id diam vel quam elementum. Dis parturient montes nascetur ridiculus mus. Praesent tristique magna sit amet. Et sollicitudin ac orci phasellus. Id cursus metus aliquam eleifend mi in nulla. Convallis posuere morbi leo urna molestie at elementum eu facilisis.</p>`,

    }

};

export default config;