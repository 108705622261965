import React, {Component} from "react";
import {withTranslation} from 'react-i18next';
import {Col, Container, Form, Row} from "react-bootstrap";
import {compose, getAllModalFunctions} from "../../../utils";
import {withTLService} from "../../hoc";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import siteLanguages from "../../../utils/sitelanguages";

class ModalAddEditRight extends Component {

    handleChange = (event) => {
        const {index} = this.props;
        const {name, value, type} = event.target;
        event.target.value = type === 'number' && parseInt(value);
        const {modalData: {modal: {data}}} = this.props;
        this.props.modalUpdateData(index, {[name]: type === 'number' ? parseInt(value) : value})
    };

    handleChangeDescription = (event) => {
        const {index} = this.props;
        const {name, value} = event.target;
        const {modalData: {modal: {data}}} = this.props;
        console.log('handleChangeDescription');
        console.log(data);
        this.props.modalUpdateData(index, {
            description: {
                ...data.description,
                ...{[name]: value}
            }

        })
    };

    render() {
        const {index} = this.props;
        const {
            t: translate,
            openData: {user_types},
            modalData: {modal: {data: {user_type_id, id, description: dTmp}}}
        } = this.props;
        const {modalData: {modal: {data}}} = this.props;

        let description = {};
        if (!dTmp || typeof dTmp === 'string') {
            Object.keys(siteLanguages).map((language) => {

                //TODO change here to new multilang !!!!
                // description[language] = descrTmp && !description[language] ? descrTmp.text : '';
                return null;
            });
            this.props.modalUpdateData(index, {
                description: description
            })
        } else {
            description = dTmp;
        }
        console.log('render!!!!!');
        console.log(description);
        return (
            <Container>
                <Row>
                    <Col md={6}>
                        <Form.Group controlId="user_type_id">
                            <Form.Control
                                onChange={this.handleChange}
                                value={user_type_id}
                                name="user_type_id"
                                as="select">
                                {
                                    Object.keys(user_types).length > 0 && Object.keys(user_types).map((user_type) =>
                                        <option key={user_type}
                                                value={user_type}>{translate(user_types[user_type].name)}</option>
                                    )
                                }
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="id">
                            <Form.Control
                                type="number"
                                name="id"
                                defaultValue={id}
                                onChange={this.handleChange}
                                placeholder="Enter id"/>
                        </Form.Group>
                    </Col>
                </Row>

                <h1 className="h5 mb-0 text-gray-800 my-3">Descriptions</h1>

                <Row>
                    <Col>
                        {
                            Object.keys(siteLanguages).map((language) => {

                                    return (
                                        <Row key={language}>
                                            <Col md={2}>
                                                {translate(language)}
                                            </Col>
                                            <Col md={10}>
                                                <Form.Group controlId={language + 'description'}>
                                                    <Form.Control
                                                        type="text"
                                                        name={language}
                                                        defaultValue={description[language]}
                                                        onChange={this.handleChangeDescription}
                                                        placeholder="Enter description"/>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    )
                                }
                            )
                        }

                    </Col>
                </Row>
            </Container>
        )
    }
}

const mapStateToProps = ({userData, modalData, openData}) => {
    return {userData, openData, modalData};
};

const mapDispatchToProps = (dispatch, {tlService}) => {
    return bindActionCreators({
        ...getAllModalFunctions(),
    }, dispatch);
};

export default compose(
    withTranslation(),
    withTLService(),
    connect(mapStateToProps, mapDispatchToProps)
)(ModalAddEditRight);