import {modalAdd, modalCleanAll, modalDelete, modalOpenClose, modalUpdate, modalUpdateData, modalReplace ,modalReplaceData} from "../actions";

function getAllModalFunctions() {
    return {
        modalUpdateData: modalUpdateData,
        modalReplaceData: modalReplaceData,
        modalUpdate: modalUpdate,
        modalReplace: modalReplace,
        modalOpenClose: modalOpenClose,
        modalCleanAll: modalCleanAll,
        modalAdd: modalAdd,
        modalDelete: modalDelete
    }

}

export {
    getAllModalFunctions
}