import React from 'react';
import {checkUserRight, compose, getAllModalFunctions} from "../../../utils";
import {withTranslation} from "react-i18next";
import {withTLService} from "../../hoc";
import {connect} from "react-redux";


import {bindActionCreators} from "redux";
import {
    adminGetAllCompanies, adminGetAllOptions
} from "../../../actions";

import ModalAddEditCompany from "../../modals/modal-add-edit-company";
import ModalViewCompany from "../../modals/modal-view-company";

import {Button} from "react-bootstrap";
import DataViewer from "../../elements/data-viewer";


class Companies extends DataViewer {

    /*  define count of modal*/
    modalWindowsCount = 2;

    tableFilters = {
        deleted: {
            name: 'Show deleted elements',
            type: 'integer',
            default: 0
        },
        name: {
            name: 'Company ame',
            type: 'string',
            default: undefined
        }
    }


    functions = {
        getAll: this.props.tlService.getFilteredCompanies,
        massDelete: data => this.actionMassDelete(data)
    };

    modalComponents = {
        modalAdd: ModalAddEditCompany,
        modalEdit: ModalAddEditCompany,
        modalView: ModalViewCompany,
    };


    componentDidMount() {
        const {
            userData: {token, user},
            t: translate
        } = this.props;

        this.columns_default = [
            {
                name: 'id',
                selector: 'id',
                sortable: true
            },
            {
                name: 'name',
                selector: 'name',
                sortable: true
            },
            {
                name: 'company_licenses',
                selector: 'company_licenses',
                sortable: true,
                cell: row => JSON.stringify(row.company_licenses)
            },
            {
                name: 'company_options',
                selector: 'company_options',
                sortable: true,
                cell: row => JSON.stringify(row.company_options)

            },
            {
                name: 'delete_time',
                selector: 'delete_time',
                sortable: true

            },
            {
                name: 'edit',
                selector: 'id',
                cell: row => {
                    return (
                        <div className="btn-group">
                            <Button onClick={() => this.openModal(0, row, 'modalView')}><i className="fas fa-eye"/></Button>
                            {(row.id > 2 && checkUserRight(user, [308]))   &&
                            <>
                                <Button onClick={() => this.openModal(0, row, 'modalEdit')} className="ml-2"><i className="fas fa-edit"/></Button>
                                <Button onClick={() => this.openModal(0, row, 'modalDelete')} className="ml-2"><i className="fas fa-trash"/></Button>
                            </>

                            }
                        </div>
                    )
                }
            }
        ];

        // check for enable page
        this.renderIt = checkUserRight(user, [ 306, 307, 308]);

        // check add right
        this.addButton = checkUserRight(user, [307]);


        this.props.adminGetAllCompanies(token);
        this.props.adminGetAllOptions(token);




        this.modalSettings['modalAdd'] = {
            action: this.actionAdd,
            data: {
                name: '',
                company_licenses: [],
                company_options: {}
            },
            show: false,
            header: translate('Add Company'),
            footer: true,
            text: '',
            saveButton: true,
            saveButtonText: translate('Save'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        };
        this.modalSettings['modalEdit'] = {
            action: this.actionEdit,
            data: {
                name: '',
                company_licenses: [],
                company_options: {}
            },
            show: false,
            header: translate('Edit Company'),
            footer: true,
            text: '',
            saveButton: true,
            saveButtonText: translate('Save'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        };
        this.modalSettings['modalView'] = {
            action: false,
            data: {
                name: '',
                company_licenses: [],
                company_options: {}
            },
            show: false,
            header: translate('View Company'),
            footer: true,
            text: '',
            saveButton: false,
            saveButtonText: translate('Save'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        };
        this.modalSettings['modalDelete'] = {
            action: this.actionDelete,
            data: {
                id: false,
            },
            show: false,
            header: translate('Delete Company'),
            footer: true,
            text: "Delete company?",
            saveButton: true,
            saveButtonText: translate('Delete'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        };
        this.modalSettings['modalMassDelete'] = {
            action: this.actionDoMassDelete,
            data: {},
            show: false,
            header: translate('Delete Selected Companies'),
            footer: true,
            text: translate('Delete Selected Companies'),
            saveButton: true,
            saveButtonText: translate('Delete'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        }


        this.setState({
            filters: {
            },
            limits: {
                limit: 10,
                offset: 0,
                order_by: 'id',
                order: 0
            },
            page: {
                pageHeader: translate('Companies'),
                addNewText: translate('Add new company'),
            }
        });
        super.componentDidMount();
    }

    actionAdd = async (data) => {
        const {
            tlService,
            userData: {token},
            t: translate
        } = this.props;

        let id = false;

        if (data.company_licenses.length < 1){
            this.setState({error: translate('Must be at least one license')});
            return false;
        }

        try {
           const ret = await tlService.addCompany(token, data.company_licenses, data.name);
           id = ret.id
        } catch (e) {
            console.log('error ' + e);
            this.setState({error: e.message});
            return false;
        }

        // options
        for (let tmpOption of Object.keys(data.company_options)) {
            if (data.company_options[tmpOption] && data.company_options[tmpOption].trim().length > 0) {
                try {
                    await tlService.addCompanyOption(token, id, tmpOption,data.company_options[tmpOption].trim());
                } catch (e) {
                    console.log('error ' + e);
                    this.setState({error: e.message});
                    return false;
                }
            }
        }
        return true;
    }

    actionEdit = async (data) => {
        const {
            tlService,
            userData: {token},
        } = this.props;



        try {
            await tlService.updateCompany(token, parseInt(data.id), data.name);
        } catch (e) {
            console.log('error ' + e);
            this.setState({error: e.message});
            return false;
        }

        // update licenses

        const newLicenses = data.company_licenses.map(lic => parseInt(lic));
        const company = await tlService.getCompanyById(token, parseInt(data.id));
        if (company && company.company_licenses) {
            const toDelete = company.company_licenses.filter(d => !newLicenses.includes(d));
            const toAdd = newLicenses.filter(d => !company.company_licenses.includes(d));

            /*
            console.log('company.company_licenses');
            console.log(company.company_licenses);
            console.log('toAdd');
            console.log(toAdd);
            console.log('toDelete');
            console.log(toDelete);
            */

            for (let license of toAdd) {
                try {
                    await tlService.addCompanyLicense(token, data.id, license);
                } catch (e) {
                    console.log('error ' + e);
                    this.setState({error: e.message});
                    return false;
                }
            }
            for (let license of toDelete) {
                try {
                    await tlService.deleteCompanyLicense(token, data.id, license);
                } catch (e) {
                    console.log('error ' + e);
                    this.setState({error: e.message});
                    return false;
                }
            }
        }
        // options

        try {
             await tlService.deleteAllCompanyOption(token, parseInt(data.id));
        } catch (e) {
            console.log('error ' + e);
            this.setState({error: e.message});
            return false;
        }

        for (let tmpOption of Object.keys(data.company_options)) {
            if (data.company_options[tmpOption] && data.company_options[tmpOption].trim().length > 0) {
                try {
                    await tlService.addCompanyOption(token, parseInt(data.id), tmpOption,data.company_options[tmpOption].trim());
                } catch (e) {
                    console.log('error ' + e);
                    this.setState({error: e.message});
                    return false;
                }
            }
        }
        return true;

    }

    actionDelete = async (data) => {
        const {
            tlService,
            userData: {token},
        } = this.props;

        // delete company
        try {
            if (parseInt(data.id) > 2)
                await tlService.deleteCompany(token, parseInt(data.id));
        } catch (e) {
            console.log('error ' + e);
            this.setState({error: e.message});
            return false;
        }
        return true;
    }

}


const mapStateToProps = ({userData, modalData, openData, adminData}) => {
    return {userData, openData, modalData, adminData};
};

const mapDispatchToProps = (dispatch, {tlService}) => {
    return bindActionCreators({
        ...getAllModalFunctions(),
        adminGetAllCompanies: adminGetAllCompanies(tlService),
        adminGetAllOptions: adminGetAllOptions(tlService)

    }, dispatch);
};

export default compose(
    withTranslation(),
    withTLService(),
    connect(mapStateToProps, mapDispatchToProps)
)(Companies);

