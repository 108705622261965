import React, {Component} from "react";
import {compose} from "../../utils";
import {withTranslation} from "react-i18next";
import {withTLService} from "../hoc";
import {connect} from "react-redux";
import {Link} from "react-router-dom";


class MessagePopup extends Component {

    render() {
        const {userData: {messages}, t: translate} = this.props;
        const iaw = messages.getWarningsAndInfo();
       // console.log('MessagePopup')
       // console.log(iaw)

        return (
            <li className="nav-item dropdown no-arrow mx-1">
                <div className="nav-link dropdown-toggle cursor-pointer"  id="alertsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className="fas fa-bell fa-fw"/>

                    <span className="badge badge-danger badge-counter">{iaw ? iaw[1] : 0}</span>
                </div>

                <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="alertsDropdown">
                    <h6 className="dropdown-header">
                        {translate ('Message Center')}
                    </h6>

                    {
                        iaw && iaw[0].map(el => {
                            if (el.level === 'info') {
                                return (<Link key={el.id} className="dropdown-item d-flex align-items-center" to={`/messages/${el.level}/${el.id}`}>
                                    <div className="mr-3">
                                        <div className="icon-circle bg-primary">
                                            <i className="fas fa-file-alt text-white"/>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="small text-gray-500">{el.create_time}</div>
                                        <span className={!el.read_time && "font-weight-bold"}>{el.text}</span>
                                    </div>
                                </Link>)
                            }
                            else if  (el.level === 'warning') {
                                return (<Link key={el.id} className="dropdown-item d-flex align-items-center" to={`/messages/${el.level}/${el.id}`}>
                                    <div className="mr-3">
                                        <div className="icon-circle bg-warning">
                                            <i className="fas fa-exclamation-triangle text-white"/>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="small text-gray-500">{el.create_time}</div>
                                        <span className={!el.read_time && "font-weight-bold"}>{el.text}</span>
                                    </div>
                                </Link>)
                            }
                            return '';
                        })

                    }

                    <Link className="dropdown-item text-center small text-gray-500"  to={`/messages/all`}>{translate ('Show All Messages')}</Link>
                </div>
            </li>
        )
    }
}


const mapStateToProps = ({userData}) => {
    return {userData};
};

export default compose(
    withTranslation(),
    withTLService(),
    connect(mapStateToProps)
)(MessagePopup);
