import React from 'react';
import {compose} from "../../../utils";
import {withTranslation} from "react-i18next";
import {withTLService} from "../../hoc";
import {connect} from "react-redux";


import {bindActionCreators} from "redux";
import ModalAddEditRight from "../../modals/modal-add-edit-right";
import ModalViewRight from "../../modals/modal-view-right";

import {Button} from "react-bootstrap";
import DataViewer from "../../elements/data-viewer";
import siteLanguages from "../../../utils/sitelanguages";

class Rights extends DataViewer {

    functions = {
        getAll: this.props.tlService.getAllRights
    };
    components = {
        modalAdd: ModalAddEditRight,
        modalEdit: ModalAddEditRight,
        modalView: ModalViewRight,
    };
    table_name = 'rights';

    extended_fields = ['description']


    componentDidMount() {
        const {
            t: translate,
            userData: {user},
        } = this.props;

        this.renderIt =  user.user_type_id === 1;

        this.columns_default = [
            {
                title: 'id',
                prop: 'id',
                sortable: true
            },
            {
                title: 'user type id',
                prop: 'user_type_id',
                sortable: true
            },
            {
                title: 'description',
                prop: 'description'

            },
            {
                title: 'edit',
                prop: 'id',
                cell: row => {
                    return (
                        <span>
                            <Button onClick={() => this.viewModal(row.id)}><i className="fas fa-eye"/></Button>
                            <Button onClick={() => this.editModal(row.id)} className="ml-2"><i className="fas fa-edit"/></Button>
                            <Button onClick={() => this.deleteModal(row.id)} className="ml-2"><i
                                className="fas fa-trash"/></Button>
                        </span>
                    )
                }
            }
        ];

        this.props.modalSetAdd({
            action: this.actionAdd,
            data: {
                user_type_id: 3,
                id: '',
                description: Object.fromEntries(
                    Object.keys(siteLanguages).map((lang) => [lang, ''])
                )
            },
            show: true,
            header: translate('Add Right'),
            footer: true,
            text: '',
            saveButton: true,
            saveButtonText: translate('Save'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        });
        this.props.modalSetEdit({
            action: this.actionEdit,
            data: {
                user_type_id: 3,
                id: '',
                description: Object.fromEntries(
                    Object.keys(siteLanguages).map((lang) => [lang, ''])
                )
            },
            show: true,
            header: translate('Edit Right'),
            footer: true,
            text: '',
            saveButton: true,
            saveButtonText: translate('Save'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true

        });
        this.props.modalSetView({
            action: false,
            data: {
                user_type_id: 3,
                id: '',
                description: Object.fromEntries(
                    Object.keys(siteLanguages).map((lang) => [lang, ''])
                )
            },
            show: true,
            header: translate('View Right'),
            footer: false,
            text: '',
            saveButton: false,
            saveButtonText: translate('Save'),
            closeButton: false,
            closeButtonText: translate('Close'),
            ok: true
        });
        this.props.modalSetDelete({
            action: this.actionDelete,
            data: {
                id: false,
            },
            show: true,
            header: translate('Delete Right'),
            footer: true,
            text: "Delete right?",
            saveButton: true,
            saveButtonText: translate('Delete'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        });
        this.setState({
            page: {
                pageHeader: translate('Rights'),
                addNewText: translate('Add new right'),
                keyField: 'id',
                rowsPerPage: 100
            }
        });
        super.componentDidMount();
    }

    reloadTable(data) {
        const columns_default = this.columns_default;
        const {t: translate,} = this.props;
        if (data && data.length > 0) {
            let newData = [];
            for (let i of data) {
                const {id, user_type_id} = i;
                newData.push({id, user_type_id});
            }
            /* update columns */
            let newColumns = [];
            for (let i of columns_default) {
                const {title, prop, sortable, cell} = i;
                if (title !== 'edit') newColumns.push({title: translate(title), prop, sortable})
                else newColumns.push({title, prop, cell})
            }
            this.setState({
                columns: newColumns,
                rows: newData,
                lang: this.props.i18n.language
            });
        }
    }

    actionAdd = async (data) => {
        const {
            tlService,
            userData: {token},
        } = this.props;

        try {
            await tlService.addRight(token, data.id, parseInt(data.user_type_id));
        } catch (e) {
            console.log('error ' + e);
            this.setState({error: e.message});
            return false;
        }
        // description
        let reloadTranslate = false;
        for (let tmpLang of Object.keys(data.description)) {
            if (data.description[tmpLang] && data.description[tmpLang].trim().length > 0) {
                try {
                    //TODO multilang
                    //await tlService.addMultilang(token, data.id, 'rights', 'description', data.description[tmpLang].trim(), tmpLang);
                    reloadTranslate = true;
                } catch (e) {
                    console.log('error ' + e);
                    this.setState({error: e.message});
                    return false;
                }
            }
        }
        //reloadTranslate && this.props.getMultilang();
        return true;
    }

    actionEdit = async (data) => {
        const {
            tlService,
            userData: {token},
        } = this.props;

        console.log('actionEdit');
        console.log(data);
        // delete right
        try {
            await tlService.deleteRight(token, data.id);
        } catch (e) {
            this.setState({error: e.message});
            return false;
        }

        // create right
        try {
            await tlService.addRight(token, data.id, parseInt(data.user_type_id));
        } catch (e) {
            this.setState({error: e.message});
            return false;
        }
        // description delete
        for (let tmpLang of Object.keys(data.description)) {
            try {
               // await tlService.deleteMultilang(token, data.id, 'rights', 'description',  tmpLang);
            } catch (e) {
                console.log(e);
            }
        }
        // description create
        let reloadTranslate = false;
        for (let tmpLang of Object.keys(data.description)) {
            if (data.description[tmpLang] && data.description[tmpLang].trim().length > 0) {
                try {
                    //await tlService.addMultilang(token, data.id, 'rights', 'description', data.description[tmpLang].trim(), tmpLang);
                    reloadTranslate = true;
                } catch (e) {
                    console.log('error ' + e);
                    this.setState({error: e.message});
                    return false;
                }
            }
        }
      //  reloadTranslate && this.props.getMultilang();
        return true;
    }

    actionDelete = async (data) => {
        const {
            tlService,
            userData: {token},
        } = this.props;

        try {
            await tlService.deleteRight(token, parseInt(data.id));
        } catch (e) {
            console.log('error ' + e);
            this.setState({error: e.message});
            return false;
        }
        // description
        /*
        let reloadTranslate = false;
        for (let tmpLang of Object.keys(data.description)) {
            if (data.description[tmpLang] && data.description[tmpLang].trim().length > 0) {
                try {
                    await tlService.addMultilang(token, data.id, 'rights', 'description', data.description[tmpLang].trim(), tmpLang);
                    reloadTranslate = true;
                } catch (e) {
                    console.log('error ' + e);
                    this.setState({error: e.message});
                    return false;
                }
            }
        }
        reloadTranslate && this.props.getMultilang();
        */
        return true;
    }

}


const mapStateToProps = ({userData, modalData, openData}) => {
    return {userData, openData, modalData};
};

const mapDispatchToProps = (dispatch, {tlService}) => {
    return bindActionCreators({
       // getMultilang: getMultilang(tlService)

    }, dispatch);
};

export default compose(
    withTranslation(),
    withTLService(),
    connect(mapStateToProps, mapDispatchToProps)
)(Rights);

