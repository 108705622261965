//import axios from 'axios';

import {deepCopy, getResource, isObjectEmpty} from "../utils";

require('dotenv').config();
export default class TLService {

    _apiBase = '';

    localGetResource = async (url, method, body, token, json, extraHeaders) => {
        if (!this._apiBase) {
            // set default
            try {
                const response = await fetch('/config/backend_connection.json',{
                    headers : {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                });
                const backendConfig = await response.json();
                this._apiBase = backendConfig?.textLabApiBase || process.env.REACT_APP_TL_API_BASE;
            } catch (e) {
                console.log(e.toString());
                this._apiBase = process.env.REACT_APP_TL_API_BASE;
            }
        }
        return await getResource(this._apiBase, url, method, body, token, json, extraHeaders)
    };

    /**terms**/
    addTermInCategory = async (token, category_id, body) => {
        return await this.localGetResource(`/term/${category_id}`, 'post', body, token);
    }

    updateTermInCategory = async (token, category_id, term_id, body) => {
        return await this.localGetResource(`/term/${category_id}/${term_id}`, 'put', body, token);
    }

    deleteTermInCategory = async (token, category_id, term_id) => {
        return await this.localGetResource(`/term/${category_id}/${term_id}`, 'delete', null, token);
    }
    addTermReplacementInCategory = async (token, category_id, term_id, body) => {
        return await this.localGetResource(`/term/replacement/${category_id}/${term_id}`, 'post', body, token);
    }

    getTermReplacementInCategoryByFilter = async (token, category_id, term_id, limits = {}, filters = {}) => {
        let filter = new URLSearchParams();
        filter = this.appendLimits(limits, filter);
        filter = this.appendFilters(filters, filter);
        return await this.localGetResource(`/term/replacement/${category_id}/${term_id}?` + filter.toString(), 'get', null, token);
    };

    getTermInCategoryByFilter = async (token, category_id, limits = {}, filters = {}) => {
        let filter = new URLSearchParams();
        filter = this.appendLimits(limits, filter);
        filter = this.appendFilters(filters, filter);
        return await this.localGetResource(`/term/bycategory/${category_id}?` + filter.toString(), 'get', null, token);
    };

    getFilteredTermByCategory  = async (token, limits = {}, filters = {}) => {
        const copyFilters =deepCopy(filters);
        const category_id = copyFilters?.category_id;
        delete copyFilters["category_id"];
        return await this.getTermInCategoryByFilter(token, category_id, limits , copyFilters);
    }

    getFilteredTermReplacement  = async (token, limits = {}, filters = {}) => {
        const copyFilters =deepCopy(filters);
        const term_category_id= copyFilters?.term_category_id;
        const term_id= copyFilters?.term_id;
        delete copyFilters["term_category_id"];
        delete copyFilters["term_id"];
        return await this.getTermReplacementInCategoryByFilter(token, term_category_id, term_id, limits , copyFilters);
    }

    deleteTermReplacementInCategory = async (token, category_id, term_id, replacement_id, meaning = "") => {
        console.log("deleteTermReplacementInCategory", category_id, term_id, replacement_id, meaning, isObjectEmpty(meaning))

        if (!!meaning)
            return await this.localGetResource(`/term/replacement/${category_id}/${term_id}/${replacement_id}/${meaning}`, 'delete', null, token);
        else
            return await this.localGetResource(`/term/replacement/${category_id}/${term_id}/${replacement_id}`, 'delete', null, token);
    }

    /* for tables! */

    getFilteredBenchmarks = async (token, limits = {}, filters = {}) => {
        let filter = new URLSearchParams();
        filter = this.appendLimits(limits, filter);
        filter = this.appendFilters(filters, filter);
        return await this.localGetResource('/benchmark?' + filter.toString(), 'GET', null, token)
    };



    getFilteredUsers = async (token, limits = {}, filters = {}) => {
        let filter = new URLSearchParams();
        filter = this.appendLimits(limits, filter);
        filter = this.appendFilters(filters, filter);
        //  console.log('getFilteredUsers','/user?' + filter.toString())
        return await this.localGetResource('/user?' + filter.toString(), 'GET', null, token);
    };

    getFilteredGroups = async (token, limits = {}, filters = {}) => {
        let filter = new URLSearchParams();
        filter = this.appendLimits(limits, filter);
        filter = this.appendFilters(filters, filter);
        //  console.log('getFilteredGroups', '/usergroup?' + filter.toString())
        return await this.localGetResource('/usergroup?' + filter.toString(), 'GET', null, token);
    };

    getFilteredAllowLists = async (token, limits = {}, filters = {}) => {
        let filter = new URLSearchParams();
        filter = this.appendLimits(limits, filter);
        filter = this.appendFilters(filters, filter);
        return await this.localGetResource('/allowlist?' + filter.toString(), 'GET', null, token);
    };

    getFilteredCompanies = async (token, limits = {}, filters = {}) => {
        let filter = new URLSearchParams();
        filter = this.appendLimits(limits, filter);
        filter = this.appendFilters(filters, filter);
        return await this.localGetResource('/company?' + filter.toString(), 'GET', null, token);
    };

    getFilteredTermCategories = async (token, limits = {}, filters = {}) => {
        let filter = new URLSearchParams();
        filter = this.appendLimits(limits, filter);
        filter = this.appendFilters(filters, filter);
        return await this.localGetResource('/term_category?' + filter.toString(), 'GET', null, token);
    };

    /*whitelist*/
    updateWhitelist = async (token, id, body) => {
        return await this.localGetResource(`/allowlist/${id}`, 'put', body, token);
    };

    deleteWhitelist = async (token, id) => {
        return await this.localGetResource(`/allowlist/${id}`, 'delete', null, token);
    };

    /* for tables! */

    addTermCategory = async (token, locale_name, name, description, category_type, settings, global_visible, parent_id) => {
        return await this.localGetResource(`/term_category`, 'post', {
            locale_name, name, description, category_type, settings, global_visible, parent_id
        }, token);
    };


    loginUser = async (identifier, password, ttl = 0) => {
        return await this.localGetResource('/user/login', 'POST', {identifier, password, ttl});
    };

    renewUser = async (token, ttl = 0) => {
        return await this.localGetResource('/user/renew', 'POST', {ttl}, token);
    };

    abbreviationUpload = async (token, language_id, data) => {
        return await this.localGetResource('/abbreviation/upload', 'POST', {language_id, data}, token);
    };
    abbreviationDownload = async (token, language_id) => {
        return await this.localGetResource('/abbreviation/download?language_id=' + language_id, 'GET', null, token);
    };

    exceptionWordDownload = async (token, language_id) => {
        return await this.localGetResource('/exception-words/download?language_id=' + language_id, 'GET', null, token);
    };
    exceptionWordUpload = async (token, language_id, data) => {
        return await this.localGetResource('/exception-words/upload', 'POST', {language_id, data}, token);
    };

    meUser = async (token) => {
        return await this.localGetResource('/user/me', 'GET', null, token);
    };

    getLanguages = async () => {
        return await this.localGetResource('/language', 'GET', null, null);
    };

    getCountries = async () => {
        return await this.localGetResource('/country', 'GET', null, null);
    };

    getCountryLanguages = async () => {
        return await this.localGetResource('/country_languages', 'GET', null, null);
    };

    getLocales = async () => {
        return await this.localGetResource('/locale', 'GET', null, null);
    };

    getMultilang = async () => {
        return await this.localGetResource('/multilang', 'GET', null, null);
    };
    getAllMessages = async (token, user_id, deleted = 0) => {
        let filter = new URLSearchParams();
        user_id && filter.append("user_id", user_id);
        deleted && filter.append("deleted", '1');
        return await this.localGetResource('/message?' + filter.toString(), 'GET', null, token);
    };
    setMessageRead = async (token, id) => {
        return await this.localGetResource(`/message/${id}/read`, 'GET', null, token);
    };
    setMessageAnswer = async (token, id, answer) => {
        return await this.localGetResource(`/message/${id}/answer`, 'POST', {answer}, token);
    };
    addMessage = async (token, user_id, level, from_inf, template, text) => {
        return await this.localGetResource(`/message`, 'post', {level, user_id, from_inf, template, text}, token);
    };

    addBenchmarkTemplate = async (token, name, description, text_type_id, default_calcelements, locale_name, return_values, settings) => {
        //   console.log({name, description, benchmark_template_id, calcelements_setting, return_values, settings})
        return await this.localGetResource(`/benchmark_template`, 'post', {
            name,
            description,
            text_type_id,
            default_calcelements,
            locale_name,
            return_values,
            settings
        }, token);
    };

    addBenchmark = async (token, name, description, benchmark_template_id, calcelements_setting, return_values, settings) => {
        //   console.log({name, description, benchmark_template_id, calcelements_setting, return_values, settings})
        return await this.localGetResource(`/benchmark`, 'post', {
            name,
            description,
            benchmark_template_id,
            calcelements_setting,
            return_values,
            settings
        }, token);
    };

    addBenchmarkUGC = async (token, benchmark_id, user_id, user_group_id, company_id) => {
        return await this.localGetResource(`/benchmark/ugc`, 'post', {benchmark_id, user_id, user_group_id, company_id}, token);
    };

    addTermCategoryUGC = async (token, term_category_id, user_id, user_group_id, company_id) => {
        return await this.localGetResource(`/term_category/ugc`, 'post', {
            term_category_id,
            user_id,
            user_group_id,
            company_id
        }, token);
    };

    testRegex = async (token, regexp, text) => {
        return await this.localGetResource(`/regex`, 'post', {regexp, text}, token);
    };


    getAllRights = async (token) => {
        return await this.localGetResource('/right', 'GET', null, token);
    };
    getAllOptions = async (token) => {
        return await this.localGetResource('/options', 'GET', null, token);
    };


    getAllCalcelements = async (token) => {
        return await this.localGetResource('/calcelements', 'GET', null, token);
    };
    getAllReturnValues = async (token = null) => {
        return await this.localGetResource('/return_values', 'GET', null, token);
    };

    getAllTextTypes = async (token) => {
        return await this.localGetResource('/texttype', 'GET', null, token);
    };

    getAllSettings = async (token) => {
        return await this.localGetResource('/settings', 'GET', null, token);
    };


    getAllBenchmarkTemplates = async (token) => {
        return await this.localGetResource('/benchmark_template', 'GET', null, token);
    };

    appendLimits = (limits, filter) => {
        limits['limit'] && filter.append("limit", limits['limit']);
        limits['offset'] && filter.append("offset", limits['offset']);
        limits['order_by'] && filter.append("order_by", limits['order_by']);
        limits['order'] && filter.append("order", limits['order'].toString());
        return filter;
    }
    appendFilters = (varFilter, filter) => {
        if (varFilter && Object.keys(varFilter).length > 0) {
            for (const [keyFilter, valFilter] of Object.entries(varFilter)) {
                if (valFilter !== undefined)
                    filter.append(keyFilter, valFilter.toString());
            }
        }
        return filter;
    }


    getCompanyById = async (token, id) => {
        return await this.localGetResource(`/company/${id}`, 'GET', null, token);
    };

    deleteUser = async (token, id) => {
        return await this.localGetResource(`/user/${id}`, 'DELETE', null, token);
    };
    addUser = async (token, user_group_id, country_id, user_type_id,
                     language_id, user_name, email, password,
                     first_name, last_name, street, house_number,
                     zip_code, city, enabled, license_id) => {
        return await this.localGetResource(`/user`, 'post', {
            user_group_id, country_id, user_type_id, language_id, user_name,
            email, password, first_name, last_name, street,
            house_number, zip_code, city, enabled, license_id
        }, token);
    };

    updateUser = async (token, id, {
        user_group_id, country_id, user_type_id,
        language_id, user_name, email, password,
        first_name, last_name, street, house_number,
        zip_code, city, enabled, license_id, old_password = ''
    }) => {
        return await this.localGetResource(`/user/${id}`, 'put', {
            user_group_id, country_id, user_type_id, language_id, user_name,
            email, password, first_name, last_name, street,
            house_number, zip_code, city, enabled, license_id, old_password
        }, token);
    };

    addUserOption = async (token, id, option, value) => {
        return await this.localGetResource(`/user/${id}/option`, 'post', {option, value}, token);
    };

    addUserRight = async (token, id, right_id, user_group_id) => {
        return await this.localGetResource(`/user/${id}/right`, 'post', {right_id, user_group_id}, token);
    };

    deleteUserRight = async (token, id, right_id = null, user_group_id = null) => {
        let filter = new URLSearchParams();
        right_id && filter.append("right_id", right_id);
        user_group_id && filter.append("user_group_id", user_group_id);
        return await this.localGetResource(`/user/${id}/right?` + filter.toString(), 'delete', null, token);
    };

    deleteUserOption = async (token, id) => {
        return await this.localGetResource(`/user/${id}/option`, 'delete', null, token);
    };

    deleteRight = async (token, id) => {
        return await this.localGetResource(`/right/${id}`, 'DELETE', null, token);
    };

    addGroup = async (token, company_id, name) => {
        return await this.localGetResource(`/usergroup`, 'post', {company_id, name}, token);
    };

    addCompany = async (token, company_licenses, name) => {
        return await this.localGetResource(`/company`, 'post', {company_licenses, name}, token);
    };

    updateCompany = async (token, id, name) => {
        return await this.localGetResource(`/company/${id}`, 'put', {name}, token);
    };

    updateBenchmark = async (token, id, name, description, calcelements_setting, return_values, settings) => {
        //  console.log(id, name, description, calcelements_setting, return_values, settings)
        return await this.localGetResource(`/benchmark/${id}`, 'put', {
            name,
            description,
            calcelements_setting,
            return_values,
            settings
        }, token);
    };

    updateTermCategory = async (token, id, locale_name, name, description, category_type, settings, global_visible) => {
        return await this.localGetResource(`/term_category/${id}`, 'put', {
            locale_name, name, description, category_type, settings, global_visible
        }, token);
    };




    deleteCompany = async (token, id) => {

        return await this.localGetResource(`/company/${id}`, 'delete', null, token);
    };

    deleteBenchmark = async (token, id) => {
        return await this.localGetResource(`/benchmark/${id}`, 'delete', null, token);
    };

    deleteTermCategory = async (token, id, clear = 0) => {
        let filter = new URLSearchParams();
        clear && filter.append("clear", clear.toString());
        console.log('deleteTermCategory', `/term_category/${id}?` + filter.toString())
        return await this.localGetResource(`/term_category/${id}?` + filter.toString(), 'delete', null, token);
    };



    addCompanyOption = async (token, id, option, value) => {
        return await this.localGetResource(`/company/${id}/option`, 'post', {option, value}, token);
    };



    calculateCategory = async (token, id) => {
        return await this.localGetResource(`/term_category/${id}/calculate`, 'get', null, token);
    };

    clearAllCache = async (token, cachetype = 0, id = undefined) => {
        let filter = new URLSearchParams();
        cachetype !== undefined && filter.append("cachetype", cachetype.toString());
        id !== undefined && filter.append("id", id.toString());
        console.log('clearAllCache', `/clearcache?` + filter.toString())
        return await this.localGetResource(`/clearcache?` + filter.toString(), 'get', null, token);
    };

    deleteAllCompanyOption = async (token, id) => {
        return await this.localGetResource(`/company/${id}/option`, 'delete', null, token);
    };

    addCompanyLicense = async (token, id, license_id) => {
        return await this.localGetResource(`/company/${id}/license`, 'post', {license_id}, token);
    };

    deleteCompanyLicense = async (token, id, license_id) => {
        return await this.localGetResource(`/company/${id}/license?license_id=${license_id}`, 'delete', null, token);
    };

    deleteBenchmarkUGC = async (token, benchmark_id, uid, gid, cid) => {
        // console.log(`/benchmark/ugc/${benchmark_id}/${uid}/${gid}/${cid}`)
        return await this.localGetResource(`/benchmark/ugc/${benchmark_id}/${uid}/${gid}/${cid}`, 'delete', null, token);
    };

    deleteTermCategoryUGC = async (token, category_id, uid, gid, cid) => {
        return await this.localGetResource(`/term_category/ugc/${category_id}/${uid}/${gid}/${cid}`, 'delete', null, token);
    };

    deleteGroup = async (token, id) => {
        return await this.localGetResource(`/usergroup/${id}`, 'delete', null, token);
    };

    updateGroup = async (token, id, company_id, name) => {
        return await this.localGetResource(`/usergroup/${id}`, 'put', {company_id, name}, token);
    };

    addGroupOption = async (token, id, option, value) => {
        return await this.localGetResource(`/usergroup/${id}/option`, 'post', {option, value}, token);
    };
    deleteGroupOption = async (token, id) => {
        return await this.localGetResource(`/usergroup/${id}/option`, 'delete', null, token);
    };

    getLicenses = async () => {
        return await this.localGetResource(`/license`, 'get', null, null);
    };

    getUserTypes = async () => {
        return await this.localGetResource(`/usertype`, 'get', null, null);
    };

    getTreeTager = async (token, locale_name, text) => {
        return await this.localGetResource(`/treetager`, 'post', {locale_name, text}, token);
    };

    addRight = async (token, id, user_type_id) => {
        return await this.localGetResource(`/right`, 'post', {id, user_type_id}, token);
    };

    addMultilang = async (token, element_id, table_name, value_name, text, language_short_name) => {
        return await this.localGetResource(`/multilang`, 'post', {
            element_id,
            table_name,
            value_name,
            text,
            language_short_name
        }, token);
    };

    deleteMultilang = async (token, element_id, table_name, value_name, language_short_name) => {
        return await this.localGetResource(`/multilang?element_id=${element_id}&table_name=${table_name}&value_name=${value_name}&language_short_name=${language_short_name}`,
            'delete', null, token);
    };

    // benchmarks
    runBenchmark = async (token, id, locale_name, text) => {
        return await this.localGetResource(`/benchmark/${id}`, 'post', {locale_name, text}, token);
    };
    getTextTypes = async (token) => {
        return await this.localGetResource(`/texttype`, 'get', null, token);
    };
    getBenchmarks = async (token) => {
        return await this.localGetResource(`/benchmark`, 'get', null, token);
    };
    getBenchmarkTemplates = async (token) => {
        return await this.localGetResource(`/benchmark_template`, 'get', null, token);
    };
}
