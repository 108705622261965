import React, {useState} from 'react';
import {Button, Col, Form, Popover, Row} from "react-bootstrap";
import {convertArrayToText} from "../../utils";

const prepareReplacement = (replacement) => {
    let returnReplacements = {
        false: {
            fullLength: replacement.length,
            meaning: false,
            replacements: []
        }
    };
    if (replacement.length > 0) {
        for (let index in replacement) {
            const {meaning, description, lemma, status, words, tag} = replacement[index]

            if (meaning && meaning.length > 0) {
                if (returnReplacements[meaning] === undefined) {
                    let meaningArray;
                    try {
                        meaningArray = JSON.parse(meaning)
                        meaningArray[0] = convertArrayToText(meaningArray[0]);
                    } catch (e) {
                        meaningArray = [meaning, '']
                    }
                    returnReplacements[meaning] = {
                        meaning: meaningArray,
                        replacements: []
                    }
                }
                returnReplacements[meaning].replacements.push({description, lemma, status, words, tag, index})
            } else {
                returnReplacements[false].replacements.push({description, lemma, status, words, tag, index})
            }
        }
    }
    return returnReplacements

}

const PopupColorWords = (props) => {
    const [repl, setRepl] = useState(0);
    const {
        goNextPopup,
        replaceFromPopup,
        popover: {
            left, top,
            data: {term, replacement, position, currentPosition, category},
            popId
        }
    } = props;

    const preparedReplacement = prepareReplacement(replacement);
   // console.log("preparedReplacement", preparedReplacement);

    const partWords = {s:'subject', o:'object', v:'verb', n:'noun', a:'adverb'};

    return (
        <Popover
            id={"popover" + popId}
            style={{left, top, maxWidth: 'none'}}
        >
            <Popover.Title as="h3">Color words replacement suggestion</Popover.Title>
            <Popover.Content>
                <Row>
                    <Col className="font-weight-bold p-2">
                        {JSON.parse(term['words']).join(" ")}
                    </Col>
                </Row>
                <Row>
                    <Col className="font-weight-bold p-2">Suggestion</Col>

                </Row>
                {preparedReplacement[false].fullLength > 0 ?
                    <Row>
                        <Col md={8} className="p-2">
                            <Form.Control
                                size={"xs"}
                                as="select"
                                htmlSize={5}
                                value={repl}
                                onChange={(event) => setRepl(event.target.value)}
                                name="suggestions">
                                {Object.values(preparedReplacement).map(meaningVal => {
                                    if (meaningVal.replacements.length > 0) {
                                        return (
                                            <optgroup key={meaningVal.meaning && meaningVal.meaning[0]} label={meaningVal.meaning && meaningVal.meaning[0]+` (${partWords[meaningVal.meaning[1]]})`}>
                                                {
                                                    meaningVal.replacements.map((valRep, index) => {
                                                        // console.log('JSON.parse', valRep)
                                                        let text = '';
                                                        try {
                                                            text = convertArrayToText(JSON.parse(valRep['words']))
                                                        } catch (e) {
                                                            console.log('error parse', valRep)
                                                        }
                                                        return (
                                                            <option key={valRep.index}
                                                                    value={valRep.index}>{text}</option>
                                                        );

                                                    })
                                                }
                                            </optgroup>
                                        );
                                    }
                                })}
                            </Form.Control>
                        </Col>
                        <Col className=" p-2">
                            <Button
                                onClick={() => replaceFromPopup(popId, repl, 'color')}
                                variant="secondary">
                                Replace
                            </Button>
                        </Col>
                    </Row> :
                    <Row>
                        <Col className="p-2">
                            No suggestions, hooray!
                        </Col>
                    </Row>

                }

            </Popover.Content>
        </Popover>
    );
};

export default PopupColorWords;
