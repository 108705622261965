
export const modalUpdateData = (id, data) => (dispatch) => {
    dispatch({
        type: 'MODAL_UPDATE_DATA',
        payload: {id, data}
    });
};

export const modalReplaceData = (id, data) => (dispatch) => {
    dispatch({
        type: 'MODAL_UREPLACE_DATA',
        payload: {id, data}
    });
};

export const modalUpdate = (id, data) => (dispatch) => {
    dispatch({
        type: 'MODAL_UPDATE',
        payload: {id, data}
    });
};

export const modalReplace = (id, data) => (dispatch) => {
    dispatch({
        type: 'MODAL_REPLACE',
        payload: {id, data}
    });
};

export const modalOpenClose = (id, data) => (dispatch) => {
    dispatch({
        type: 'MODAL_OPEN_CLOSE',
        payload: {id, data}
    });
};

export const modalCleanAll = () => (dispatch) => {
    dispatch({
        type: 'MODAL_CLEAN_ALL',
    });
};

export const modalDelete = (id) => (dispatch) => {
    dispatch({
        type: 'MODAL_DELETE',
        payload: {id}
    });
};

export const modalAdd = (data) => (dispatch) => {
    dispatch({
        type: 'MODAL_ADD',
        payload: {data}
    });
};