import React from "react";
import {checkUserRight, compose, createDefaultMultilangObject, getAllModalFunctions} from "../../../utils";
import {withTranslation} from "react-i18next";
import {withTLService} from "../../hoc";
import {connect} from "react-redux";
import {Button} from "react-bootstrap";
import DataViewer from "../../elements/data-viewer";
import {bindActionCreators} from "redux";
import {
    adminGetAllCalcelements,
    adminGetAllCompanies,
    adminGetAllGroups,
    adminGetAllReturnValues,
    adminGetAllTextTypes,
    adminGetAllBenchmarkTemplates,
    adminGetAllSettings,
    modalDelete
} from "../../../actions";
import ModalViewBenchmarkTemplate from "../../modals/modal-view-benchmark-template";
import ModalAddEditBenchmark from "../../modals/modal-add-edit-benchmark";


class Benchmarks extends DataViewer {

    /*  define count of modal*/
    modalWindowsCount = 2;

    tableFilters = {
        deleted: {
            name: 'Show deleted elements',
            type: 'integer',
            default: 0
        },
        id: {
            name: 'Benchmark id',
            type: 'integer',
            default: undefined
        },
        name: {
            name: 'Benchmark name',
            type: 'multilang',
            default: undefined
        },
        description: {
            name: 'Benchmark description',
            type: 'multilang',
            default: undefined
        }
    };

    functions = {
        getAll: this.props.tlService.getFilteredBenchmarks,
        massDelete: data => this.actionMassDelete(data)
    };

    modalComponents = {
        modalAdd: ModalAddEditBenchmark,
        modalEdit: ModalAddEditBenchmark,
        modalCopy: ModalAddEditBenchmark,
        modalCreateTemplate: ModalAddEditBenchmark,
        modalView: ModalViewBenchmarkTemplate,
    };

    table_name = false;

    extended_fields = []

    componentDidMount() {

        const {
            userData: {token, user},
            t: translate
        } = this.props;

        this.columns_default = [
            {
                name: 'id',
                selector: 'id',
                sortable: true
            },
            {
                name: 'name',
                selector: 'name',
                cell: row => {
                    const name = row.name;
                    return (<ul>
                        {Object.entries(name).map(([key, val]) => <li key={key}>({key}) {val}</li>)}
                    </ul>)
                }
            },
            {
                name: 'description',
                selector: 'description',
                cell: row => {
                    const name = row.description;
                    return (<ul>
                        {Object.entries(name).map(([key, val]) => <li key={key}>({key}) {val}</li>)}
                    </ul>)
                }
            },
            {
                name: 'rights',
                selector: 'rights',
                cell: row => {
                    const right = row.rights;
                    return (
                        <table className="table table-sm font-smaller">
                            <thead>
                            <tr>
                                <th>{translate('user')}</th>
                                <th>{translate('group')}</th>
                                <th>{translate('company')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {right && right.map(val =>
                                <tr key={JSON.stringify(val)}>
                                    <td>{(val[0] && val[0][0] > 0 ? `${val[0][1]} (${val[0][0]})` : '-')}</td>
                                    <td>{(val[1] && val[1][0] > 0 ? `${val[1][1]} (${val[1][0]})` : '-')}</td>
                                    <td>{(val[2] && val[2][0] > 0 ? `${val[2][1]} (${val[2][0]})` : '-')}</td>
                                </tr>
                            )}
                            </tbody>

                        </table>)
                }
            },
            {
                name: 'edit',
                cell: row => {
                    return (
                        <div className="btn-group">
                            {checkUserRight(user, [601, 602]) &&
                            (<>
                                <Button onClick={() => this.openModal(0, row, 'modalEdit')} className="ml-2"><i
                                    className="fas fa-edit"/></Button>
                                <Button onClick={() => this.openModal(0, row, 'modalCopy')} className="ml-2"><i
                                    className="fas fa-copy"/></Button>
                                <Button onClick={() => this.openModal(0, row, 'modalCreateTemplate')}
                                        className="btn-success  ml-2"><i
                                    className="fas fa-file-import"/></Button>
                                <Button onClick={() => this.openModal(0, row, 'modalDelete')}
                                        className="btn-danger ml-2"><i
                                    className="fas fa-trash"/></Button>
                            </>)

                            }
                        </div>
                    )
                }
            }
        ];

        // check for enable page
        this.renderIt = checkUserRight(user, [601, 602]);

        // check add right
        this.addButton = checkUserRight(user, [601, 602]);

        this.props.adminGetAllBenchmarkTemplates(token);
        this.props.adminGetAllCalcelements(token);
        this.props.adminGetAllReturnValues(token);
        this.props.adminGetAllCompanies(token);
        this.props.adminGetAllTextTypes(token);
        this.props.adminGetAllGroups(token);
        this.props.adminGetAllSettings(token);


        this.modalSettings['modalAdd'] = {
            action: this.actionAdd,
            data: {
                id: false,
                benchmark_template: 0,
                name: createDefaultMultilangObject(),
                description: createDefaultMultilangObject(),
                settings: {},
                text_type_id: "",
                calcelements: [],
                return_values: [],
                locale_name: "",
                rights: []
            },
            show: false,
            header: translate('Add Benchmark'),
            footer: true,
            text: '',
            saveButton: true,
            saveButtonText: translate('Save'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        };
        this.modalSettings['modalEdit'] = {
            action: this.actionEdit,
            data: {
                id: false,
                benchmark_template: 0,
                name: {},
                description: {},
                settings: {},
                text_type_id: "",
                calcelements: [],
                return_values: [],
                locale_name: "",
                rights: [],
                old_rights: []
            },
            show: false,
            header: translate('Edit Benchmark'),
            footer: true,
            text: '',
            saveButton: true,
            saveButtonText: translate('Save'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        };
        this.modalSettings['modalCopy'] = {
            action: this.actionAdd,
            data: {
                id: false,
                benchmark_template: 0,
                name: {},
                description: {},
                settings: {},
                text_type_id: "",
                calcelements: [],
                return_values: [],
                locale_name: "",
                rights: [],
                old_rights: []
            },
            show: false,
            header: translate('Copy Benchmark'),
            footer: true,
            text: '',
            saveButton: true,
            saveButtonText: translate('Save'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        };
        this.modalSettings['modalCreateTemplate'] = {
            action: this.actionCreateTemplate,
            data: {
                id: false,
                benchmark_template: 0,
                name: {},
                description: {},
                settings: {},
                text_type_id: "",
                calcelements: [],
                return_values: [],
                locale_name: "",
                rights: [],
                old_rights: []
            },
            show: false,
            header: translate('Create Template From Benchmark'),
            footer: true,
            text: '',
            saveButton: true,
            saveButtonText: translate('Save'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        };

        this.modalSettings['modalView'] = {
            action: false,
            data: {
                id: false,
                benchmark_template: 0,
                name: {},
                description: {},
                settings: {},
                text_type_id: "",
                calcelements: [],
                return_values: [],
                locale_name: "",
                rights: []
            },
            show: false,
            header: translate('View Benchmark'),
            footer: false,
            text: '',
            saveButton: false,
            saveButtonText: translate('Save'),
            closeButton: false,
            closeButtonText: translate('Close'),
            ok: true
        };
        this.modalSettings['modalDelete'] = {
            action: this.actionDelete,
            data: {
                id: false,
            },
            show: false,
            header: translate('Delete Benchmark'),
            footer: true,
            text: "Delete Benchmark?",
            saveButton: true,
            saveButtonText: translate('Delete'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        };
        this.modalSettings['modalMassDelete'] = {
            action: this.actionDoMassDelete,
            data: {},
            show: false,
            header: translate('Delete Selected Benchmarks'),
            footer: true,
            text: translate('Delete Selected Benchmarks'),
            saveButton: true,
            saveButtonText: translate('Delete'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        }

        this.setState({
            filters: {},
            limits: {
                limit: 10,
                offset: 0,
                order_by: 'id',
                order: 0
            },
            page: {
                pageHeader: translate('Benchmark'),
                addNewText: translate('Add new benchmark'),
            }
        });

        super.componentDidMount();
    }

    reloadTable(data, totalRows) {
        const columns_default = this.columns_default;
        const {t: translate} = this.props;
        if (data && data.length > 0) {
            let newData = [];
            for (let i of data) {
                const {
                    id,
                    benchmark_template_id,
                    name,
                    description,
                    rights,
                    owner,
                    text_type_id,
                    calcelements,
                    locale_name,
                    return_values,
                    settings
                } = i;
                newData.push({
                    id,
                    benchmark_template_id,
                    name,
                    description,
                    rights,
                    owner,
                    text_type_id,
                    calcelements,
                    locale_name,
                    return_values,
                    settings,
                    old_rights: []
                });
            }
            /* update columns */
            let newColumns = [];
            for (let i of columns_default) {
                const {name, selector, sortable, cell} = i;
                if (cell === undefined) newColumns.push({name: translate(name), selector, sortable})
                else newColumns.push({name, selector, cell})
            }

            this.setState({
                totalRows,
                columns: newColumns,
                rows: newData,
                lang: this.props.i18n.language
            });
        } else {
            this.setState({
                columns: [],
                rows: [],
                lang: this.props.i18n.language
            });
        }
    }

    addReturnValues(return_values, settings) {
        let tReturnValues = [];

        console.log('addReturnValues settings', settings);

        // add return_values
        for (const [, settings_value] of Object.entries(settings)) {
            if (settings_value?.settings?.return_values
                && settings_value?.settings?.return_values?.length > 0) {
                settings_value['settings']['return_values'].map(value => {
                    if (tReturnValues.indexOf(value) === -1) {
                        tReturnValues.push(value);
                    }
                })

            }
        }

        console.log('tReturnValues', tReturnValues);
        return tReturnValues;
    }

    actionAdd = async (data) => {
        const {
            tlService,
            userData: {token}
        } = this.props;

        let id = false;

        let tReturnValues = this.addReturnValues(data.return_values, data.settings);

        console.log('data', data)


        try {
            const ret = await tlService.addBenchmark(
                token,
                JSON.stringify(data.name),
                JSON.stringify(data.description),
                data.benchmark_template ? data.benchmark_template : data.benchmark_template_id,
                JSON.stringify(data.settings),
                JSON.stringify(tReturnValues),
                JSON.stringify(data.settings)
            );
            id = ret.id
        } catch (e) {
            console.log('error ' + e);
            this.setState({error: e.message});
            return false;
        }

        if (!id) {
            this.setState({error: 'No benchmark id!'});
            return false;
        }

        //right
        if (data.rights.length > 0) {
            for (const right of data.rights) {
                try {
                    await tlService.addBenchmarkUGC(token, id,
                        right[0] ? parseInt(right[0][0]) : 0,
                        right[1] ? parseInt(right[1][0]) : 0,
                        right[2] ? parseInt(right[2][0]) : 0
                    );
                } catch (e) {
                    console.log('error ' + e);
                    this.setState({error: e.message});
                    return false;
                }
            }
        }
        return true;
    }

    actionCreateTemplate = async (data) => {
        const {
            tlService,
            userData: {token}
        } = this.props;


        let tReturnValues = this.addReturnValues(data.return_values, data.settings);

        console.log('actionCreateTemplate', data)
        console.log(data.calcelements.sort((a, b) => a[1] - b[1]))

        //(token, name, description, text_type_id, default_calcelements, locale_name, return_values, settings)

        try {
            await tlService.addBenchmarkTemplate(
                token,
                JSON.stringify(data.name),
                JSON.stringify(data.description),
                data.text_type_id,
                JSON.stringify(data.calcelements.sort((a, b) => a[1] - b[1])),
                data.locale_name,
                JSON.stringify(tReturnValues),
                JSON.stringify(data.settings),
            );
        } catch (e) {
            console.log('error ' + e);
            this.setState({error: e.message});
            return false;
        }
        return true;
    }

    actionEdit = async (data) => {
        const {
            tlService,
            userData: {token},
        } = this.props;


        console.log('data actionEdit', data)

        let tReturnValues = this.addReturnValues(data.return_values, data.settings);

        try {
            await tlService.updateBenchmark(token,
                parseInt(data.id),
                JSON.stringify(data.name),
                JSON.stringify(data.description),
                null,
                JSON.stringify(tReturnValues),
                JSON.stringify(data.settings));
        } catch (e) {
            console.log('error ' + e);
            this.setState({error: e.message});
            return false;
        }

        // update rights

        //const company = await tlService.getCompanyById(token, parseInt(data.id));
        let toDelete = [];
        let toAdd = [];
        if (data.old_rights && data.old_rights.length > 0) {
            toDelete = data.old_rights.filter(d => !data.rights.includes(d));
            toAdd = data.rights.filter(d => !data.old_rights.includes(d));
        } else {
            toAdd = data.rights;
        }

        // console.log('data.old_rights ', data.old_rights);
        // console.log('data.rights', data.rights);

        // console.log('toAdd');
        // console.log(toAdd);
        // console.log('toDelete');
        // console.log(toDelete);


        for (let right of toAdd) {
            try {
                await tlService.addBenchmarkUGC(token,
                    data.id,
                    right[0] ? parseInt(right[0][0]) : 0,
                    right[1] ? parseInt(right[1][0]) : 0,
                    right[2] ? parseInt(right[2][0]) : 0
                );
            } catch (e) {
                console.log('error ' + e);
                this.setState({error: e.message});
                return false;
            }
        }
        for (let right of toDelete) {
            try {
                await tlService.deleteBenchmarkUGC(token,
                    data.id,
                    right[0] ? parseInt(right[0][0]) : 0,
                    right[1] ? parseInt(right[1][0]) : 0,
                    right[2] ? parseInt(right[2][0]) : 0
                );
            } catch (e) {
                console.log('error ' + e);
                this.setState({error: e.message});
                return false;
            }
        }
        return true;
    }

    actionCopy = async (data) => {
        const {
            tlService,
            userData: {token},
        } = this.props;


        console.log('data actionCopy', data)

        let tReturnValues = this.addReturnValues(data.return_values, data.settings);
        let id = false;

        try {
            const ret = await tlService.addBenchmark(
                token,
                JSON.stringify(data.name),
                JSON.stringify(data.description),
                data.benchmark_template,
                JSON.stringify(data.settings),
                JSON.stringify(tReturnValues),
                JSON.stringify(data.settings)
            );
            id = ret.id
        } catch (e) {
            console.log('error ' + e);
            this.setState({error: e.message});
            return false;
        }

        if (!id) {
            this.setState({error: 'No benchmark id!'});
            return false;
        }

        //right
        if (data.rights.length > 0) {
            for (const right of data.rights) {
                try {
                    await tlService.addBenchmarkUGC(token, id,
                        right[0] ? parseInt(right[0][0]) : 0,
                        right[1] ? parseInt(right[1][0]) : 0,
                        right[2] ? parseInt(right[2][0]) : 0
                    );
                } catch (e) {
                    console.log('error ' + e);
                    this.setState({error: e.message});
                    return false;
                }
            }
        }
        return true;
    }

    actionDelete = async (data) => {
        const {
            tlService,
            userData: {token},
        } = this.props;

        // delete  Benchmark
        try {
            await tlService.deleteBenchmark(token, parseInt(data.id));
        } catch (e) {
            console.log('error ' + e);
            this.setState({error: e.message});
            return false;
        }
        return true;
    }

}

const mapStateToProps = ({userData, modalData, openData, adminData}) => {
    return {userData, openData, modalData, adminData};
};

const mapDispatchToProps = (dispatch, {tlService}) => {
    return bindActionCreators({
        ...getAllModalFunctions(),
        adminGetAllCompanies: adminGetAllCompanies(tlService),
        adminGetAllGroups: adminGetAllGroups(tlService),
        adminGetAllCalcelements: adminGetAllCalcelements(tlService),
        adminGetAllReturnValues: adminGetAllReturnValues(tlService),
        adminGetAllTextTypes: adminGetAllTextTypes(tlService),
        adminGetAllBenchmarkTemplates: adminGetAllBenchmarkTemplates(tlService),
        adminGetAllSettings: adminGetAllSettings(tlService)

    }, dispatch);
};

export default compose(
    withTranslation(),
    withTLService(),
    connect(mapStateToProps, mapDispatchToProps)
)(Benchmarks);
