import BaseModal from "../base-modal";
import {bindActionCreators} from "redux";
import {compose, convertArrayToText, getAllModalFunctions, getDataFromModals} from "../../../utils";
import {withTranslation} from "react-i18next";
import {withTLService} from "../../hoc";
import {connect} from "react-redux";
import {Col, Form, Row, Tab, Tabs} from "react-bootstrap";
import React from "react";
import config from "../../../config";
import SettingTable from "../../modal-elements/setting-table";


class ModalAddEditReplacement extends BaseModal {
    componentDidMount() {
        const {index} = this.props;
        const data = getDataFromModals(this.props, index);
        const {id, words, meaning} = data;

        // edit
        if (id > 0) {
            if (words.length > 0) {
                this.handleChange({target: {name: 'rawWords', value: convertArrayToText(words)}});
            }
            try {
                const arrMeaning = JSON.parse(meaning);
                this.handleChange({target: {name: 'meaningText', value: convertArrayToText(arrMeaning[0])}});
                this.handleChange({target: {name: 'meaningType', value: arrMeaning[1] ? arrMeaning[1] : ''}});
            } catch (e) {
                console.log(e)
            }
        }
    }

    render() {
        const {index, t: translate} = this.props;
        const data = getDataFromModals(this.props, index);
        const {rawWords, meaningText, meaningType, description} = data;
        console.log('render', {rawWords, meaningText, meaningType, description})
        const meaningTypesList = config['meaningTypesList'];

        return (
            <Tabs className="font-smaller" id="main-tab">
                <Tab tabClassName="p-2 my-2" key="replace" eventKey="replace" title={translate('replace tab')}>
                    <Row className="m-2">
                        <Col>
                            <Row>
                                <Col className="p-1">
                                    <Form.Control
                                        size="xs"
                                        type="text"
                                        name="rawWords"
                                        value={rawWords}
                                        onChange={this.handleChange}
                                        onKeyPress={this.handleKeyPress}
                                        placeholder="Enter words"/>
                                </Col>
                            </Row>
                        </Col>

                        <Col>
                            <Row>
                                <Col className="p-1">
                                    <Form.Control
                                        size="xs"
                                        type="text"
                                        name="meaningText"
                                        value={meaningText}
                                        onChange={this.handleChange}
                                        onKeyPress={this.handleKeyPress}
                                        placeholder="Enter meaning"/>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="p-1">
                                    <Form.Control
                                        size="xs"
                                        onChange={this.handleChange}
                                        onKeyPress={this.handleKeyPress}
                                        value={meaningType}
                                        name="meaningType"
                                        as="select">
                                        <option key='' value=''>{translate("Meaning Type")}</option>
                                        {Object.entries(meaningTypesList).map(([key, value]) =>
                                            <option key={key} value={key}>{translate(value)}</option>
                                        )}
                                    </Form.Control>
                                </Col>
                            </Row>
                        </Col>
                        <Col className="p-1">
                            <Form.Control
                                size="xs"
                                onChange={this.handleChange}
                                value={description}
                                name="description"
                                rows={3}
                                as="textarea"/>
                        </Col>
                    </Row>
                </Tab>
                <Tab tabClassName="p-2 my-2" key="setting" eventKey="setting" title={translate('setting tab')}>
                    <SettingTable
                        addText='Add setting'
                        settingText='Settings'
                        valueText='Value'
                        valuePlaceholder='Add Value'
                        selectText='Select setting name'
                        settings={config['termSettings']}
                        settingFieldName={"settings"}
                        {...this.props}
                    />
                </Tab>
            </Tabs>
        );
    }

}

const mapStateToProps = ({userData, modalData, openData, adminData}) => {
    return {userData, openData, modalData, adminData};
};

const mapDispatchToProps = (dispatch, {tlService}) => {
    return bindActionCreators({
        ...getAllModalFunctions(),
    }, dispatch);
};

export default compose(
    withTranslation(),
    withTLService(),
    connect(mapStateToProps, mapDispatchToProps)
)(ModalAddEditReplacement);