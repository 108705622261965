import React from "react";
import {checkUserRight, compose, getAllModalFunctions} from "../../../utils";
import {withTranslation} from "react-i18next";
import {withTLService} from "../../hoc";
import {connect} from "react-redux";
import {Button} from "react-bootstrap";
import DataViewer from "../../elements/data-viewer";
import {bindActionCreators} from "redux";
import {
    adminGetAllCalcelements,
    adminGetAllCompanies, adminGetAllGroups,
    adminGetAllOptions,
    adminGetAllReturnValues,
    adminGetAllTextTypes
} from "../../../actions";
import ModalViewBenchmarkTemplate from "../../modals/modal-view-benchmark-template";



class BenchmarkTemplates extends DataViewer {

    functions = {
        getAll: this.props.tlService.getAllBenchmarkTemplates
    };
    components = {
        modalAdd: ModalViewBenchmarkTemplate,
        modalEdit: ModalViewBenchmarkTemplate,
        modalView: ModalViewBenchmarkTemplate,
    };
    table_name = 'benchmark_templates';

    extended_fields = []


    componentDidMount() {
        const {
            userData: {token, user},
            t: translate
        } = this.props;

        this.columns_default = [
            {
                title: 'id',
                prop: 'id',
                sortable: true
            },
            {
                title: 'locale_name',
                prop: 'locale_name',
                sortable: true
            },
            {
                title: 'return_values',
                prop: 'return_values',
                cell: row => {
                    return (<ul>
                        {JSON.parse(row.return_values).map(val => <li key={val}>{val}</li>)}
                    </ul>)
                }
            },
            {
                title: 'default_calcelements',
                prop: 'default_calcelements',
                cell: row => {
                    return (<ol>
                        {JSON.parse(row.default_calcelements).map(val => <li key={val}>{JSON.stringify(val)}</li>)}
                    </ol>)
                }

            },
            {
                title: 'text_type_id',
                prop: 'text_type_id',
                sortable: true

            },
            {
                title: 'edit',
                prop: 'id',
                cell: row => {
                    return (
                        <div className="btn-group">
                            <Button onClick={() => this.viewModal(row.id)}><i className="fas fa-eye"/></Button>
                            {checkUserRight(user, [308]) &&
                            (<>
                                <Button onClick={() => this.editModal(row.id)} className="ml-2"><i
                                    className="fas fa-edit"/></Button>
                                <Button onClick={() => this.deleteModal(row.id)} className="ml-2"><i
                                    className="fas fa-trash"/></Button>
                            </>)

                            }
                        </div>
                    )
                }
            }
        ];

        // check for enable page
        this.renderIt = checkUserRight(user, [306, 307, 308]);

        // check add right
        this.addButton = checkUserRight(user, [307]);


        this.props.adminGetAllCalcelements(token);
        this.props.adminGetAllReturnValues(token);
        this.props.adminGetAllCompanies(token);
        this.props.adminGetAllTextTypes(token);
        this.props.adminGetAllGroups(token);

        this.props.modalSetAdd({
            action: this.actionAdd,
            data: {
                name: {},
                text_type_id: "",
                default_calcelements: [],
                return_values_save: [],
                locale_name: "",
            },
            show: true,
            header: translate('Add Template'),
            footer: true,
            text: '',
            saveButton: true,
            saveButtonText: translate('Save'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        });
        this.props.modalSetEdit({
            action: this.actionEdit,
            data: {
                name: '',
                company_licenses: [],
                company_options: {}
            },
            show: true,
            header: translate('Edit Template'),
            footer: true,
            text: '',
            saveButton: true,
            saveButtonText: translate('Save'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        });
        this.props.modalSetView({
            action: false,
            data: {
                name: '',
                company_licenses: [],
                company_options: {}
            },
            show: true,
            header: translate('View Template'),
            footer: false,
            text: '',
            saveButton: false,
            saveButtonText: translate('Save'),
            closeButton: false,
            closeButtonText: translate('Close'),
            ok: true
        });
        this.props.modalSetDelete({
            action: this.actionDelete,
            data: {
                id: false,
            },
            show: true,
            header: translate('Delete Template'),
            footer: true,
            text: "Delete company?",
            saveButton: true,
            saveButtonText: translate('Delete'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        });

        this.setState({
            page: {
                pageHeader: translate('Benchmark Templates'),
                addNewText: translate('Add new template'),
                keyField: 'id',
                rowsPerPage: 100
            }
        });
        super.componentDidMount();
    }

    reloadTable(data) {

        const columns_default = this.columns_default;
        const {t: translate} = this.props;
        if (data && data.length > 0) {
            let newData = [];
            for (let i of data) {
                const {id, locale_name, default_calcelements, return_values, text_type_id} = i;
                newData.push({id, locale_name, default_calcelements, return_values, text_type_id});
            }
            /* update columns */
            let newColumns = [];
            for (let i of columns_default) {
                const {title, prop, sortable, cell} = i;
                if (cell === undefined) newColumns.push({title: translate(title), prop, sortable})
                else newColumns.push({title, prop, cell})
            }

            this.setState({
                columns: newColumns,
                rows: newData,
                lang: this.props.i18n.language
            });
        }
    }

    async actionAdd(data) {
        const {
            tlService,
            userData: {token},
            t: translate
        } = this.props;

        let id = false;

        if (data.company_licenses.length < 1) {
            this.setState({error: translate('Must be at least one license')});
            return false;
        }

        try {
            const ret = await tlService.addCompany(token, parseInt(data.company_licenses[0]), data.name);
            id = ret.id
        } catch (e) {
            console.log('error ' + e);
            this.setState({error: e.message});
            return false;
        }
        // licenses
        if (data.company_licenses.length > 1) {
            data.company_licenses.map(async (license) => {
                try {
                    await tlService.addCompanyLicense(token, id, parseInt(license));
                } catch (e) {
                    console.log('error ' + e);
                    this.setState({error: e.message});
                    return false;
                }
            })
        }
        // options
        for (let tmpOption of Object.keys(data.company_options)) {
            if (data.company_options[tmpOption] && data.company_options[tmpOption].trim().length > 0) {
                try {
                    await tlService.addCompanyOption(token, id, tmpOption, data.company_options[tmpOption].trim());
                } catch (e) {
                    console.log('error ' + e);
                    this.setState({error: e.message});
                    return false;
                }
            }
        }
        return true;
    }

    async actionEdit(data) {
        const {
            tlService,
            userData: {token},
        } = this.props;


        try {
            await tlService.updateCompany(token, parseInt(data.id), data.name);
        } catch (e) {
            console.log('error ' + e);
            this.setState({error: e.message});
            return false;
        }

        // update licenses

        const newLicenses = data.company_licenses.map(lic => parseInt(lic));
        const company = await tlService.getCompanyById(token, parseInt(data.id));
        if (company && company.company_licenses) {
            const toDelete = company.company_licenses.filter(d => !newLicenses.includes(d));
            const toAdd = newLicenses.filter(d => !company.company_licenses.includes(d));

            /*
            console.log('company.company_licenses');
            console.log(company.company_licenses);
            console.log('toAdd');
            console.log(toAdd);
            console.log('toDelete');
            console.log(toDelete);
            */

            for (let license of toAdd) {
                try {
                    await tlService.addCompanyLicense(token, data.id, license);
                } catch (e) {
                    console.log('error ' + e);
                    this.setState({error: e.message});
                    return false;
                }
            }
            for (let license of toDelete) {
                try {
                    await tlService.deleteCompanyLicense(token, data.id, license);
                } catch (e) {
                    console.log('error ' + e);
                    this.setState({error: e.message});
                    return false;
                }
            }
        }
        // options

        try {
            await tlService.deleteAllCompanyOption(token, parseInt(data.id));
        } catch (e) {
            console.log('error ' + e);
            this.setState({error: e.message});
            return false;
        }

        for (let tmpOption of Object.keys(data.company_options)) {
            if (data.company_options[tmpOption] && data.company_options[tmpOption].trim().length > 0) {
                try {
                    await tlService.addCompanyOption(token, parseInt(data.id), tmpOption, data.company_options[tmpOption].trim());
                } catch (e) {
                    console.log('error ' + e);
                    this.setState({error: e.message});
                    return false;
                }
            }
        }
        return true;

    }

    async actionDelete(data) {
        const {
            tlService,
            userData: {token},
        } = this.props;

        // delete company
        try {
            await tlService.deleteCompany(token, parseInt(data.id));
        } catch (e) {
            console.log('error ' + e);
            this.setState({error: e.message});
            return false;
        }
        return true;
    }

}

const mapStateToProps = ({userData, modalData, openData, adminData}) => {
    return {userData, openData, modalData, adminData};
};

const mapDispatchToProps = (dispatch, {tlService}) => {
    return bindActionCreators({
        ...getAllModalFunctions(),
        adminGetAllCompanies: adminGetAllCompanies(tlService),
        adminGetAllGroups: adminGetAllGroups(tlService),
        adminGetAllOptions: adminGetAllOptions(tlService),
        adminGetAllCalcelements: adminGetAllCalcelements(tlService),
        adminGetAllReturnValues: adminGetAllReturnValues(tlService),
        adminGetAllTextTypes: adminGetAllTextTypes(tlService)

    }, dispatch);
};

export default compose(
    withTranslation(),
    withTLService(),
    connect(mapStateToProps, mapDispatchToProps)
)(BenchmarkTemplates);
