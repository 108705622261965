import React from 'react';
import {checkUserRight, compose, getAllModalFunctions} from "../../../utils";
import {withTranslation} from "react-i18next";
import {withTLService} from "../../hoc";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {
    adminGetAllCompanies, adminGetAllOptions
} from "../../../actions";

import ModalViewGroup from "../../modals/modal-view-group";

import {Button} from "react-bootstrap";
import DataViewer from "../../elements/data-viewer";
import ModalAddEditAllowlist from "../../modals/modal-add-edit-allowlist";


class Allowlists extends DataViewer {


    /*  define count of modal*/
    modalWindowsCount = 2;

    tableFilters = {
        deleted: {
            name: 'Show deleted elements',
            type: 'integer',
            default: 0
        },
        words: {
            name: 'Filter by words',
            type: 'string',
            default: undefined
        },
        lemma: {
            name: 'Filter by lemmas',
            type: 'string',
            default: undefined
        },
        id: {
            name: 'Term id',
            type: 'integer',
            default: undefined
        },
        description: {
            name: 'Term description',
            type: 'string',
            default: undefined
        },
        term_category_id: {
            name: 'List category id',
            type: 'integer',
            default: undefined
        },
        state: {
            name: 'state',
            type: 'integer',
            default: 0
        },
    }

    functions = {
        getAll: this.props.tlService.getFilteredAllowLists,
        massDelete: data => this.actionMassDelete(data),
        massApprove: data => this.actionMassApprove(data),
        approveAll: data => this.actionApproveAll(data)
    };

    modalComponents = {
        modalAdd: ModalAddEditAllowlist,
        modalEdit: ModalAddEditAllowlist,
        modalView: ModalViewGroup,
    };

    componentDidMount() {
        const {
            userData: {user},
            t: translate
        } = this.props;

        /* default table columns config */
        this.columns_default = [
            {
                name: 'id',
                selector: 'id',
                sortable: true
            },
            {
                name: 'term_category_name',
                selector: 'term_category_name',
                cell: row => row.term_category_name[this.props.i18n.language]
            },
            {
                name: 'check_words',
                selector: 'check_words',
                cell: row => row.check_words === 1 ? 'by word' : 'by lemma'
            },
            {
                name: 'words',
                selector: 'words',
                cell: row => row.words
            },
            {
                name: 'lemma',
                selector: 'lemma',
                cell: row => row.lemma
            },
            {
                name: 'state',
                selector: 'state',
                cell: row => row.state
            }
        ];

        this.columns_default.push({
            name: 'edit',
            cell: row => {
                return (
                    <div className="btn-group">
                        {checkUserRight(user, []) &&
                            (<>
                                <Button onClick={() => this.openModal(0, row, 'modalEdit')} className="ml-2"><i className="fas fa-edit"/></Button>
                                <Button onClick={() => this.openModal(0, row, 'modalApprove')} className="ml-2"><i
                                    className="fas fa-check"/></Button>
                                <Button onClick={() => this.openModal(0, row, 'modalDelete')} className="ml-2"><i
                                    className="fas fa-trash"/></Button>
                            </>)

                        }
                    </div>
                )
            }
        });


        // check for enable page
        this.renderIt = checkUserRight(user, []);

        // check add right
        this.addButton = checkUserRight(user, []);


        //this.props.adminGetAllCompanies(token);
        //this.props.adminGetAllOptions(token);


        this.modalSettings['modalAdd'] = {
            action: this.actionAdd,
            data: {
                name: '',
                company_id: 1,
                user_group_options: {}
            },
            show: false,
            header: translate('Add Group'),
            footer: true,
            text: '',
            saveButton: true,
            saveButtonText: translate('Save'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        };
        this.modalSettings['modalEdit'] = {
            action: this.actionEdit,
            data: {
                name: '',
                company_id: 1,
                user_group_options: {}
            },
            show: false,
            header: translate('Edit Group'),
            footer: true,
            text: '',
            saveButton: true,
            saveButtonText: translate('Save'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        };
        this.modalSettings['modalApprove'] = {
            action: this.actionApprove,
            data: {
                name: '',
                company_id: 1,
                user_group_options: {}
            },
            show: false,
            header: translate('Approve term'),
            footer: true,
            text: '',
            saveButton: true,
            saveButtonText: translate('Approve'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        };
        this.modalSettings['modalView'] = {
            action: false,
            data: {
                name: '',
                company_id: 1,
                user_group_options: {}
            },
            show: false,
            header: translate('View Group'),
            footer: true,
            text: '',
            saveButton: false,
            saveButtonText: translate('Save'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        };
        this.modalSettings['modalDelete'] = {
            action: this.actionDelete,
            data: {
                id: false,
            },
            show: false,
            header: translate('Delete Group'),
            footer: true,
            text: "Delete group?",
            saveButton: true,
            saveButtonText: translate('Delete'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        };
        this.modalSettings['modalMassDelete'] = {
            action: this.actionDoMassDelete,
            data: {},
            show: false,
            header: translate('Delete Selected Groups'),
            footer: true,
            text: translate('Delete Selected Groups'),
            saveButton: true,
            saveButtonText: translate('Delete'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        }
        this.modalSettings['modalMassApprove'] = {
            action: this.actionDoMassApprove,
            data: {},
            show: false,
            header: translate('Approve Selected Terms'),
            footer: true,
            text: translate('DApprove Selected Terms'),
            saveButton: true,
            saveButtonText: translate('Approve'),
            closeButton: true,
            closeButtonText: translate('Approve'),
            ok: true
        }

        this.setState({
            filters: {
                state: 0
            },
            limits: {
                limit: 10,
                offset: 0,
                order_by: 'id',
                order: 0
            },
            page: {
                pageHeader: translate('Groups'),
                addNewText: translate('Add new group'),
            }
        });
        super.componentDidMount();
    }

    actionMassApprove(data) {
        if (data.length > 0)
            this.openModal(this.props.index !== undefined ? this.props.index + 1 : 0, data, 'modalMassApprove')
    }

    actionDoMassApprove = async (data) => {
        for (let id of data) {
            await this.actionApprove(id)
        }
        this.getAllData();
        return true;
    }

    actionAdd = async (data) => {
        const {
            tlService,
            userData: {token},
        } = this.props;

        let id = false;

        try {
            const ret = await tlService.addGroup(token, parseInt(data.company_id), data.name);
            id = ret.id
        } catch (e) {
            console.log('error ' + e);
            this.setState({error: e.message});
            return false;
        }
        // options
        for (let tmpOption of Object.keys(data.user_group_options)) {
            if (data.user_group_options[tmpOption] && data.user_group_options[tmpOption].trim().length > 0) {
                try {
                    await tlService.addGroupOption(token, id, tmpOption, data.user_group_options[tmpOption].trim());
                } catch (e) {
                    console.log('error ' + e);
                    this.setState({error: e.message});
                    return false;
                }
            }
        }
        return true;
    }

    actionApprove = async (data) => {
        const {
            tlService,
            userData: {token},
        } = this.props;

        try {
            await tlService.addTermInCategory(token, data.term_category_id,
                {
                    words: data.words,
                    lemma: data.lemma,
                    tag: data.tag,
                    check_words: !!data.check_words ? 1 : 0
                });
            await tlService.clearAllCache(token, 1, parseInt(data.term_category_id));
        } catch (e) {
            console.log('error ' + e);
            this.setState({error: e.message});
            return false;
        }

        try {
            await tlService.updateWhitelist(token, data.id,
                {
                    state: 1,
                });

        } catch (e) {
            console.log('error ' + e);
            this.setState({error: e.message});
            return false;
        }
        return true;
    }

    actionEdit = async (data) => {
        //check_words, lemma, tag, words
        const {
            tlService,
            userData: {token},
        } = this.props;

        try {
            await tlService.updateWhitelist(token, data.id,
                {
                    lemma: data.lemma,
                    check_words: parseInt(data.check_words),
                    tag: data.tag,
                    words: data.words,
                });

        } catch (e) {
            console.log('error ' + e);
            this.setState({error: e.message});
            return false;
        }
        return true;
    }

    actionDelete = async (data) => {
        const {
            tlService,
            userData: {token},
        } = this.props;

        try {
            await tlService.deleteWhitelist(token, parseInt(data.id));
        } catch (e) {
            console.log('error ' + e);
            this.setState({error: e.message});
            return false;
        }

        return true;
    }

}

const mapStateToProps = ({userData, modalData, openData, adminData}) => {
    return {userData, openData, modalData, adminData};
};

const mapDispatchToProps = (dispatch, {tlService}) => {
    return bindActionCreators({
        ...getAllModalFunctions(),
        adminGetAllCompanies: adminGetAllCompanies(tlService),
        adminGetAllOptions: adminGetAllOptions(tlService)

    }, dispatch);
};

export default compose(
    withTranslation(),
    withTLService(),
    connect(mapStateToProps, mapDispatchToProps)
)(Allowlists);

