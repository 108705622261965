import {Button, Col, Form, Row, Tab} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {deepCopy, getDataFromModals} from "../../../utils";


export default function SectionTab(props) {

    const {
        t: translate,
        tSectionKey, tSectionVal, setState, defaultSectionSet,
        state: {tSectionAdd},
        userData: {termCategories},
        i18n: {language}
    } = props;
    const {index} = props;
    const data = getDataFromModals(props, index);
    const {settings} = data;

    const [invalidValues, setInvalidValues] = useState({});
    const [values, setValues] = useState({});

    useEffect(()=>{
        Object.entries(tSectionVal).map(([tElementKey, tElementVal]) => {
            if (tElementKey !== 'all_values') {
                if (settings[tElementKey] !== undefined) {
                    tSectionVal['all_values'].map(valueKey => {
                        if (tElementVal[valueKey] !== undefined) {
                            setValues(s=>{
                                return {...s, [`${tSectionKey}|${tElementKey}|${valueKey}`]: settings[tElementKey]['values'][valueKey]}
                            })
                        }
                    })
                }
            }
        })

    },[])

    const addSetting = () => {
        const {tSectionAdd} = props.state;
        const newSettings =  deepCopy(settings);

        const {index} = props;
        const data = getDataFromModals(props, index);

        if (defaultSectionSet[tSectionAdd] !== undefined) {
            let newSection = deepCopy(defaultSectionSet[tSectionAdd]);
            for (const [tempKey, tempVal] of Object.entries(defaultSectionSet[tSectionAdd]['values'])) {
                newSection['values'][tempKey] = tempVal[2];
            }

            if (newSettings[tSectionAdd] === undefined)
                newSettings[tSectionAdd] = {};
            newSettings[tSectionAdd] = newSection;
            props.modalUpdateData(index, {
                settings: newSettings
            });
        }

    };
    const onSectionAddChange = (event) => {
        const {value} = event.target;
        setState({tSectionAdd: value});
    }
    const deleteSetting = (ind) => {
        const [tSectionKey, tElementKey] = ind.split('|');

        const {index} = props;
        const data = getDataFromModals(props, index);
        const {settings} = data;

        let tSetting = deepCopy(settings);
        delete tSetting[tElementKey];

        props.modalUpdateData(index, {
            settings: tSetting
        });

    };
    const onChangeSettingValue = (event, type) => {
        const {value, name} = event.target;
        const [tSectionKey, tElementKey, valueKey] = name.split('|');

        const {index} = props;
        const data = getDataFromModals(props, index);
        const {settings} = data;

        let tSetting = deepCopy(settings);
        let validationError = false;
        if (type === 'boolean')
            setValues(s=>{ return{...s, [`${tSectionKey}|${tElementKey}|${valueKey}`]: !s[`${tSectionKey}|${tElementKey}|${valueKey}`]}});
        else
            setValues(s=>{ return{...s, [`${tSectionKey}|${tElementKey}|${valueKey}`]: value}});

        switch (type) {
            case 'array':
                let tVal = [];
                try {
                    tVal = JSON.parse(value);
                    if (typeof tVal !== 'object')
                        tVal = [];
                } catch(e) {
                    validationError = true;
                }
                tSetting[tElementKey]['values'][valueKey] = tVal;
                break;
            case 'integer':
                validationError = isNaN(parseInt(value));
                tSetting[tElementKey]['values'][valueKey] =  parseInt(value);
                break;
            case 'category':
                validationError = isNaN(parseInt(value));
                tSetting[tElementKey]['values'][valueKey] = parseInt(value);
                break;
            case 'number':
                validationError = isNaN(parseFloat(value));
                tSetting[tElementKey]['values'][valueKey] = parseFloat(value);
                break;
            case 'string':
                validationError = !value?.length;
                tSetting[tElementKey]['values'][valueKey] = value;
                break;
            case 'enum':
                tSetting[tElementKey]['values'][valueKey] = value;
                break;
            case 'boolean':
                tSetting[tElementKey]['values'][valueKey] = !tSetting[tElementKey]['values'][valueKey];
                break;
            default:
                break;
        }
        setInvalidValues(s=>{
            return {
                ...s,
            [`${tSectionKey}|${tElementKey}|${valueKey}`]: validationError
            }
        })
        props.modalUpdateData(index, {
            settings: tSetting
        });
    }

    let hasAddButton = false;

    //console.log ('SectionTab', tSectionKey, tSectionVal, defaultSectionSet,values)
    return (<>
            <table className="table table-bordered table-striped table-sm">
                <thead>
                <tr>
                    <th key='_'>&nbsp;</th>
                    {tSectionVal['all_values'].map(valueKeys => {
                            return <th key={valueKeys}
                                       className="font-smaller text-center my-auto py-1">{translate(valueKeys)}</th>
                        }
                    )}
                    <th className="font-smaller text-center my-auto py-1"
                        key='_edit_'>{translate('edit')}</th>
                </tr>
                </thead>
                <tbody>
                {Object.entries(tSectionVal).map(([tElementKey, tElementVal]) => {
                    if (tElementKey !== 'all_values') {
                        if (settings[tElementKey] !== undefined) {
                            return (
                                <tr key={'tr_' + tElementKey}>
                                    <td key={tElementKey}
                                        className="font-smaller my-auto">{translate(tElementKey)}</td>
                                    {tSectionVal['all_values'].map(valueKey => {
                                        const tempValue = values[`${tSectionKey}|${tElementKey}|${valueKey}`] !== undefined ?  values[`${tSectionKey}|${tElementKey}|${valueKey}`] : '';
                                            if (tElementVal[valueKey] !== undefined) {
                                                switch (tElementVal[valueKey]) {
                                                    case 'array':
                                                        let tVal;
                                                        try {
                                                            tVal = JSON.stringify(tempValue);
                                                        } catch(e) {
                                                        }
                                                        return <td key={valueKey}
                                                                   className="font-smaller my-auto py-2 px-1"
                                                        ><Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            isInvalid={!!invalidValues[`${tSectionKey}|${tElementKey}|${valueKey}`]}
                                                            name={`${tSectionKey}|${tElementKey}|${valueKey}`}
                                                            onChange={e => onChangeSettingValue(e, 'array')}
                                                            size="xs"
                                                            value={tVal.replace(/^"/, '').replace(/"$/, '')
                                                                .replace(/\\"/g, '"')}
                                                            placeholder={translate(valueKey)}/>
                                                        </td>;
                                                    case 'integer':
                                                        return <td key={valueKey}
                                                                   className="font-smaller my-auto py-2 px-1"
                                                        ><Form.Control
                                                            isInvalid={!!invalidValues[`${tSectionKey}|${tElementKey}|${valueKey}`]}
                                                            name={`${tSectionKey}|${tElementKey}|${valueKey}`}
                                                            onChange={e => onChangeSettingValue(e, 'integer')}
                                                            size="xs"
                                                            type="integer"
                                                            value={tempValue}
                                                            placeholder={translate(valueKey)}/>
                                                        </td>;
                                                    case 'string':
                                                        return <td key={valueKey}
                                                                   className="font-smaller my-auto py-2 px-1"
                                                        ><Form.Control
                                                            isInvalid={!!invalidValues[`${tSectionKey}|${tElementKey}|${valueKey}`]}
                                                            name={`${tSectionKey}|${tElementKey}|${valueKey}`}
                                                            onChange={e => onChangeSettingValue(e, 'string')}
                                                            size="xs"
                                                            type="text"
                                                            value={tempValue}
                                                            placeholder={translate(valueKey)}/>
                                                        </td>;
                                                    case 'category':
                                                        console.log("termCategories",termCategories)

                                                        return <td key={valueKey}
                                                                   className="font-smaller my-auto py-2 px-1"
                                                        ><Form.Control
                                                            size="xs"
                                                            as="select"
                                                            value={tempValue}
                                                            isInvalid={!!invalidValues[`${tSectionKey}|${tElementKey}|${valueKey}`]}
                                                            name={`${tSectionKey}|${tElementKey}|${valueKey}`}
                                                            onChange={e => onChangeSettingValue(e, 'category')}
                                                        >
                                                            {Object.keys(termCategories).map(val => {
                                                                return <option key={val}
                                                                               value={val}>{termCategories[val].name[language]}</option>
                                                            })}
                                                        </Form.Control></td>;
                                                    case 'number':
                                                        return <td key={valueKey}
                                                                   className="font-smaller my-auto py-2 px-1"
                                                        ><Form.Control
                                                            isInvalid={!!invalidValues[`${tSectionKey}|${tElementKey}|${valueKey}`]}
                                                            name={`${tSectionKey}|${tElementKey}|${valueKey}`}
                                                            onChange={e => onChangeSettingValue(e, 'number')}
                                                            size="xs"
                                                            htmlSize={"30"}
                                                            type="text"
                                                            value={tempValue}
                                                            placeholder={translate(valueKey)}/>
                                                        </td>;
                                                    case 'boolean':
                                                        return <td key={valueKey}
                                                                   className="font-smaller text-center my-auto py-2 px-1"
                                                        ><Form.Check

                                                            name={`${tSectionKey}|${tElementKey}|${valueKey}`}
                                                            onChange={e => onChangeSettingValue(e, 'boolean')}
                                                            checked={tempValue}
                                                            type="checkbox" label=''/></td>;
                                                    case 'enum':
                                                        return <td key={valueKey}
                                                                   className="font-smaller my-auto py-2 px-1"
                                                        ><Form.Control
                                                            size={"xs"}
                                                            as="select"
                                                            value={tempValue}
                                                            name={`${tSectionKey}|${tElementKey}|${valueKey}`}
                                                            onChange={e => onChangeSettingValue(e, 'enum')}
                                                        >
                                                            {Object.entries(settings[tElementKey]['limits'][valueKey]).map(([a, b]) => {
                                                                return <option key={a}
                                                                               value={a}>{translate(a)}</option>
                                                            })}
                                                        </Form.Control></td>;
                                                }
                                            } else {
                                                return <td key={valueKey}/>;
                                            }

                                        }
                                    )}
                                    <td key={"_edit_"}
                                        className="font-smaller text-center my-auto py-2 pl-2">
                                        <Button size={"xs"}
                                                onClick={() => deleteSetting(`${tSectionKey}|${tElementKey}`)}><i
                                            className="fas fa-trash"/></Button>
                                    </td>
                                </tr>
                            );
                        } else {
                            hasAddButton = true;
                        }
                    }
                })}
                </tbody>
            </table>

            {hasAddButton &&
            <Row>
                <Col md={3} className="font-smaller text-center my-auto py-2 pl-2">
                    <Form.Control
                        size={"xs"}
                        as="select"
                        value={tSectionAdd}
                        name='addSectionSelect'
                        onChange={e => onSectionAddChange(e)}
                    >
                        <option key='' value=''>{translate('Select Setting')}</option>
                        {Object.keys(tSectionVal).map((tElementKey) => {
                            if (settings[tElementKey] === undefined && tElementKey !== 'all_values') {
                                return <option key={tElementKey}
                                               value={tElementKey}>{translate(tElementKey)}</option>
                            }
                        })}
                    </Form.Control>
                </Col>
                <Col md={1} className="font-smaller text-center my-auto py-2 pl-2">
                    <Button size={"xs"}
                            onClick={() => addSetting()}><i
                        className="fas fa-plus"/></Button>
                </Col>
            </Row>
            }
        </>
    );


}
