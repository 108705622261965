import React, {useState} from "react";
import {Col, Form, Row} from "react-bootstrap";
import {getDataFromModals} from "../../../utils";


export default function EditTermMainTab(props) {
    const [tmpWords, setTmpWords] = useState('');
    const [errorWords, setErrorWords] = useState(false);
    const [tmpLemma, setTmpLemma] = useState('');
    const [errorLemma, setErrorLemma] = useState(false);

    const {index} = props;
    const data = getDataFromModals(props, index);
    const {words ,lemma} = data;

    if (words.length !== 0 && tmpWords.length === 0) {
        setTmpWords(JSON.stringify(words));
    }
    if (lemma.length !== 0 && tmpLemma.length === 0) {
        setTmpLemma(JSON.stringify(lemma));
    }
    const {
        t: translate
    } = props;

    const setErrorState = (name, text) => {
        if (name === 'words')
            setErrorWords (text);
        else if (name === 'lemma')
            setErrorLemma (text);
        return true;
    }

    const updateField = (name, text) => {
        if (name === 'words')
            setTmpWords (text);
        else if (name === 'lemma')
            setTmpLemma (text);
        return true;
    }

    const handleChangeJSON = (event, props) => {
        const {name, value} = event.target;
        const {index, t: translate} = props;
        const data = getDataFromModals(props, index);

        updateField(name, value);

        let convertedData;
        try {
            convertedData = JSON.parse(value);
        } catch (e) {
            setErrorState(name, translate('must be an JSON array'));
            return true;
        }

        if (convertedData.length !== data.tag.length) {
            setErrorState(name, translate('Lengths must be same as original text'));
            return true;
        }
        setErrorState(name,  false);
        props.modalUpdateData(index, {
            [name]: convertedData
        })
    }

    return (
        <>
            <Row>
                <Col md={1} className="p-2 font-weight-bold real-align-middle">{translate('words')}</Col>
                <Col className="p-2 font-weight-bold">
                    <Form.Control
                        isInvalid={!!errorWords}
                        size="xs"
                        type="text"
                        name="words"
                        value={tmpWords}
                        onChange={e => handleChangeJSON(e, props)}
                        placeholder="words"/>
                    {errorWords && <small className="text-danger">{errorWords}</small>}
                </Col>
                <Col md={1} className="p-2 font-weight-bold real-align-middle">{translate('lemma')}</Col>
                <Col className="p-2">
                    <Form.Control
                        isInvalid={!!errorLemma}
                        size="xs"
                        type="text"
                        name="lemma"
                        value={tmpLemma}
                        onChange={e => handleChangeJSON(e, props)}
                        placeholder="lemmas"/>
                    {errorLemma && <small className="text-danger">{errorLemma}</small>}
                </Col>
            </Row>
        </>
    )
}

