import React, {Component} from "react";
import {withTranslation} from 'react-i18next';
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import {compose, getAllModalFunctions, getDataFromModals} from "../../../utils";
import {withTLService} from "../../hoc";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import SettingTable from "../../modal-elements/setting-table";
import BaseModal from "../base-modal";


class ModalAddEditGroup extends BaseModal {

    render() {
        const {index} = this.props;
        const data = getDataFromModals(this.props, index);
        const {name, company_id} = data;

        const {
            adminData: {companies, options},
        } = this.props;

        console.log('render modal');


        return (
            <Container>
                <Row>
                    <Col md={6}>
                        <Form.Group controlId="company_id">
                            <Form.Control
                                onChange={e => this.handleChange(e)}
                                value={company_id}
                                name="company_id"
                                as="select">
                                {
                                    Object.keys(companies).length > 0 && Object.keys(companies).map((company) =>
                                        <option key={company}
                                                value={company}>{companies[company].name}</option>
                                    )
                                }
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="name">
                            <Form.Control
                                type="text"
                                name="name"
                                defaultValue={name}
                                onChange={e => this.handleChange(e)}
                                placeholder="Enter name"/>
                        </Form.Group>
                    </Col>
                </Row>

                <h1 className="h5 mb-0 text-gray-800 my-3">Options</h1>
                <SettingTable
                    addText='Add option'
                    settingText='Options'
                    valueText='Value'
                    valuePlaceholder='Add Value'
                    selectText='Select option name'
                    settings={Object.keys(options)}
                    settingFieldName='user_group_options'
                    {...this.props}
                />
            </Container>
        )
    }
}

const mapStateToProps = ({userData, modalData, openData, adminData}) => {
    return {userData, openData, modalData, adminData};
};

const mapDispatchToProps = (dispatch, {tlService}) => {
    return bindActionCreators({
        ...getAllModalFunctions(),
    }, dispatch);
};

export default compose(
    withTranslation(),
    withTLService(),
    connect(mapStateToProps, mapDispatchToProps)
)(ModalAddEditGroup);