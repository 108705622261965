import React, {useState} from 'react';
import {Link} from "react-router-dom";
import {Alert, Button, Col, Form, ProgressBar, Row} from "react-bootstrap";
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import XLSX from "xlsx";
import {
    convertArrayToText,
    convertLanguagesToFull,
    convertTextToTT,
    isObjectEmpty,
    readUploadedFileAsBinary
} from "../../../utils";
import backendLanguages from "../../../utils/backendlanguages.json";


const DataViewerTermCategoryHeader = (props) => {
    const {headerData: {category: {name, id: category_id}, refreshFunc}, page: {pageHeader}, lang} = props.state;
    const {t, tlService, userData: {token}} = props;

    const [downloadBarVisible, setDownloadBarVisible] = useState(false);
    const [downloadBarNow, setDownloadBarNow] = useState(0);
    const [uploadBarVisible, setUploadBarVisible] = useState(false);
    const [uploadBarNow, setUploadBarNow] = useState(0);
    const [uploadBarText, setUploadBarText] = useState('');
    const [uploadFile, setUploadFile] = useState(null);
    const [uploadError, setUploadError] = useState('');

    const options = {
        title: 'Are you sure want to upload?',
        message: 'All data in Category will be REMOVED!',
        buttons: [
            {
                label: 'Go',
                onClick: () => uploadTerms()
            },
            {
                label: 'Back'
            }
        ],
        childrenElement: () => <div/>,
        closeOnEscape: true,
        closeOnClickOutside: true
    };

    const prepareUploadFile = (e) => {
        setUploadFile(e.target.files[0]);
    }

    const prepareForCompare = (value) => {
        if (typeof value === 'object') {
            try {
                return JSON.stringify(value)
            } catch (e) {
                return '';
            }
        }
        return value;
    }

    const downloadTerms = async () => {
        let limit = 500;
        let offset = -limit;
        let fullcount = 0;
        let dataResult = []
        setDownloadBarVisible(true);
        do {
            offset += limit;
            const categoryData = await tlService.getFilteredTermByCategory(token,
                {limit, offset},
                {category_id: category_id});
            console.log(categoryData)
            if (categoryData['data'] && categoryData['data'].length > 0) {
                fullcount = categoryData['fullcount'];
                // convert data

                for (let dataTmp of categoryData['data']) {
                    let returnVal = {id: dataTmp['id']};
                    for (const [key, value] of Object.entries(dataTmp)) {
                        if (key === 'lemma' && value.length > 0) {
                            returnVal['lemma'] = JSON.stringify(value);
                        } else if (key === 'words' && value.length > 0) {
                            returnVal['words'] = JSON.stringify(value);
                        } else if (key === 'tag' && value.length > 0) {
                            returnVal['tag'] = JSON.stringify(value);
                        } else if (key === 'settings' && !isObjectEmpty(value)) {
                            returnVal['settings'] = JSON.stringify(value);
                        } else if (key === 'term_replacement' || key === 'id') {
                        } else {
                            returnVal = {...returnVal, [key]: value}
                        }
                    }
                    dataResult.push(returnVal)

                    // add replacements
                    if (dataTmp['term_replacement'] && dataTmp['term_replacement'].length > 0) {
                        let returnValReplacement = {id: dataTmp['id']}
                        for (let dataReplacement of dataTmp['term_replacement']) {
                            for (const [key, value] of Object.entries(dataReplacement)) {
                                if (key === 'lemma' && value.length > 0) {
                                    returnValReplacement['replacement_lemma'] = JSON.stringify(value);
                                } else if (key === 'words' && value.length > 0) {
                                    returnValReplacement['replacement_words'] = JSON.stringify(value);
                                } else if (key === 'tag' && value.length > 0) {
                                    returnValReplacement['replacement_tag'] = JSON.stringify(value);
                                } else if (key === 'settings' && !isObjectEmpty(value)) {
                                    returnValReplacement['replacement_settings'] = JSON.stringify(value);
                                } else {
                                    returnValReplacement = {...returnValReplacement, ['replacement_' + key]: value}
                                }
                            }
                            dataResult.push(returnValReplacement)
                        }

                    }

                }
                setDownloadBarNow(Math.round((offset + limit) / (fullcount / 100)))
            } else {
                break;
            }
        } while (fullcount > (offset + limit));

        setDownloadBarVisible(false);
        const workSheet = XLSX.utils.json_to_sheet(dataResult);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, workSheet, 'Sheet1');
        const bin = XLSX.write(wb, {bookType: 'xlsx', type: "array"});
        const element = document.createElement("a");
        const file = new Blob([bin], {type: 'application/vnd.ms-excel'});
        element.href = URL.createObjectURL(file);
        element.download = `downloadTerm${category_id}.xlsx`;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();


    }

    const clearCache = async () => {
        await tlService.clearAllCache(token, 1, parseInt(category_id));
    }
    const calculateSize = async () => {
        await tlService.calculateCategory(token, parseInt(category_id));
    }

    const checkWordsAndLemmas = (words, lemma) =>{
        let correct;
        try {
            const tmpWords = JSON.parse(words);
            const tmpLemma = JSON.parse(lemma);
            correct = Array.isArray(tmpWords) && Array.isArray(tmpLemma) && tmpWords.length > 0 && tmpWords.length === tmpLemma.length;
        } catch (e) {
            correct = false;
        }
        return correct;
    }

    const uploadTerms = async () => {
        const {t: translate} = props;
        setUploadError('');
        if (uploadFile) {
            if (
                uploadFile['type'] === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                uploadFile['type'] === "application/vnd.ms-excel"
            ) {
                const fileContents = await readUploadedFileAsBinary(uploadFile);
                const workbook = XLSX.read(fileContents, {type: 'binary'})
                if (workbook && workbook.SheetNames[0] && workbook.Sheets[workbook.SheetNames[0]]) {
                    const data = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], {header: 0});
                    if (data && data.length > 0) {
                        let termId = 0;
                        let counter = 0;
                        let oldTerm = {};
                        setUploadBarVisible(true);
                        // go through the file lines
                        for (let tmpData of data) {
                            counter++;
                            setUploadBarNow(Math.round(counter / (data.length / 100)));
                            setUploadBarText(`${counter} / ${data.length}`)


                            // cast boolean parameters
                            tmpData['enabled'] = isNaN(parseInt(tmpData['enabled'])) ? 0 : parseInt(tmpData['enabled']);
                            tmpData['global_visible'] = isNaN(parseInt(tmpData['global_visible'])) ? 0 : parseInt(tmpData['global_visible']);
                            tmpData['check_words'] = isNaN(parseInt(tmpData['check_words'])) ? 0 : parseInt(tmpData['check_words']);
                            tmpData['replacement_global_visible'] = isNaN(parseInt(tmpData['replacement_global_visible'])) ? 0 : parseInt(tmpData['replacement_global_visible']);


                            // if check_words is 0 and word is empty -  copy lemma to words
                            if (tmpData['check_words'] === 0 && !tmpData['words']?.length && tmpData['lemma']?.length ) {
                                tmpData['words'] = tmpData['lemma'];
                            }

                            // run only if "words" present
                            if (tmpData['words']) {

                                // if lemma is not present, get it form TT
                                if (!tmpData['lemma']) {
                                    let rawWords;
                                    if (tmpData['words'].match(/^\[.+]$/)) {
                                        console.log('match');
                                        try {
                                            rawWords = convertArrayToText(JSON.parse(tmpData['words']));
                                        } catch (e) {
                                            setUploadError(s =>  s + ` Error in converting word to JSON: ${tmpData['words']} ` + e.toString())
                                            break;
                                        }
                                    } else {
                                        rawWords = tmpData['words'];
                                    }

                                    const lemmaRes = await convertTextToTT(token, tlService, rawWords, convertLanguagesToFull(lang, backendLanguages));
                                    if (lemmaRes) {
                                        tmpData['words'] = JSON.stringify(lemmaRes['words']);
                                        tmpData['lemma'] = JSON.stringify(lemmaRes['lemma']);
                                        tmpData['tag'] = JSON.stringify(lemmaRes['tag']);
                                    } else {
                                        setUploadError(s =>  s + ` Error in converting words to lemmas: ${tmpData['words']} `)
                                        break;
                                    }
                                }

                                // check for size word/ lemma is equal
                                if (!checkWordsAndLemmas(tmpData['words'],tmpData['lemma'])) {
                                    setUploadError(s =>  s + ` lemma or word is not an array or size is not equal: ${tmpData['words']},  ${tmpData['lemma']} `)
                                    break;
                                }


                                // check for setting
                                if (tmpData['settings']) {
                                    try {
                                        JSON.parse(tmpData['settings']);
                                    } catch (e) {
                                        setUploadError(s =>  s + ` Error in setting field term: ${tmpData['words']},  ${tmpData['lemma']} ` + e.toString())
                                        break;
                                    }
                                } else {
                                    tmpData['settings'] = '{}';
                                }


                                // check if term is in category
                                try {
                                    const tmpTerm = await tlService.getFilteredTermByCategory(token, {}, {
                                        category_id: category_id,
                                        words: tmpData['words'],
                                        lemma: tmpData['lemma'],
                                    })
                                    if (tmpTerm['data'] && tmpTerm['data'][0]) {
                                        oldTerm = tmpTerm['data'][0];
                                        oldTerm['settings'] = oldTerm['settings'] ? typeof oldTerm['settings'] === "object" ?
                                            JSON.stringify(oldTerm['settings']) : oldTerm['settings'] : '{}';
                                    } else {
                                        oldTerm = {};
                                    }
                                } catch {
                                    oldTerm = {};
                                }

                                // if term is already in Term Category !!
                                if (oldTerm['id']) {
                                    const differentTerm = Object.entries(oldTerm)
                                        .filter(([key,]) => ['check_words', 'settings', 'description', 'enabled', 'global_visible', 'tag'].includes(key))
                                        .filter(([key,]) => prepareForCompare(tmpData[key]) !== prepareForCompare(oldTerm[key]));

                                    // if a new term is different from the old one, update it!
                                    if (differentTerm.length > 0) {
                                        if ( tmpData['enabled'] === 0) {
                                            //delete if enabled == 0
                                            try {
                                                await tlService.deleteTermInCategory(token, category_id, oldTerm['id']);

                                            } catch (e) {
                                                console.log(e)
                                                setUploadError(s =>  s + ` Error Delete term: ${tmpData['words']},  ${tmpData['lemma']} ` + e.toString())
                                                break;
                                            }
                                        } else {
                                            try {
                                                await tlService.updateTermInCategory(token,category_id, oldTerm['id'],{
                                                    check_words: tmpData['check_words'],
                                                    description: tmpData['description'] || undefined,
                                                    global_visible: tmpData['global_visible'],
                                                    settings: (!tmpData['settings'] || tmpData['settings'] === '{}') ? undefined : tmpData['settings']
                                                } );
                                            } catch (e) {
                                                console.log(e)
                                                setUploadError(s =>  s + ` Error Update term: ${tmpData['words']},  ${tmpData['lemma']} ` + e.toString())
                                                break;
                                            }
                                        }
                                    }
                                    termId = oldTerm['id'];

                                // termin is new - add it!
                                } else {
                                    try {
                                        let newTerm = await tlService.addTermInCategory(
                                            token,
                                            category_id,
                                            {
                                                words: tmpData['words'],
                                                lemma: tmpData['lemma'],
                                                tag: tmpData['tag'] || "[]",
                                                check_words: tmpData['check_words'],
                                                settings: (!tmpData['settings'] || tmpData['settings'] === '{}') ? undefined : tmpData['settings'],
                                                description: tmpData['description'] || undefined,
                                                global_visible: tmpData['global_visible']
                                            });
                                        if (newTerm?.data?.id) {
                                            termId = newTerm.data.id;
                                        } else {
                                            setUploadError(s =>  s + ` <br/> Error upload term ${tmpData['words']} ` + JSON.stringify(newTerm));
                                            continue;
                                        }
                                    } catch (e) {
                                        console.log(e)
                                        setUploadError(s =>  s + ` <br/> error upload term ${tmpData['words']} `+ e.toString())
                                        continue;
                                     }
                                }

                            }
                            // replace word  with lemma if not present and same way for lemma
                            if (tmpData['replacement_words']?.length || tmpData['replacement_lemma']?.length) {
                                tmpData['replacement_words'] = tmpData['replacement_words']?.length ? tmpData['replacement_words'] : tmpData['replacement_lemma'];
                                tmpData['replacement_lemma'] = tmpData['replacement_lemma']?.length ? tmpData['replacement_lemma'] : tmpData['replacement_words'];

                                // check for size word/ lemma is equal
                                if (!checkWordsAndLemmas(tmpData['replacement_words'],tmpData['replacement_lemma'])) {
                                    setUploadError(s =>  s + `replacement lemma or word is not an array or their size is not equal: ${tmpData['replacement_words']},  ${tmpData['replacement_lemma']} `)
                                    break;
                                }
                            }

                            // check replacement setting
                            if (tmpData['replacement_settings']) {
                                try {
                                    JSON.parse(tmpData['replacement_settings']);
                                } catch (e) {
                                    setUploadError(s =>  s + ` Error in replacement_settings field term: ${tmpData['words']},  ${tmpData['lemma']} ` + e.toString())
                                    break;
                                }
                            } else {
                                tmpData['replacement_settings'] = '{}';
                            }
                            //console.log('repl', '-' + termId + '-', tmpData['replacement_lemma'], tmpData['replacement_words'])
                            if (tmpData['replacement_lemma'] && tmpData['replacement_words'] && termId > 0) {

                                tmpData['replacement_meaning'] = tmpData['replacement_meaning'] || ' ';

                                // check for replacement exist
                                let oldReplacement = {};
                                let mustAddRepl = false;

                                if (oldTerm['term_replacement'] && oldTerm['term_replacement'].length > 0) {
                                    const tempRepl = oldTerm['term_replacement'].filter(value =>
                                        prepareForCompare(tmpData['replacement_words']) === prepareForCompare(value['words']) &&
                                        prepareForCompare(tmpData['replacement_lemma']) === prepareForCompare(value['lemma']) &&
                                        (!tmpData['replacement_meaning'] || prepareForCompare(tmpData['replacement_meaning']) === prepareForCompare(value['meaning']))
                                    )
                                    if (tempRepl && tempRepl[0]) {
                                        oldReplacement = tempRepl[0];
                                        oldReplacement['settings'] = oldReplacement['settings'] ? JSON.stringify(oldReplacement['settings']) : '{}';
                                        oldReplacement['meaning'] = oldReplacement['meaning'] || ' ';
                                    } else {
                                        oldReplacement = {};
                                    }
                                    // console.log('oldReplacement', oldReplacement);
                                }

                                if (oldReplacement['id']) {
                                    const differentReplacement = Object.entries(oldReplacement)
                                        .filter(([key,]) => ['meaning', 'description', 'settings', 'global_visible', 'tag'].includes(key))
                                        .filter(([key,]) => {
                                            //    console.log(key, prepareForCompare(tmpData[`replacement_${key}`]), prepareForCompare(oldReplacement[key]))
                                            return prepareForCompare(tmpData[`replacement_${key}`]) !== prepareForCompare(oldReplacement[key])
                                        });


                                    // replacements is different
                                    // we can't update it, so just remove and create  new
                                    // remove old one
                                    if (differentReplacement.length > 0) {
                                        try {
                                            await tlService.deleteTermReplacementInCategory(token, category_id, termId, oldReplacement['id'],oldReplacement['meaning'] || ' ');
                                            mustAddRepl = true;
                                        } catch(e) {
                                            setUploadError(s =>  s + ` Error removing Term Replacement : ${termId} ${oldReplacement['words']},  ${oldReplacement['lemma']} ` + e.toString())
                                            break;
                                        }
                                    }
                                } else {
                                    mustAddRepl = true;
                                }

                                // now  set a new replacement
                                if (mustAddRepl) {
                                    try {
                                        let newTermReplacement = await tlService.addTermReplacementInCategory(
                                            token,
                                            category_id,
                                            termId,{
                                                words: tmpData['replacement_words'],
                                                lemma: tmpData['replacement_lemma'],
                                                tag: tmpData['replacement_tag'] || "[]",
                                                description: tmpData['replacement_description'] || undefined,
                                                meaning: tmpData['replacement_meaning'] || ' ',
                                                settings: (!tmpData['replacement_settings'] || tmpData['replacement_settings'] === '{}') ? undefined : tmpData['replacement_settings'],
                                                global_visible: tmpData['replacement_global_visible'],
                                            });

                                        if (!newTermReplacement?.data?.id) {
                                             setUploadError(s =>  s + ` Error Upload replacement: ${tmpData['replacement_words']},  ${tmpData['replacement_lemma']} ` + JSON.stringify(newTermReplacement))
                                            break;
                                        }

                                    } catch (e) {
                                        setUploadError(s =>  s + ` <br /> Warning replacement: ${tmpData['replacement_words']},  ${tmpData['replacement_lemma']} ` + e.toString())
                                    }
                                }

                            }

                        }

                        // at the end  calculate size
                        await tlService.calculateCategory(token, parseInt(category_id));

                        // clear cache
                        await tlService.clearAllCache(token, 1, `${parseInt(category_id)}`);
                        refreshFunc();


                    } else {
                        setUploadError(translate('Data is empty'))
                    }
                } else {
                    setUploadError(translate('Wrong data'))
                }
            } else {
                setUploadError(translate('Wrong file format'))
            }
        } else {
            setUploadError(translate('Choose file for upload'))
        }
        setUploadBarVisible(false);
        setUploadFile(false);
    }


    return (
        <Row>
            <Col md={1} className="mr-2"><Link to="/terms"><i className="fas fa-arrow-left"/></Link></Col>
            <Col md={3}>
                <h1 className="h3 mb-0 text-gray-800">{pageHeader}</h1>
            </Col>
            <Col>({category_id} {name[lang]} )</Col>
            <Col className="col-6">
                <Row>
                    <Col className="pr-1 ">
                        <Button className="btn btn-xs btn-info" onClick={calculateSize}>
                            {t('Recalculate size')}
                        </Button>
                    </Col>
                    <Col className="pr-1">
                        <Button className="btn btn-xs btn-primary " onClick={clearCache}>
                            {t('Clear Cache')}
                        </Button>
                    </Col>
                    <Col className="pr-1">
                        <Button className="btn btn-xs btn-secondary " onClick={downloadTerms}>
                            {t('Download Category')}
                        </Button>
                        {downloadBarVisible &&
                            <div className="pt-1 mt-1">
                                <ProgressBar now={downloadBarNow} variant="warning striped"/>
                            </div>}
                    </Col>
                    <Col className="pr-1">
                        <Button className="btn btn-xs btn-danger" onClick={() => confirmAlert(options)}>
                            {t('Upload Category')}
                        </Button>
                        {uploadBarVisible &&
                            <div className="pt-1 mt-1">
                                <ProgressBar now={uploadBarNow} variant="warning striped"/> ({uploadBarText})
                            </div>}
                    </Col>
                    <Col className="pr-1">
                        <Form.Control
                            size="sm"
                            type="file"
                            name="uploadFile"
                            onChange={prepareUploadFile}
                            placeholder="Upload file"/>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-right">
                        {uploadError && <Alert variant="danger" onClose={() => setUploadError(false)} dismissible>
                            <div className="font-smaller mx-auto text-center">{uploadError}</div>
                        </Alert>}
                    </Col>
                </Row>

            </Col>
        </Row>
    );
}

export default DataViewerTermCategoryHeader;