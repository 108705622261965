import React from 'react';
import {compose, checkUserRight, getAllModalFunctions} from "../../../utils";
import {withTranslation} from "react-i18next";
import {withTLService} from "../../hoc";
import {connect} from "react-redux";

import {bindActionCreators} from "redux";
import {
    adminGetAllCompanies, adminGetAllGroups, adminGetAllRights, adminGetAllOptions, modalAdd, modalDelete, getUserTypes

} from "../../../actions";

import ModalAddEditUser from "../../modals/modal-add-edit-user";
import ModalViewUser from "../../modals/modal-view-user";
import ModalSendMessageUser from "../../modals/modal-send-message-user";

import {Button} from "react-bootstrap";
import DataViewer from "../../elements/data-viewer";


class Users extends DataViewer {

    /*  define count of modal*/
    modalWindowsCount = 2;

    tableFilters = {
        deleted: {
            name: 'Show deleted elements',
            type: 'integer',
            default: 0
        },
        user_name: {
            name: 'Name',
            type: 'string',
            default: undefined
        },
        email: {
            name: 'E-mail',
            type: 'string',
            default: undefined
        }
    }

    functions = {
        getAll: this.props.tlService.getFilteredUsers,
        massDelete: data => this.actionMassDelete(data)
    };

    modalComponents = {
        modalAdd: ModalAddEditUser,
        modalEdit: ModalAddEditUser,
        modalView: ModalViewUser,
        modalSendMessage: ModalSendMessageUser
    };


    componentDidMount() {
        super.componentDidMount();
        const {
            userData: {token, user},
            t: translate, i18n
        } = this.props;

        this.columns_default = [
            {
                name: 'id',
                selector: 'id',
                sortable: true
            },
            {
                name: 'name',
                selector: 'user_name',
                sortable: true
            },
            {
                name: 'email',
                selector: 'email',
                sortable: true
            },
            {
                name: 'first_name',
                selector: 'first_name',
                sortable: true

            },
            {
                name: 'last_name',
                selector: 'last_name',
                sortable: true

            },
            {
                name: 'user_rights',
                selector: 'user_rights',
                cell: row => JSON.stringify(row.user_rights)

            },
            {
                name: 'user_options',
                selector: 'user_options',
                cell: row => JSON.stringify(row.user_options)
            },
            {
                name: 'edit',
                selector: 'id',
                cell: row => {
                    return (
                        <div className="btn-group">
                            {(checkUserRight(user, [403])) &&
                            <Button onClick={() => this.openModal(0, row, 'modalSendMessage')} className="ml-2"><i
                                className="fas fa-envelope"/></Button>
                            }
                            {(row.id > 1 && (checkUserRight(user, [203]) || (checkUserRight(user, [103]) && row.user_group_id === user.user_group_id))
                            ) && <>
                                <Button onClick={() => this.openModal(0, row, 'modalEdit')} className="ml-2"><i
                                    className="fas fa-edit"/></Button>
                                <Button onClick={() => this.openModal(0, row, 'modalDelete')} className="ml-2"><i
                                    className="fas fa-trash"/></Button>
                            </>

                            }
                        </div>
                    )
                }
            }
        ];

        // check for enable page
        this.renderIt = checkUserRight(user, [101, 102, 103, 201, 202, 203]);

        // check add right
        this.addButton = checkUserRight(user, [102, 202]);

        // get groups and companies


        this.props.adminGetAllCompanies(token);
        this.props.adminGetAllGroups(token);
        this.props.adminGetAllRights(token);
        this.props.adminGetAllOptions(token);
        this.props.openGetUserTypes();

        this.modalSettings['modalAdd'] = {
            action: this.actionAdd,
            data: {
                user_name: '',
                email: '',
                password: '',
                first_name: '',
                last_name: '',
                zip_code: '',
                city: '',
                street: '',
                house_number: '',
                enabled: 1,
                country_id: 'DE',
                user_group_id: '0',
                user_type_id: '3',
                license_id: '0',
                language_id: i18n.language,
                user_rights: [],
                user_options: {},
                t_a_c: 0,
                c_p: 0
            },
            show: false,
            header: translate('Add User'),
            footer: true,
            text: '',
            saveButton: true,
            saveButtonText: translate('Save'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: false
        };
        this.modalSettings['modalEdit'] = {
            action: this.actionEdit,
            data: {
                id: false,
                user_name: '',
                email: '',
                password: '',
                first_name: '',
                last_name: '',
                zip_code: '',
                city: '',
                street: '',
                house_number: '',
                enabled: 1,
                country_id: 'DE',
                user_group_id: '1',
                user_type_id: '3',
                license_id: '0',
                language_id: i18n.language,
                user_rights: [],
                user_options: {},
                t_a_c: 0,
                c_p: 0

            },
            ok: false,
            show: false,
            header: translate('Edit User'),
            footer: true,
            text: '',
            saveButton: true,
            saveButtonText: translate('Save'),
            closeButton: true,
            closeButtonText: translate('Close'),
        };
        this.modalSettings['modalView'] = {
            action: false,
            data: {
                id: false,
                user_name: '',
                email: '',
                password: '',
                first_name: '',
                last_name: '',
                zip_code: '',
                city: '',
                street: '',
                house_number: '',
                enabled: 1,
                country_id: 'DE',
                user_group_id: '0',
                user_type_id: '3',
                license_id: '0',
                language_id: '',
                user_rights: [],
                user_options: {}
            },
            show: false,
            header: translate('View User'),
            footer: false,
            text: '',
            saveButton: false,
            saveButtonText: translate('Save'),
            closeButton: false,
            closeButtonText: translate('Close'),
            ok: true
        };
        this.modalSettings['modalSendMessage'] = {
            action: this.actionMessage,
            data: {
                id: false,
                level: "alert",
                from_inf: "admin",
                template: "text_message",
                text: "",
            },
            show: false,
            header: translate('Send message to user'),
            footer: true,
            text: '',
            saveButton: true,
            saveButtonText: translate('Send'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        };
        this.modalSettings['modalDelete'] = {
            action: this.actionDelete,
            data: {
                id: false,
            },
            show: true,
            header: translate('Delete User'),
            footer: true,
            text: "Delete user?",
            saveButton: true,
            saveButtonText: translate('Delete'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        };
        this.modalSettings['modalMassDelete'] = {
            action: this.actionDoMassDelete,
            data: {},
            show: false,
            header: translate('Delete Selected Users'),
            footer: true,
            text: translate('Delete Selected Users'),
            saveButton: true,
            saveButtonText: translate('Delete'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        }

        this.setState({
            filters: {},
            limits: {
                limit: 10,
                offset: 0,
                order_by: 'id',
                order: 0
            },
            page: {
                pageHeader: translate('Users'),
                addNewText: translate('Add new user'),
            }
        });

    }

    actionMessage = async (data) => {
        const {
            tlService,
            userData: {token}
        } = this.props;
        const {id, level, from_inf, template, text} = data;

        console.log(data);

        try {
            await tlService.addMessage(token, id, level, from_inf, template, text);
        } catch (e) {
            console.log('error ' + e);
            this.setState({error: e.message});
            return false;
        }
        return true;
    }

    actionAdd = async (data) => {
        const {
            tlService,
            userData: {token}
        } = this.props;

        //t_a_c: false,
        //    c_p: false
        let id = false;
        try {
            const ret = await tlService.addUser(token,
                parseInt(data.user_group_id), data.country_id, parseInt(data.user_type_id),
                data.language_id, data.user_name, data.email, data.password,
                data.first_name, data.last_name, data.street, data.house_number,
                data.zip_code, data.city, !!data.enabled ? 1 : 0, parseInt(data.license_id));
            id = ret.id
        } catch (e) {
            console.log('error ' + e);
            this.setState({error: e.message});
            return false;
        }

        if (data.c_p) {
            try {
                await tlService.addMessage(token, id, 'alert', 'admin', 'must_change_password', '');
            } catch (e) {
                console.log('error ' + e);
                this.setState({error: e.message});
                return false;
            }
        }

        if (data.t_a_c) {
            try {
                await tlService.addMessage(token, id, 'alert', 'admin', 'terms_and_conditions', '');
            } catch (e) {
                console.log('error ' + e);
                this.setState({error: e.message});
                return false;
            }
        }


        // user_rights
        if (data.user_rights.length > 0) {
            data.user_rights.map(async (value) => {
                if (parseInt(value.right_id) > 0) {
                    try {
                        await tlService.addUserRight(token, id, parseInt(value.right_id), parseInt(value.user_group_id));
                    } catch (e) {
                        console.log('error ' + e);
                        this.setState({error: e.message});
                        return false;
                    }
                }
            })
        }
        // options
        for (let tmpOption of Object.keys(data.user_options)) {
            if (data.user_options[tmpOption] && data.user_options[tmpOption].trim().length > 0) {
                try {
                    await tlService.addUserOption(token, id, tmpOption, data.user_options[tmpOption].trim());
                } catch (e) {
                    console.log('error ' + e);
                    this.setState({error: e.message});
                    return false;
                }
            }
        }
        return true;

    }

    actionEdit = async (data) => {
        const {
            tlService,
            userData: {token},
        } = this.props;

        console.log('actionEdit', data)


        try {
            await tlService.updateUser(token, parseInt(data.id),
                {
                    user_group_id: parseInt(data.user_group_id),
                    country_id: data.country_id,
                    user_type_id: parseInt(data.user_type_id),
                    language_id: data.language_id,
                    user_name: data.user_name,
                    email: data.email,
                    password: data.password || undefined,
                    first_name: data.first_name,
                    last_name: data.last_name,
                    street: data.street,
                    house_number: data.house_number,
                    zip_code: data.zip_code,
                    city: data.city,
                    enabled: !!data.enabled ? 1 : 0,
                    license_id: parseInt(data.license_id)
                });
        } catch (e) {
            console.log('error ' + e);
            this.setState({error: e.message});
            return false;
        }

        // user_rights
        await tlService.deleteUserRight(token, data.id);

        if (data.user_rights.length > 0) {
            data.user_rights.map(async (value) => {
                if (parseInt(value.right_id) > 0) {
                    try {
                        console.log('addUserRight ', parseInt(value.right_id), parseInt(value.user_group_id));
                        await tlService.addUserRight(token, data.id, parseInt(value.right_id), parseInt(value.user_group_id));
                    } catch (e) {
                        console.log('error ' + e);
                        this.setState({error: e.message});
                        return false;
                    }
                }

            })
        }
        // options
        await tlService.deleteUserOption(token, data.id);
        for (let tmpOption of Object.keys(data.user_options)) {
            if (data.user_options[tmpOption] && data.user_options[tmpOption].trim().length > 0) {
                try {
                    await tlService.addUserOption(token, data.id, tmpOption, data.user_options[tmpOption].trim());
                } catch (e) {
                    console.log('error ' + e);
                    this.setState({error: e.message});
                    return false;
                }
            }
        }


        return true;

    }

    actionDelete = async (data) => {
        const {
            tlService,
            userData: {token},
        } = this.props;

        // delete company
        try {
            if (parseInt(data.id) > 1 )
                await tlService.deleteUser(token, parseInt(data.id));
        } catch (e) {
            console.log('error ' + e);
            this.setState({error: e.message});
            return false;
        }
        return true;
    }

}


const mapStateToProps = ({userData, modalData, openData, adminData}) => {
    return {userData, openData, modalData, adminData};
};

const mapDispatchToProps = (dispatch, {tlService}) => {
    return bindActionCreators({
        ...getAllModalFunctions(),
        adminGetAllCompanies: adminGetAllCompanies(tlService),
        adminGetAllGroups: adminGetAllGroups(tlService),
        adminGetAllRights: adminGetAllRights(tlService),
        adminGetAllOptions: adminGetAllOptions(tlService),
        openGetUserTypes:getUserTypes
    }, dispatch);
};

export default compose(
    withTranslation(),
    withTLService(),
    connect(mapStateToProps, mapDispatchToProps)
)(Users);

