import React, {Component} from "react";
import {withTranslation} from 'react-i18next';
import {Col, Container, Form, Row} from "react-bootstrap";
import {compose, getAllModalFunctions, getDataFromModals} from "../../../utils";
import {withTLService} from "../../hoc";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";


class ModalViewCompany extends Component {

    state = {
        tmpOptions: [],
        tmpLicenses: []
    }




    getOption() {
        const {index} = this.props;
        const data = getDataFromModals(this.props, index);
        const {company_options} = data;
        const opt = Object.keys(company_options).map((option) => {
            return {option, value: company_options[option]}
        });
        this.setState({tmpOptions: opt || []});
    }

    getLicenses() {
        const {index} = this.props;
        const data = getDataFromModals(this.props, index);
        const {company_licenses} = data;
        this.setState({tmpLicenses: company_licenses || []});
    }

    componentDidMount() {
        this.getOption();
        this.getLicenses();
    }

    render() {
        const {index} = this.props;
        const data = getDataFromModals(this.props, index);
        const {name, company_licenses, company_options} = data;

        const {
            t: translate,
            openData: {licenses}
        } = this.props;
        const {tmpOptions, tmpLicenses} = this.state;

        console.log('render modal');

        return (
            <Container>
                <Row>
                    <Col md={6}>
                        <Form.Group controlId="name">
                            <Form.Control

                                type="text"
                                name="name"
                                defaultValue={name}
                                disabled={true}
                                placeholder="Enter name"/>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="company_licenses">
                            <Form.Control
                                as="select"
                                multiple
                                value = {tmpLicenses}
                                name="company_licenses"
                                disabled={true}
                            >
                                {
                                    Object.keys(licenses).length > 0 && Object.keys(licenses).map((license) =>
                                        <option key={license}
                                                value={license}>{translate(licenses[license].name)}</option>
                                    )
                                }
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>

                <h1 className="h5 mb-0 text-gray-800 my-3">Options</h1>

                <Row>
                    <Col>
                        {
                            tmpOptions.map((opt, index) => {
                                    const {option, value} = opt;
                                    return (
                                        <Row key={index}>
                                            <Col md={4}>
                                                <Form.Group controlId={"option_" + index}>
                                                    <Form.Control
                                                        type="text"
                                                        name={"option_" + index}
                                                        value={option}
                                                        disabled={true}
                                                        placeholder="Enter option name"/>
                                                </Form.Group>
                                            </Col>
                                            <Col md={7}>
                                                <Form.Group controlId={"value_" + index}>
                                                    <Form.Control
                                                        type="text"
                                                        name={"value_" + index}
                                                        value={value}
                                                        disabled={true}
                                                        placeholder="Enter description"/>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    )
                                }
                            )
                        }

                    </Col>
                </Row>
            </Container>
        )
    }
}

const mapStateToProps = ({userData, modalData, openData, adminData}) => {
    return {userData, openData, modalData, adminData};
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        ...getAllModalFunctions()
    }, dispatch);
};

export default compose(
    withTranslation(),
    withTLService(),
    connect(mapStateToProps, mapDispatchToProps)
)(ModalViewCompany);