import React, {Component} from 'react';
import {
    compose,
    convertLanguagesToFull,
    convertPlainToHTML,
    removeTagMark,
    setLanguage
} from "../../../utils";
import {withTranslation} from "react-i18next";
import {withTLService} from "../../hoc";
import {connect} from "react-redux";
import TinyEditor from "../../elements/tiny-editor";
import Dnd from "../../elements/dnd/dnd";
import mammoth from "mammoth";
import {Col, Form, Container, Row, Button, Alert} from "react-bootstrap";
import backendLanguages from "../../../utils/backendlanguages.json";
import enTags from "./english-tags.json";
import deTags from "./german-tags.json";


import HixChart from "../../elements/hix-chart"
import {Link} from "react-router-dom";


/*
Wenn Sie unser Angebot annehmen, lsenden  Sie  uns Ihre Zusage bitte schriftlich und nennen Sie uns dabei Ihre Kontoverbindung.
Bitte senden Sie uns keine außergerichtlichen Briefe mehr zu dieser Angelegenheit, wenn Sie unser Angebot nicht annehmen möchten.
37
*/


class Dashboard extends Component {
    state = {
        regexp: '',
        text: '',
        result: '',
        submitted: false
    };
    handleChange = (event) => {
        const {name, value} = event.target;
        this.setState({[name]: value});
        this.setState({submitted: false});
    };

    handleSubmit = async (event) => {
        const {userData: {token}, tlService, t: translate} = this.props;
        event.preventDefault();
        event.stopPropagation();
        this.setState({submitted: true});
        const {regexp, text} = this.state;
        if (regexp && text) {
            let result;
            try {
                result = await tlService.testRegex(token, regexp, text);
                let res = '';
                if (result?.data?.length) {
                    for (let reg of result.data) {
                        //res += `[${reg[0]} ${reg[1]}] <br> `
                        res += `pos:[${reg[0]-reg[1] + 1},${reg[0] + 1}]: ${text.substring(reg[0]-reg[1] + 1,reg[0] + 1)} <br/>`
                    }
                }
                result = res
            } catch (e) {
                result = e.toString()
            }
            this.setState({result});
        }
    };

    render() {
        return (
            <Container className="h-100 d-inline-block" fluid>
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 text-gray-800">Dashboard</h1>
                </div>
                <Row>
                    <Col><h4>Regexp Test</h4></Col>
                </Row>
                <Form
                    name="regexpForm"
                    onSubmit={this.handleSubmit}>
                    <Row>
                        <Col className={'col-md-1 p-1'}>regexp</Col>
                        <Col className={'col-md-2 p-1'}><Form.Control
                            value={this.state.regexp}
                            onChange={this.handleChange}
                            name="regexp"
                            type="text"
                            placeholder={'regexp'}/></Col>
                    </Row>
                    <Row>
                        <Col className={'col-md-1 p-1'}>text</Col>
                        <Col className={'col-md-2 p-1'}><Form.Control
                            value={this.state.text}
                            onChange={this.handleChange}
                            name="text"
                            type="text"
                            placeholder={'text'}/></Col>
                    </Row>
                    <Row>
                        <Col className={'col-md-2 p-1'}>
                            <Button
                                type="submit"
                                className="btn btn-primary btn-user btn-block">
                                {'Check'}
                            </Button>
                        </Col>
                    </Row>
                </Form>

                {this.state.result &&
                    <Row>
                        <Col className={'p-1'}>
                            <Alert variant={'primary'}><div dangerouslySetInnerHTML={{__html: this.state.result}}/></Alert>
                        </Col>
                    </Row>
                }
            </Container>
        )
    }
}

const mapStateToProps = ({userData, modalData, openData, adminData}) => {
    return {userData, openData, modalData, adminData};
};

export default compose(
    withTranslation(),
    withTLService(),
    connect(mapStateToProps)
)(Dashboard);
