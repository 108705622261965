import React from 'react';
import {checkUserRight, compose, getAllModalFunctions} from "../../../utils";
import {withTranslation} from "react-i18next";
import {withTLService} from "../../hoc";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {
    adminGetAllCompanies, adminGetAllOptions
} from "../../../actions";

import ModalAddEditGroup from "../../modals/modal-add-edit-group";
import ModalViewGroup from "../../modals/modal-view-group";

import {Button} from "react-bootstrap";
import DataViewer from "../../elements/data-viewer";


class Groups extends DataViewer {


    /*  define count of modal*/
    modalWindowsCount = 2;

    tableFilters = {
        deleted: {
            name: 'Show deleted elements',
            type: 'integer',
            default: 0
        },
        name: {
            name: 'Name',
            type: 'string',
            default: undefined
        },
        company_name: {
            name: 'Company name',
            type: 'string',
            default: undefined
        }
    }

    functions = {
        getAll: this.props.tlService.getFilteredGroups,
        massDelete: data => this.actionMassDelete(data)
    };

    modalComponents = {
        modalAdd: ModalAddEditGroup,
        modalEdit: ModalAddEditGroup,
        modalView: ModalViewGroup,
    };

    componentDidMount() {
        const {
            userData: {token, user},
            t: translate
        } = this.props;

        this.columns_default = [
            {
                name: 'id',
                selector: 'id',
                sortable: true
            },
            {
                name: 'name',
                selector: 'name',
                sortable: true
            },
            {
                name: 'company_name',
                selector: 'company_name',
                sortable: true
            },
            {
                name: 'create_time',
                selector: 'create_time',
                sortable: true

            },
            {
                name: 'delete_time',
                selector: 'delete_time',
                sortable: true

            },
            {
                name: 'user_group_options',
                selector: 'user_group_options',
                cell: row => JSON.stringify(row.user_group_options)

            },
            {
                name: 'edit',
                selector: 'id',
                cell: row => {
                    return (
                        <div className="btn-group">
                            <Button onClick={() => this.openModal(0, row, 'modalView')}><i className="fas fa-eye"/></Button>
                            {(checkUserRight(user, [208]) && (user.user_type_id === 1 || row.company_id === user.company_id) &&  row.id > 2) &&
                            (<>
                                <Button onClick={() => this.openModal(0, row, 'modalEdit')} className="ml-2"><i className="fas fa-edit"/></Button>
                                <Button onClick={() => this.openModal(0, row, 'modalDelete')} className="ml-2"><i className="fas fa-trash"/></Button>
                            </>)

                            }
                        </div>
                    )
                }
            }
        ];

        // check for enable page
        this.renderIt = checkUserRight(user, [ 206, 207, 306, 307]);

        // check add right
        this.addButton = checkUserRight(user, [207, 307]);


        this.props.adminGetAllCompanies(token);
        this.props.adminGetAllOptions(token);



        this.modalSettings['modalAdd'] = {
            action: this.actionAdd,
            data: {
                name: '',
                company_id: 1,
                user_group_options: {}
            },
            show: false,
            header: translate('Add Group'),
            footer: true,
            text: '',
            saveButton: true,
            saveButtonText: translate('Save'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        };
        this.modalSettings['modalEdit'] = {
            action: this.actionEdit,
            data: {
                name: '',
                company_id: 1,
                user_group_options: {}
            },
            show: false,
            header: translate('Edit Group'),
            footer: true,
            text: '',
            saveButton: true,
            saveButtonText: translate('Save'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        };
        this.modalSettings['modalView'] = {
            action: false,
            data: {
                name: '',
                company_id: 1,
                user_group_options: {}
            },
            show: false,
            header: translate('View Group'),
            footer: true,
            text: '',
            saveButton: false,
            saveButtonText: translate('Save'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        };
        this.modalSettings['modalDelete'] = {
            action: this.actionDelete,
            data: {
                id: false,
            },
            show: false,
            header: translate('Delete Group'),
            footer: true,
            text: "Delete group?",
            saveButton: true,
            saveButtonText: translate('Delete'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        };
        this.modalSettings['modalMassDelete'] = {
            action: this.actionDoMassDelete,
            data: {},
            show: false,
            header: translate('Delete Selected Groups'),
            footer: true,
            text: translate('Delete Selected Groups'),
            saveButton: true,
            saveButtonText: translate('Delete'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        }

        this.setState({
            filters: {
            },
            limits: {
                limit: 10,
                offset: 0,
                order_by: 'id',
                order: 0
            },
            page: {
                pageHeader: translate('Groups'),
                addNewText: translate('Add new group'),
            }
        });
        super.componentDidMount();
    }

    actionAdd = async (data) => {
        const {
            tlService,
            userData: {token},
        } = this.props;

        let id = false;

        try {
           const ret = await tlService.addGroup(token, parseInt(data.company_id), data.name);
           id = ret.id
        } catch (e) {
            console.log('error ' + e);
            this.setState({error: e.message});
            return false;
        }
        // options
        for (let tmpOption of Object.keys(data.user_group_options)) {
            if (data.user_group_options[tmpOption] && data.user_group_options[tmpOption].trim().length > 0) {
                try {
                    await tlService.addGroupOption(token, id, tmpOption,data.user_group_options[tmpOption].trim());
                } catch (e) {
                    console.log('error ' + e);
                    this.setState({error: e.message});
                    return false;
                }
            }
        }
        return true;
    }

    actionEdit = async (data) => {
        const {
            tlService,
            userData: {token},
        } = this.props;

        try {
            await tlService.updateGroup(token, parseInt(data.id),parseInt(data.company_id), data.name);
        } catch (e) {
            console.log('error ' + e);
            this.setState({error: e.message});
            return false;
        }

        // options
        try {
             await tlService.deleteGroupOption(token, parseInt(data.id));
        } catch (e) {
            console.log('error ' + e);
            this.setState({error: e.message});
            return false;
        }

        for (let tmpOption of Object.keys(data.user_group_options)) {
            if (data.user_group_options[tmpOption] && data.user_group_options[tmpOption].trim().length > 0) {
                try {
                    await tlService.addGroupOption(token, parseInt(data.id), tmpOption,data.user_group_options[tmpOption].trim());
                } catch (e) {
                    console.log('error ' + e);
                    this.setState({error: e.message});
                    return false;
                }
            }
        }
        return true;
    }

    actionDelete = async (data) => {
        const {
            tlService,
            userData: {token},
        } = this.props;

        // delete group
        if (data.id > 2) {
            try {
                await tlService.deleteGroup(token, parseInt(data.id));
            } catch (e) {
                console.log('error ' + e);
                this.setState({error: e.message});
                return false;
            }
        }
        return true;
    }

}

const mapStateToProps = ({userData, modalData, openData, adminData}) => {
    return {userData, openData, modalData, adminData};
};

const mapDispatchToProps = (dispatch, {tlService}) => {
    return bindActionCreators({
        ...getAllModalFunctions(),
        adminGetAllCompanies: adminGetAllCompanies(tlService),
        adminGetAllOptions: adminGetAllOptions(tlService)

    }, dispatch);
};

export default compose(
    withTranslation(),
    withTLService(),
    connect(mapStateToProps, mapDispatchToProps)
)(Groups);

