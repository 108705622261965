import React from "react";
import {withTranslation} from 'react-i18next';
import {Col, Container, Form, Row} from "react-bootstrap";
import {compose, getAllModalFunctions, getDataFromModals} from "../../../utils";
import {withTLService} from "../../hoc";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import SettingTable from "../../modal-elements/setting-table";
import BaseModal from "../base-modal";
import LicenseTable from "../../modal-elements/license-table";
import {adminGetAllGroups} from "../../../actions";


class ModalAddEditCompany extends BaseModal {

    render() {
        const {index} = this.props;
        const data = getDataFromModals(this.props, index);
        const {name} = data;

        const {
            openData: {licenses},
            adminData: {options}
        } = this.props;

        console.log('render modal ModalAddEditCompany', this.props);

        return (
            <Container>
                <Row>
                    <Col md={6}>
                        <Form.Group controlId="name">
                            <Form.Control
                                type="text"
                                name="name"
                                defaultValue={name}
                                onChange={e => {
                                    this.handleChange(e)
                                }}
                                placeholder="Enter name"/>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <LicenseTable
                            multiple={true}
                            {...this.props}
                            licenses={licenses}
                            licenseFieldName={"company_licenses"}
                        />
                    </Col>
                </Row>

                <h1 className="h5 mb-0 text-gray-800 my-3">Options</h1>
                <SettingTable
                    addText='Add option'
                    settingText='Options'
                    valueText='Value'
                    valuePlaceholder='Add Value'
                    selectText='Select option name'
                    settings={Object.keys(options)}
                    settingFieldName={"company_options"}
                    {...this.props}
                />
            </Container>
        )
    }
}

const
    mapStateToProps = ({userData, modalData, openData, adminData}) => {
        return {userData, openData, modalData, adminData};
    };

const
    mapDispatchToProps = (dispatch, {tlService}) => {
        return bindActionCreators({
            ...getAllModalFunctions(),
            adminGetAllGroups: adminGetAllGroups(tlService)
        }, dispatch);
    };

export default compose(
    withTranslation(),
    withTLService(),
    connect(mapStateToProps, mapDispatchToProps)
)(ModalAddEditCompany);
