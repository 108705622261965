import React  from "react";
import {Accordion, Card, Col, Row} from "react-bootstrap";
import ReactBootstrapSlider from "react-bootstrap-slider";
import {roundWithPrecision} from "../../utils";


const DefaultSection = (props) => {
    const {
        resultData,
        t: translate,
        keySetting,
        valSetting,
        accOnClose,
        openedSetting
    } = props;
    return (
        <Card key={keySetting}>
            <Accordion.Toggle as={Card.Header} eventKey={keySetting}>
                {translate(keySetting)}
            </Accordion.Toggle>
            <Accordion.Collapse onExit={accOnClose}
                                eventKey={keySetting}>
                <Card.Body>
                    {valSetting.map((elementSetting, index) => {
                        if (elementSetting['values']['enabled'] && resultData[elementSetting.name]) {
                            const name = elementSetting['values']['threshold'] !== undefined ? translate(elementSetting.name).replace(/X/, elementSetting['values']['threshold']) : elementSetting.name
                            const result = typeof resultData[elementSetting.name] === 'object' ? resultData[elementSetting.name].length : resultData[elementSetting.name];
                            const percentFromClauses = roundWithPrecision (resultData["countClauses"] > 0 ? result / (resultData["countClauses"] / 100) : 0 , 2);
                            let printResult = '0';
                            if (result) {
                                switch (elementSetting['name']) {
                                    case "countPassiveVoiceInSentence":
                                        printResult = ` ${result}/${resultData["countClauses"]}  (${percentFromClauses}%)`;
                                        break;
                                    case "countInfinitiveConstructions":
                                        printResult = ` ${result}/${resultData["countClauses"]}  (${percentFromClauses}%)`;
                                        break;
                                    case "countFutureTenseInSentence":
                                        printResult = ` ${result}/${resultData["countClauses"]}  (${percentFromClauses}%)`;
                                        break;
                                    case "countNominalStyle":
                                        printResult = ` ${result}/${resultData["countClauses"]}  (${percentFromClauses}%)`;
                                        break;
                                    default:
                                        printResult = result;
                                }
                            }
                            return (
                                <Card key={index}>
                                    <Accordion>
                                        <Accordion.Toggle
                                            onClick={() => openedSetting(elementSetting.name)}
                                            as={Card.Header} eventKey="0">
                                            <Row>
                                                <Col
                                                    md={8}>{name}</Col>
                                                <Col
                                                    className="font-weight-bold">{printResult}</Col>
                                            </Row>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0">
                                            <Card.Body>
                                                {(elementSetting['values']['max'] !== undefined
                                                    && elementSetting['values']['min'] !== undefined
                                                    && elementSetting['values']['target'] !== undefined
                                                ) && <Row>
                                                    <Col className="my-auto p-1">
                                                        <ReactBootstrapSlider
                                                            value={[result, elementSetting['values']['target']]}
                                                            step={1}
                                                            max={elementSetting['values']['max']}
                                                            min={elementSetting['values']['min']}
                                                            handle={"triangle"}
                                                            orientation="horizontal"
                                                            reversed={false}
                                                            disabled={"disabled"}
                                                        />
                                                    </Col>
                                                </Row>
                                                }
                                                <Row>
                                                    <Col
                                                        className="my-auto font-smaller p-1">
                                                        {translate('Result')}: {result} |
                                                        {translate('Target')}: {elementSetting['values']['target']}
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Accordion>
                                </Card>
                            );

                        }
                    })}
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    );
}

export default DefaultSection;