import DataViewer from "../../elements/data-viewer";
import ModalAddEditTerm from "../../modals/modal-add-edit-term";
import {
    checkUserRight,
    compose,
    getAllModalFunctions,
    getModalFromModals,
    removeEmptyFromObject
} from "../../../utils";
import {Button} from "react-bootstrap";
import {bindActionCreators} from "redux";
import {userGetAllTermCategories} from "../../../actions";
import {withTranslation} from "react-i18next";
import {withTLService} from "../../hoc";
import {connect} from "react-redux";
import React from "react";

import ModalUploadTerms from "../../modals/modal-upload-terms/modal-upload-terms";
import ModalTableReplacement from "../../modals/modal-table-replacement";


import DataViewerTermCategoryHeader from "./data-viewer-term-category-header";
import {Redirect} from "react-router-dom";


class TermsByCategory extends DataViewer {

    /*  define count of modal*/
    modalWindowsCount = 2

    /* table filters */
    tableFilters = {
        deleted: {
            name: 'Show deleted elements',
            type: 'integer',
            default: 0
        },
        words: {
            name: 'Filter by words',
            type: 'string',
            default: undefined
        },
        lemma: {
            name: 'Filter by lemmas',
            type: 'string',
            default: undefined
        },
        id: {
            name: 'Term id',
            type: 'integer',
            default: undefined
        },
        description: {
            name: 'Term description',
            type: 'string',
            default: undefined
        }
    }

    /* functions */
    functions = {
        getAll: this.props.tlService.getFilteredTermByCategory,
        massDelete: data => this.actionMassDelete(data),
        deleteAll: data => this.actionDeleteAll(data)
    };

    /* modal components */
    modalComponents = {
        modalAdd: ModalAddEditTerm,
        modalEdit: ModalAddEditTerm,
        modalView: ModalAddEditTerm,
        modalReplacement: ModalTableReplacement,
        modalUploadTerms: ModalUploadTerms
    };

    table_name = false;
    extended_fields = []

    async componentDidMount() {

        const {
            userData: {token, user},
            t: translate
        } = this.props;

        /* get category */

        this.setState({filters: this.props.match.params})
        const category = await this.getCategory(this.props.match.params.category_id)
        if (category) {
            this.setState({headerData: {category, refreshFunc: () => this.getAllData()}})
            this.setState({Header: DataViewerTermCategoryHeader})
        }


        /* default table columns config */
        this.columns_default = [
            {
                name: 'id',
                selector: 'id',
                sortable: true
            },
            {
                name: 'words',
                selector: 'words',
                cell: row => JSON.stringify(row.words)
            },
            {
                name: 'description',
                selector: 'description'
            },
            {
                name: 'settings',
                selector: 'settings',
                cell: row => JSON.stringify(row.settings)
            }
        ];

        if (category && category['category_type'] !== 'positive') {
            this.columns_default.push({
                name: 'term_replacement',
                selector: 'term_replacement',
                cell: row => {
                    const values = row?.term_replacement || [];
                    return (<>
                        {values.length}
                        <>
                            <Button onClick={() => this.openReplacementModal(row.id)} className="ml-2"><i
                                className="fas fa-bars"/></Button>
                        </>
                    </>)
                }
            });
        }

        this.columns_default.push({
            name: 'edit',
            cell: row => {
                return (
                    <div className="btn-group">
                        {checkUserRight(user, [701, 702]) &&
                            (<>
                                <Button onClick={() => this.openModal(0, row, 'modalEdit')} className="ml-2"><i
                                    className="fas fa-edit"/></Button>
                                <Button onClick={() => this.openModal(0, row, 'modalDelete')} className="ml-2"><i
                                    className="fas fa-trash"/></Button>
                            </>)

                        }
                    </div>
                )
            }
        });

        // check for enable page
        this.renderIt = checkUserRight(user, [701, 702, 703]);

        // check add right
        this.addButton = checkUserRight(user, [701, 702, 703]);

        /*  get preloaded data */
        this.props.userGetAllTermCategories(token);


        /* config modal windows */
        this.modalSettings['modalAdd'] = {
            action: this.actionAdd,
            data: {
                id: false,
                words: [],
                lemma: [],
                tag: [],
                check_words: 0,
                enabled: 1,
                description: "",
                settings: {},
                global_visible: 1
            },
            additionalData: {
                category: category,
                locale_name: category.locale_name
            },
            show: false,
            header: translate('Add Term'),
            footer: true,
            text: '',
            saveButton: true,
            saveButtonText: translate('Save'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        };
        this.modalSettings['modalEdit'] = {
            action: this.actionEdit,
            data: {
                id: false,
                words: [],
                lemma: [],
                tag: [],
                check_words: 0,
                enabled: 1,
                description: "",
                settings: {},
                global_visible: 1
            },
            additionalData: {
                category: category,
            },
            show: false,
            header: translate('Edit Term'),
            footer: true,
            text: '',
            saveButton: true,
            saveButtonText: translate('Save'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        };
        this.modalSettings['modalView'] = {
            action: false,
            data: {
                id: false,
                words: [],
                lemma: [],
                tag: [],
                check_words: 0,
                enabled: 1,
                description: "",
                settings: {},
                global_visible: 1
            },
            additionalData: {
                category: category,
            },
            show: false,
            header: translate('View Term'),
            footer: false,
            text: '',
            saveButton: false,
            saveButtonText: translate('Save'),
            closeButton: false,
            closeButtonText: translate('Close'),
            ok: true
        };
        this.modalSettings['modalDelete'] = {
            action: this.actionDelete,
            data: {
                id: false,
            },
            additionalData: {
                category: category,
            },
            show: false,
            header: translate('Delete Term'),
            footer: true,
            text: "Delete Term?",
            saveButton: true,
            saveButtonText: translate('Delete'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        }
        this.modalSettings['modalReplacement'] = {
            action: this.actionReplacement,
            data: {
                id: false,
                baseform: '',
                words: [],
                lemma: [],
                tag: [],
                check_words: 0,
                enabled: 1,
                description: "",
                settings: {},
                global_visible: 1
            },
            additionalData: {
                category: category,
            },
            show: true,
            header: translate('Edit Replacement For Term'),
            footer: true,
            text: '',
            saveButton: false,
            saveButtonText: translate('Save'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        }
        this.modalSettings['modalMassDelete'] = {
            action: this.actionDoMassDelete,
            data: {},
            show: false,
            header: translate('Delete Selected Terms'),
            footer: true,
            text: translate('Delete Selected Terms'),
            saveButton: true,
            saveButtonText: translate('Delete'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        }
        this.modalSettings['modalDeleteAll'] = {
            action: this.actionDoDeleteAll,
            data: {},
            additionalData: {
                category: category,
            },
            show: false,
            header: translate('Delete All Terms'),
            footer: true,
            text: translate('Delete All Terms'),
            saveButton: true,
            saveButtonText: translate('Delete'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        }

        this.modalSettings['modalUploadTerms'] = {
            action: this.actionUpload,
            data: {},
            additionalData: {
                category: category,
            },
            show: false,
            header: translate('Upload Terms'),
            footer: true,
            text: '',
            saveButton: true,
            saveButtonText: translate('Delete'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        }
        /* set initial states */
        this.setState({
            limits: {
                limit: 100,
                offset: 0,
                order_by: 'id',
                order: 0
            },
            page: {
                pageHeader: translate('Terms By Category'),
                addNewText: translate('Add new term'),
            }
        });

        if (!category) {
            this.setState({Redirect: <Redirect to="/terms"/>})
        }

        super.componentDidMount();

    }

    async getCategory(id) {

        const {
            tlService,
            userData: {token}
        } = this.props;
        let category;
        try {
            category = await tlService.getFilteredTermCategories(token, {}, {id: id})
        } catch (e) {
            category = false;
        }

        if (category && category.data && category.data[0]) {
            return category.data[0]
        }
        return false;

    }

    closeModal(index) {
        const data = getModalFromModals(this.props, index);
        if (data.actionName && data.actionName === 'modalReplacement') {
            this.getAllData();
        }
        this.setState({error: false});
        this.props.modalOpenClose(index, false);
    }

    actionAdd = async (data) => {
        const {
            userData: {token},
            tlService,
        } = this.props;
        const {headerData: {category}} = this.state;


        let id = false;

        let objSetting;
        try {
            objSetting = typeof data.settings === "object" ? data.settings : JSON.parse(data.settings);
        }catch (e) {
            objSetting = {};
        }
        const newSetting = JSON.stringify(removeEmptyFromObject(objSetting));

        try {
            const ret = await tlService.addTermInCategory(token, data.category.id,
                {
                    words: JSON.stringify(data.words),
                    lemma: JSON.stringify(data.lemma),
                    tag: JSON.stringify(data.tag),
                    check_words: !!data.check_words ? 1 : 0,
                    settings: newSetting,
                    description: data.description
                });
            id = ret.id
        } catch (e) {
            console.log('error ' + e);
            this.setState({error: e.message});
            return false;
        }
        await tlService.calculateCategory(token, data.category.id);
        tlService.clearAllCache(token, 1, `${data.category.id}`);

        if (category && category['category_type'] === 'negative') {
            this.openModal(this.props.index !== undefined ? this.props.index + 1 : 0, {
                id,
                category: this.modalSettings['modalReplacement'].data.category
            }, 'modalReplacement')
        } else {
            return true;
        }


        return false;
    }

    actionEdit = async (data) => {

        console.log('actionEdit', data)
        const {
            userData: {token},
            tlService,
        } = this.props;

        let objSetting;
        try {
            objSetting = typeof data.settings === "object" ? data.settings : JSON.parse(data.settings);
        }catch (e) {
            objSetting = {};
        }
        const newSetting = JSON.stringify(removeEmptyFromObject(objSetting));
        const newCheckWords = parseInt(data.check_words);

        /*
        category: Object { category_type: "negative", global_visible: true, id: 13, … }
check_words: true
description: ""
enabled: true
global_visible: true
id: 14
lemma: Array [ "asdasds" ]
settings: Object { syllcount: 5 }
tag: Array [ "NP" ]
term_replacement: Array [ {…} ]
0: Object { description: "", global_visible: "true", id: 7564, … }
<prototype>: Array []
wordcount: 1
words: Array [ "individuell" ]
         */


        try {
            // (token, id, category_id, check_words, enabled, description, global_visible, settings)

            const res = await tlService.updateTermInCategory(token, data.category.id, data.id,
                {
                    check_words: !!newCheckWords ? 1 : 0,
                    description: data.description,
                    global_visible: 1,
                    settings: newSetting
                });
            console.log('updateTerm', res);
        } catch (e) {
            console.log('error ' + e);
            this.setState({error: e.message});
            return false;
        }


        try {
            await tlService.calculateCategory(token, data.category.id);
            await tlService.clearAllCache(token, 1, `${data.category.id}`);
        } catch (e) {
            console.log('error ' + e);
        }


        return true;
    }

    actionDelete = async (data) => {
        const {
            tlService,
            userData: {token},
        } = this.props;
        console.log('actionDelete', parseInt(this.props.match.params.category_id), parseInt(data.id))
        try {

            await tlService.deleteTermInCategory(token, parseInt(this.props.match.params.category_id), parseInt(data.id))
        } catch (e) {
            console.log('error ' + e);
            this.setState({error: e.message});
            return false;
        }
        await tlService.calculateCategory(token, parseInt(this.props.match.params.category_id));
        await tlService.clearAllCache(token, 1, `${parseInt(this.props.match.params.category_id)}`);
        return true;
    }

    actionDoDeleteAll = async (data) => {
        const {tlService, userData: {token}} = this.props;
        console.log('actionDoDeleteAll', data);
        if (data['category'] && data['category']['id']) {
            console.log(await tlService.deleteTermCategory(token, data['category']['id'], 1));
        }
        this.getAllData();
        return true;
    }

    actionReplacement = async (data) => {
        console.log('actionReplacement', data);
    }

    actionDeleteAll(data) {
        this.openModal(this.props.index !== undefined ? this.props.index + 1 : 0, data, 'modalDeleteAll')
    }

    openReplacementModal(id) {
        const {rows} = this.state;
        this.openModal(this.props.index !== undefined ? this.props.index + 1 : 0, {
            ...rows.find(element => element.id === id),
            category: this.modalSettings['modalReplacement'].data.category
        }, 'modalReplacement')
    }

}

const
    mapStateToProps = ({userData, modalData, openData, adminData}) => {
        return {userData, openData, modalData, adminData};
    };

const
    mapDispatchToProps = (dispatch, {tlService}) => {
        return bindActionCreators({
            ...getAllModalFunctions(),
            userGetAllTermCategories: userGetAllTermCategories(tlService)
        }, dispatch);
    };

export default compose(
    withTranslation

    (),

    withTLService()

    ,

    connect(mapStateToProps, mapDispatchToProps)
)(
    TermsByCategory
)
;