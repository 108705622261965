import {Button, Col, Form, Row, Tab} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {deepCopy, getDataFromModals, isObjectEmpty} from "../../../utils";


export default function SectionTabTerms(props) {
    const {index} = props;
    const data = getDataFromModals(props, index);
    const {settings} = data;

    const {
        t: translate,
        tSectionKey, tSectionVal, setState, defaultSectionSet,
        userData: {termCategories},
        state: {tSectionAdd}
    } = props;

    const [invalidValues, setInvalidValues] = useState({});
    const [values, setValues] = useState({});


    useEffect(() => {

        Object.entries(settings['listTerms']['values']['_list']).map(([tElementKey, tElementVal]) => {
                tSectionVal['all_values'].map(valueKey => {
                    if (tSectionVal['listTerms'][valueKey] !== undefined) {
                        setValues(s => {
                            return {...s, [`${tSectionKey}|${tElementKey}|${valueKey}`]: tElementVal[valueKey]}
                        })
                    }
                })

            if (!isObjectEmpty(termCategories) && termCategories[tElementKey] === undefined) {
                delete settings['listTerms']['values']['_list'][tElementKey]
            }
         });

        props.modalUpdateData(index, {
            settings
        });

    }, [termCategories])


    const addSetting = () => {
        const {tSectionAdd} = props.state;
        const {index} = props;
        const data = getDataFromModals(props, index);
        const {settings} = data;

        const newSettings = deepCopy(settings);

        if (newSettings["listTerms"]["values"]["_list"][tSectionAdd] === undefined) {
            newSettings["listTerms"]["values"]["_list"][tSectionAdd] = {};
            for (const [tempKey, tempVal] of Object.entries(defaultSectionSet["listTerms"]['values'])) {
                if (tempKey[0] !== '_') {
                    newSettings["listTerms"]["values"]["_list"][tSectionAdd][tempKey] = tempVal[2];
                }
            }
        }
        props.modalUpdateData(index, {
            settings: newSettings
        });


    };

    const onSectionAddChange = (event) => {
        const {value} = event.target;
        setState({tSectionAdd: value});
    }

    const deleteSetting = (ind) => {
        const [tSectionKey, tElementKey] = ind.split('|');
        const {index} = props;
        const data = getDataFromModals(props, index);
        const {settings} = data;

        let tSetting = deepCopy(settings);

        delete tSetting["listTerms"]["values"]["_list"][tElementKey];

        props.modalUpdateData(index, {
            settings: tSetting
        });

    };

    const onChangeSettingValue = (event, type) => {
        const {value, name} = event.target;
        const [tSectionKey, tElementKey, valueKey] = name.split('|');

        const {index} = props;
        const data = getDataFromModals(props, index);
        const {settings} = data;

        let tSetting = deepCopy(settings);
        let validationError = false;
        if (type === 'boolean')
            setValues(s=>{ return{...s, [`${tSectionKey}|${tElementKey}|${valueKey}`]: !s[`${tSectionKey}|${tElementKey}|${valueKey}`]}});
        else
            setValues(s=>{ return{...s, [`${tSectionKey}|${tElementKey}|${valueKey}`]: value}});

        switch (type) {
            case 'integer':
                validationError = isNaN(parseInt(value));
                tSetting["listTerms"]["values"]["_list"][tElementKey][valueKey] = isNaN(parseInt(value)) ? 0 : parseInt(value);
                break;
            case 'number':
                validationError = isNaN(parseFloat(value));
                tSetting["listTerms"]["values"]["_list"][tElementKey][valueKey] = isNaN(parseFloat(value)) ? 0 : parseFloat(value);
                break;
            case 'enum':
                tSetting["listTerms"]["values"]["_list"][tElementKey][valueKey] = value;
                break;
            case 'boolean':
                tSetting["listTerms"]["values"]["_list"][tElementKey][valueKey] = !tSetting["listTerms"]["values"]["_list"][tElementKey][valueKey];
                break;
            default:
                break;
        }

        setInvalidValues(s=>{
            return {
                ...s,
                [`${tSectionKey}|${tElementKey}|${valueKey}`]: validationError
            }
        })

        props.modalUpdateData(index, {
            settings: tSetting
        });
    }

    let hasAddButton = false;

    const localLang = props.i18n.language;


    return (<>
            <table className="table table-bordered table-striped table-sm">
                <thead>
                <tr>
                    <th key='_'>&nbsp;</th>
                    <th key='__' className="font-smaller text-center my-auto py-1">{translate('type')}</th>
                    {tSectionVal['all_values'].map(valueKeys => {
                            return <th key={valueKeys}
                                       className="font-smaller text-center my-auto py-1">{translate(valueKeys)}</th>
                        }
                    )}
                    <th className="font-smaller text-center my-auto py-1"
                        key='_edit_'>{translate('edit')}</th>
                </tr>
                </thead>
                <tbody>
                {Object.entries(termCategories).map(([tElementKey, tElementVal]) => {
                    const currentSetting = settings['listTerms']['values']['_list'][tElementKey];
                    if (settings['listTerms']['values']['_list'][tElementKey] !== undefined) {
                        return (
                            <tr key={'tr_' + tElementKey}>
                                <td key={'name' + tElementKey}
                                    className="font-smaller my-auto">{tElementVal['name'][localLang]}</td>
                                <td key={'category_type' + tElementKey}
                                    className="font-smaller my-auto">{translate(tElementVal['category_type'])}</td>
                                {tSectionVal['all_values'].map(valueKey => {
                                        const tempValue = values[`${tSectionKey}|${tElementKey}|${valueKey}`]  !== undefined ? values[`${tSectionKey}|${tElementKey}|${valueKey}`] : '';
                                        if (tSectionVal['listTerms'][valueKey] !== undefined ) {

                                            switch (tSectionVal['listTerms'][valueKey]) {
                                                case 'integer':
                                                    return <td key={valueKey}
                                                               className="font-smaller my-auto py-2 px-1"
                                                    ><Form.Control
                                                        isInvalid={!!invalidValues[`${tSectionKey}|${tElementKey}|${valueKey}`]}
                                                        name={`${tSectionKey}|${tElementKey}|${valueKey}`}
                                                        onChange={e => onChangeSettingValue(e, 'integer')}
                                                        size="xs"
                                                        type="integer"
                                                        value={tempValue}
                                                        placeholder={translate(valueKey)}/>
                                                    </td>;
                                                case 'number':
                                                    return <td key={valueKey}
                                                               className="font-smaller my-auto py-2 px-1"
                                                    ><Form.Control
                                                        isInvalid={!!invalidValues[`${tSectionKey}|${tElementKey}|${valueKey}`]}
                                                        name={`${tSectionKey}|${tElementKey}|${valueKey}`}
                                                        onChange={e => onChangeSettingValue(e, 'number')}
                                                        size="xs"
                                                        type="text"
                                                        value={tempValue}
                                                        placeholder={translate(valueKey)}/>
                                                    </td>;
                                                case 'boolean':
                                                    return <td key={valueKey}
                                                               className="font-smaller text-center my-auto py-2 px-1"
                                                    ><Form.Check
                                                        name={`${tSectionKey}|${tElementKey}|${valueKey}`}
                                                        onChange={e => onChangeSettingValue(e, 'boolean')}
                                                        checked={tempValue}
                                                        type="checkbox" label=''/></td>;
                                                case 'enum':
                                                    return <td key={valueKey}
                                                               className="font-smaller my-auto py-2 px-1"
                                                    ><Form.Control
                                                        size={"xs"}
                                                        as="select"
                                                        value={tempValue}
                                                        name={`${tSectionKey}|${tElementKey}|${valueKey}`}
                                                        onChange={e => onChangeSettingValue(e, 'object')}
                                                    >
                                                        {Object.entries(currentSetting['limits'][valueKey]).map(([a, b]) => {
                                                            return <option key={a}
                                                                           value={a}>{translate(a)}</option>
                                                        })}
                                                    </Form.Control></td>;

                                            }
                                        } else {
                                            return <td key={valueKey}/>;
                                        }

                                    }
                                )}
                                <td key={"_edit_"}
                                    className="font-smaller text-center my-auto py-2 pl-2">
                                    <Button size={"xs"}
                                            onClick={() => deleteSetting(`${tSectionKey}|${tElementKey}`)}><i
                                        className="fas fa-trash"/></Button>
                                </td>
                            </tr>
                        );
                    } else {
                        hasAddButton = true;
                    }
                })}
                </tbody>
            </table>

            {hasAddButton &&
            <Row>
                <Col md={3} className="font-smaller text-center my-auto py-2 pl-2">
                    <Form.Control
                        size={"xs"}
                        as="select"
                        value={tSectionAdd}
                        name='addSectionSelect'
                        onChange={e => onSectionAddChange(e)}
                    >
                        <option key='' value=''>{translate('Select Term Category')}</option>
                        {Object.entries(termCategories).map(([tElementKey, tElementVal]) => {
                            if (settings['listTerms']['values']['_list'][tElementKey] === undefined && tElementKey !== 'all_values'
                                && ['positive', 'negative'].includes(tElementVal['category_type'])) {
                                return <option key={tElementKey}
                                               value={tElementKey}>{tElementVal['name'][localLang]}</option>
                            }
                        })}
                    </Form.Control>
                </Col>
                <Col md={1} className="font-smaller text-center my-auto py-2 pl-2">
                    <Button size={"xs"}
                            onClick={() => addSetting()}><i
                        className="fas fa-plus"/></Button>
                </Col>
            </Row>
            }
        </>
    );


}
