import React from "react";
import {withTranslation} from 'react-i18next';
import {Col, Container, Row, Tab, Tabs} from "react-bootstrap";
import {compose, deepCopy, getAllModalFunctions, getDataFromModals} from "../../../utils";
import {withTLService} from "../../hoc";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {userGetAllTermCategories} from "../../../actions";
import ChooseBenchmarkTemplateForm from "./choose-benchmark-template-form";
import BenchmarkMainSettings from "./benchmark-main-settings";
import SectionTab from "./section-tab";
import SectionTabTerms from "./section-tab-terms";
import SectionTabCorporateLanguage from "./section-tab-corporate-language";
import UserRightTable from "../../modal-elements/user-right-table";
import BaseModal from "../base-modal";
import SectionTabTextCleaning from "./section-tab-text-cleaning";
import SectionTabAllowedList from "./section-tab-allowed-list";


class ModalAddEditBenchmark extends BaseModal {

    state = {
        globalError: false,
        tmpCalcelements: [],
        tmpAddSetting: {},
        tabsSetting: "",
        tabsLanguage: this.props.i18n.language,
        tSectionAdd: '',
    }


    componentDidMount() {
        const {
            userData: {token}
        } = this.props;
        this.refreshData();
        this.props.userGetAllTermCategories(token)
    }

    refreshData() {
        const {index} = this.props;
        const {tabsSetting} = this.state;
        const data = getDataFromModals(this.props, index);
        const {id, rights, old_rights, name, description} = data;

        if (old_rights && id > 0 && old_rights.length === 0) {
            this.props.modalUpdateData(index, {
                old_rights: rights,
                name: name,
                description: description
            });
        }
        if (!tabsSetting)
            this.setState({tabsSetting: 'rights'});

    }

    setFromTemplate(template_id) {
        const {index} = this.props;
        const {
            adminData: {benchmark_templates_dir, settings_dir},
        } = this.props;
        const current_template = benchmark_templates_dir[template_id];
        if (current_template) {

            const sectionSet = this.filterSettingByCalcelements(
                settings_dir,
                current_template['default_calcelements'].reduce((acc, value) => [...acc, value[0]], []));

            let sectionRemoved = {};
            for (const [sectionSet_key, sectionSet_value] of Object.entries(sectionSet)) {
                sectionRemoved[sectionSet_key] = deepCopy(sectionSet_value);
                for (const [sectionSet_value_key, sectionSet_value_value] of Object.entries(sectionSet_value['values'])) {
                    sectionRemoved[sectionSet_key]['values'][sectionSet_value_key] = deepCopy(sectionSet_value_value[2]);
                }
            }
            this.props.modalUpdateData(index, {
                benchmark_template: deepCopy(template_id),
                name: current_template['name'],
                description: current_template['description'],
                settings: deepCopy(sectionRemoved),
                text_type_id: deepCopy(current_template['text_type_id']),
                calcelements: deepCopy(current_template['default_calcelements']),
                return_values: deepCopy(current_template['return_values']),
                locale_name: deepCopy(current_template['locale_name']),
                rights: []
            });
        }
    }

    onChangeLSettingTabs(eventKey) {
        this.setState({tabsSetting: eventKey});
    }

    filterSettingByCalcelements(settings, calcelements) {
        let retSetting = {}
        for (const [key, value] of Object.entries(settings)) {
            if (calcelements.indexOf(value['lib']) !== -1)
                retSetting[key] = value;
        }
        return retSetting;
    }

    render() {
        const {index} = this.props;
        const data = getDataFromModals(this.props, index);
        const {benchmark_template, calcelements, rights} = data;
        const {
            t: translate,
            adminData: {settings_dir},
        } = this.props;
        const {tabsSetting} = this.state;

        console.log('render modal');

        if (benchmark_template === 0) {
            return (
                <ChooseBenchmarkTemplateForm
                    {...this.props}
                    setFromTemplate={(template_id) => this.setFromTemplate(template_id)}
                />
            );
        }

        let sectionSetting = {};
        let defaultSectionSet = this.filterSettingByCalcelements(
            settings_dir,
            (typeof calcelements[0] === 'object') ? calcelements.reduce((acc, value) => [...acc, value[0]], []) : calcelements
        );

        for (const [settings_key, settings_value] of Object.entries(defaultSectionSet)) {
            if (sectionSetting[settings_value['category']] === undefined)
                // dirty hack!
                sectionSetting[settings_value['category']] = {all_values: new Array(20).fill('')};
            sectionSetting[settings_value['category']][settings_key] = {};
            for (const [value_key, value_value] of Object.entries(settings_value['values'])) {
                if (value_key[0] !== '_') {
                    sectionSetting[settings_value['category']][settings_key][value_key] = value_value[0];
                    sectionSetting[settings_value['category']]['all_values'][value_value[1]] = value_key;
                }

            }
        }
        for (const [settings_key,] of Object.entries(sectionSetting)) {
            sectionSetting[settings_key]['all_values'] = sectionSetting[settings_key]['all_values'].filter(e => e && e[0] !== '_');
        }

        return (
            <Container>
                <BenchmarkMainSettings
                    {...this.props}
                    state={this.state}
                    setState={newState => this.setState(newState)}
                />
                <h1 className="h5 mb-0 text-gray-800 my-3">{translate('Settings')}</h1>
                <Row>
                    <Col>
                        <Tabs className="font-smaller" id="setting-tab" activeKey={tabsSetting}
                              onSelect={(k) => this.onChangeLSettingTabs(k)}>
                            <Tab tabClassName="font-smaller text-danger" key='rights' eventKey='rights'
                                 title={translate('User right')}>
                                <UserRightTable
                                    translate={translate}
                                    updateData={(name, value) => this.handleChange({target: {name, value}})}
                                    rights={rights}
                                    rightFieldName='rights'
                                />
                            </Tab>
                            {Object.entries(sectionSetting).map(([tSectionKey, tSectionVal]) => {

                                    if (tSectionKey === 'terms_sections') {
                                        return (
                                            <Tab
                                                tabClassName="font-smaller"
                                                key={tSectionKey}
                                                eventKey={tSectionKey}
                                                title={translate(tSectionKey)}>
                                                <SectionTabTerms
                                                    key={tSectionKey}
                                                    {...this.props}
                                                    state={this.state}
                                                    defaultSectionSet={defaultSectionSet}
                                                    tSectionKey={tSectionKey}
                                                    tSectionVal={tSectionVal}
                                                    setState={newState => this.setState(newState)}
                                                />
                                            </Tab>
                                        );
                                    } else if (tSectionKey === 'corporate_language_sections') {
                                        return (
                                            <Tab
                                                tabClassName="font-smaller"
                                                key={tSectionKey}
                                                eventKey={tSectionKey}
                                                title={translate(tSectionKey)}>
                                                <SectionTabCorporateLanguage
                                                    key={tSectionKey}
                                                    {...this.props}
                                                    state={this.state}
                                                    defaultSectionSet={defaultSectionSet}
                                                    tSectionKey={tSectionKey}
                                                    tSectionVal={tSectionVal}
                                                    setState={newState => this.setState(newState)}
                                                />
                                            </Tab>
                                        );
                                    } else if (tSectionKey === 'text_cleaning_sections') {
                                        return (
                                            <Tab
                                                tabClassName="font-smaller"
                                                key={tSectionKey}
                                                eventKey={tSectionKey}
                                                title={translate(tSectionKey)}>
                                                <SectionTabTextCleaning
                                                    key={tSectionKey}
                                                    {...this.props}
                                                    state={this.state}
                                                    defaultSectionSet={defaultSectionSet}
                                                    tSectionKey={tSectionKey}
                                                    tSectionVal={tSectionVal}
                                                    setState={newState => this.setState(newState)}
                                                />
                                            </Tab>
                                        );
                                    } else if (tSectionKey === 'allow_list_sections') {
                                        return (
                                            <Tab
                                                tabClassName="font-smaller"
                                                key={tSectionKey}
                                                eventKey={tSectionKey}
                                                title={translate(tSectionKey)}>
                                                <SectionTabAllowedList
                                                    key={tSectionKey}
                                                    {...this.props}
                                                    state={this.state}
                                                    defaultSectionSet={defaultSectionSet}
                                                    tSectionKey={tSectionKey}
                                                    tSectionVal={tSectionVal}
                                                    setState={newState => this.setState(newState)}
                                                />
                                            </Tab>
                                        );
                                    } else {
                                        return (
                                            <Tab
                                                tabClassName="font-smaller"
                                                key={tSectionKey}
                                                eventKey={tSectionKey}
                                                title={translate(tSectionKey)}>
                                                <SectionTab
                                                    key={tSectionKey}
                                                    {...this.props}
                                                    state={this.state}
                                                    defaultSectionSet={defaultSectionSet}
                                                    tSectionKey={tSectionKey}
                                                    tSectionVal={tSectionVal}
                                                    setState={newState => this.setState(newState)}
                                                />
                                            </Tab>
                                        );

                                    }
                                }
                            )}

                        </Tabs>
                    </Col>
                </Row>
            </Container>
        );
    }

}


const mapStateToProps = ({userData, modalData, openData, adminData}) => {
    return {userData, openData, modalData, adminData};
};

const mapDispatchToProps = (dispatch, {tlService}) => {
    return bindActionCreators({
        ...getAllModalFunctions(),
        userGetAllTermCategories: userGetAllTermCategories(tlService)
    }, dispatch);
};


export default compose(
    withTranslation(),
    withTLService(),
    connect(mapStateToProps, mapDispatchToProps)
)(ModalAddEditBenchmark);