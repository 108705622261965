import React from "react";
import {withTranslation} from 'react-i18next';
import {Col, Container, Form, Row} from "react-bootstrap";
import {compose, checkUserRight, getDataFromModals, getModalFromModals, getAllModalFunctions} from "../../../utils";
import {withTLService} from "../../hoc";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {adminGetAllGroups} from "../../../actions";
import SettingTable from "../../modal-elements/setting-table";
import BaseModal from "../base-modal";
import LicenseTable from "../../modal-elements/license-table";
import RightTable from "../../modal-elements/right-table";


class ModalAddEditUser extends BaseModal {

    state = {
        groups: [],
        password1: '',
        password2: '',
        passwordInvalid: false,
        company_id: '0',
        errors: {
            user_group_id: false
        }
    }

    componentDidMount() {
        const {index} = this.props;
        const data = getDataFromModals(this.props, index);
        if (data.company_id) {
            this.updateGroups(data.company_id);
            this.setState({company_id: data.company_id});
        }
    }

    checkForm = () => {
        const {passwordInvalid, errors} = this.state;
        const {index} = this.props;
        const data = getDataFromModals(this.props, index);
        const {user_name, email, password, user_group_id} = data;

        const modal = getModalFromModals(this.props, index);

        if (parseInt(user_group_id) < 1) {

            this.setState({
                errors: {
                    ...errors,
                    user_group_id: 'Select user group'
                }
            })
        } else {
            this.setState({
                errors: {
                    ...errors,
                    user_group_id: false
                }
            })
        }

        if (modal.actionName === 'modalEdit' || (!passwordInvalid && user_name && email && password)) {
            this.props.modalUpdate(index, {ok: true});
        } else {
            this.props.modalUpdate(index, {ok: false});
        }
    }

    handleChangePassword = (event) => {
        const {index} = this.props;
        const {name, value} = event.target;
        const {password1, password2} = this.state;

        if ((name === 'password1' && password2 !== value) || (name === 'password2' && password1 !== value)) {
            this.setState({
                passwordInvalid: true,
                password1: name === 'password1' ? value : password1,
                password2: name === 'password2' ? value : password2
            })
            return false
        }
        this.setState({
            passwordInvalid: false,
            password1: name === 'password1' ? value : password1,
            password2: name === 'password2' ? value : password2
        })
        this.props.modalUpdateData(index, {password: value})
    };

    updateGroups(id = null) {
        const {userData: {token}} = this.props;
        this.props.adminGetAllGroups(token, id);
    }

    handleChangeCompany = (event) => {
        const {value} = event.target;
        this.updateGroups(parseInt(value));
        this.setState({company_id: parseInt(value)});
    }

    render() {
        const {index} = this.props;
        const data = getDataFromModals(this.props, index);
        const {
            language_id, country_id, user_name,
            email, first_name, last_name, zip_code, city, street, house_number,
            enabled, user_group_id, user_type_id, t_a_c, c_p,
        } = data;
        const modal = getModalFromModals(this.props, index);


        const {
            t: translate, i18n,
            adminData: {companies, groups, rights, options},
            openData: {licenses, countries, languages, user_types},
            userData: {user}
        } = this.props;
        const {password1, password2, passwordInvalid, errors, company_id} = this.state;

        console.log('render modal', modal);

        return (
            <Container className="font-smaller">
                <Row>
                    <Col md={6}>
                        <Form.Group controlId="user_name">
                            <Form.Label>{translate('User name')}</Form.Label>
                            <Form.Control
                                size="xs"
                                type="text"
                                name="user_name"
                                defaultValue={user_name}
                                onChange={e => this.handleChange(e)}
                                placeholder="Enter user_name"/>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="email">
                            <Form.Label>{translate('Email')}</Form.Label>
                            <Form.Control
                                size="xs"
                                type="email"
                                name="email"
                                defaultValue={email}
                                onChange={e => this.handleChange(e)}
                                placeholder="Enter email"/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Form.Group controlId="password1">
                            <Form.Label>{translate('Password')}</Form.Label>
                            <Form.Control
                                size="xs"
                                isInvalid={passwordInvalid}
                                type="password"
                                name="password1"
                                defaultValue={password1}
                                onChange={e => this.handleChangePassword(e)}
                                placeholder="Enter password"/>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="password2">
                            <Form.Label>{translate('Repeat password')}</Form.Label>
                            <Form.Control
                                size="xs"
                                isInvalid={passwordInvalid}
                                type="password"
                                name="password2"
                                defaultValue={password2}
                                onChange={e => this.handleChangePassword(e)}
                                placeholder="Re-enter password"/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Form.Group controlId="first_name">
                            <Form.Label>{translate('First name')}</Form.Label>
                            <Form.Control
                                size="xs"
                                type="text"
                                name="first_name"
                                defaultValue={first_name}
                                onChange={e => this.handleChange(e)}
                                placeholder="Enter first_name"/>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="last_name">
                            <Form.Label>{translate('Last name')}</Form.Label>
                            <Form.Control
                                size="xs"
                                type="text"
                                name="last_name"
                                defaultValue={last_name}
                                onChange={e => this.handleChange(e)}
                                placeholder="Enter last_name"/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>
                        <Form.Group controlId="zip_code">
                            <Form.Label>{translate('Zip code')}</Form.Label>
                            <Form.Control
                                size="xs"
                                type="text"
                                name="zip_code"
                                defaultValue={zip_code}
                                onChange={e => this.handleChange(e)}
                                placeholder="Enter zip_code"/>
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group controlId="city">
                            <Form.Label>{translate('City')}</Form.Label>
                            <Form.Control
                                size="xs"
                                type="text"
                                name="city"
                                defaultValue={city}
                                onChange={e => this.handleChange(e)}
                                placeholder="Enter city"/>
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group controlId="street">
                            <Form.Label>{translate('Street')}</Form.Label>
                            <Form.Control
                                size="xs"
                                type="text"
                                name="street"
                                defaultValue={street}
                                onChange={e => this.handleChange(e)}
                                placeholder="Enter street"/>
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group controlId="house_number">
                            <Form.Label>{translate('House number')}</Form.Label>
                            <Form.Control
                                size="xs"
                                type="text"
                                name="house_number"
                                defaultValue={house_number}
                                onChange={e => this.handleChange(e)}
                                placeholder="Enter house_number"/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Form.Group controlId="country_id">
                            <Form.Label>{translate('Country')}</Form.Label>
                            <Form.Control
                                size="xs"
                                onChange={e => this.handleChange(e)}
                                value={country_id}
                                name="country_id"
                                as="select">
                                {
                                    Object.keys(countries).length > 0 && Object.keys(countries).map((country) =>
                                        <option key={country}
                                                value={country}>{countries[country].full_name}</option>
                                    )
                                }
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="language_id">
                            <Form.Label>{translate('Language')}</Form.Label>
                            <Form.Control
                                size="xs"
                                onChange={e => this.handleChange(e)}
                                value={language_id || i18n.language}
                                name="language_id"
                                as="select">
                                {
                                    Object.keys(languages).length > 0 && Object.keys(languages).map((language) =>
                                        <option key={language}
                                                value={language}>{languages[language].lang_name}</option>
                                    )
                                }
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Form.Group controlId="company_id">
                            <Form.Label>{translate('Company')}</Form.Label>
                            <Form.Control
                                size="xs"
                                onChange={e => this.handleChangeCompany(e)}
                                value={company_id}
                                name="company_id"
                                as="select">
                                <option key="0" value="0">{translate('Select company')}</option>
                                {
                                    Object.keys(companies).length > 0 && Object.keys(companies).map((key) =>
                                        <option key={key} value={key}>{companies[key].name}</option>
                                    )
                                }
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="user_group_id">
                            <Form.Label>{translate('Group')}</Form.Label>
                            <Form.Control
                                size="xs"
                                onChange={e => this.handleChange(e)}
                                value={user_group_id}
                                name="user_group_id"
                                isInvalid={!!errors.user_group_id}
                                as="select">
                                {parseInt(company_id) > 0 ?
                                    <option key="0" value="0">{translate('Select group')}</option> :
                                    <option key="0" value="0">{translate('Select the company before')}</option>
                                }
                                {
                                    Object.keys(groups).length > 0 && Object.keys(groups).map((key) =>
                                        <option key={key} value={key}>{groups[key].name}</option>
                                    )
                                }
                            </Form.Control>
                            {errors.user_group_id &&
                            <Form.Control.Feedback type="invalid">{errors.user_group_id}</Form.Control.Feedback>}
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <LicenseTable
                            {...this.props}
                            licenses={licenses}
                            licenseFieldName='license_id'
                            multiple={false}
                        />
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="user_type_id">
                            <Form.Label>{translate('User type')}</Form.Label>
                            <Form.Control
                                size="xs"
                                onChange={this.handleChange}
                                value={user_type_id}
                                name="user_type_id"
                                as="select">
                                {
                                    Object.keys(user_types).length > 0 && Object.keys(user_types).map((key) =>
                                        <option key={key} value={key}>{user_types[key].name}</option>
                                    )
                                }
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={2}>
                        <Form.Group controlId="enabled">
                            <Form.Check
                                size="xs"
                                checked={enabled}
                                onChange={e => this.handleChange(e, 'enabled', true)}
                                name="enabled"
                                type="checkbox"
                                label={translate("Is user enabled?")}/>
                        </Form.Group>
                    </Col>
                    {(modal.actionName === 'modalAdd') && (
                        <>
                            <Col md={3}>
                                <Form.Group controlId="t_a_c">
                                    <Form.Check
                                        size="xs"
                                        checked={t_a_c}
                                        onChange={e => this.handleChange(e, 't_a_c', true)}
                                        name="t_a_c"
                                        type="checkbox"
                                        label={translate("terms and conditions?")}/>
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group controlId="c_p">
                                    <Form.Check
                                        size="xs"
                                        checked={c_p}
                                        onChange={e => this.handleChange(e, 'c_p', true)}
                                        name="c_p"
                                        type="checkbox"
                                        label={translate("change password?")}/>
                                </Form.Group>
                            </Col>
                        </>)
                    }
                </Row>
                {(checkUserRight(user, [205]) || (checkUserRight(user, [105]) && user_group_id === user.user_group_id)
                ) && <RightTable
                    {...this.props}
                    rightFieldName='user_rights'
                    rights={rights}
                    groups={groups}
                    checkForm={() => this.checkForm()}
                />
                }


                {(checkUserRight(user, [204]) || (checkUserRight(user, [104]) && user_group_id === user.user_group_id)
                ) && (
                    <>
                        <h1 className="h5 mb-0 text-gray-800 my-3">Options</h1>
                        <SettingTable
                            addText='Add option'
                            settingText='Options'
                            valueText='Value'
                            valuePlaceholder='Add Value'
                            selectText='Select option name'
                            settings={Object.keys(options)}
                            settingFieldName={"user_options"}
                            {...this.props}
                        />
                    </>
                )}
            </Container>
        )
    }
}

const
    mapStateToProps = ({userData, modalData, openData, adminData}) => {
        return {userData, openData, modalData, adminData};
    };

const
    mapDispatchToProps = (dispatch, {tlService}) => {
        return bindActionCreators({
            ...getAllModalFunctions(),
            adminGetAllGroups: adminGetAllGroups(tlService)
        }, dispatch);
    };

export default compose(
    withTranslation(),
    withTLService(),
    connect(mapStateToProps, mapDispatchToProps)
)(ModalAddEditUser);