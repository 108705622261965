import React, {Component} from "react";
import {withTranslation} from 'react-i18next';
import {Col, Container, Form, Row, Tab, Tabs} from "react-bootstrap";
import {compose, getAllModalFunctions, getDataFromModals} from "../../../utils";
import {withTLService} from "../../hoc";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import backendLanguages from "../../../utils/backendlanguages.json";
import NameDescriptionMultilang from "../../modal-elements/name-description-multilang";
import UserRightTable from "../../modal-elements/user-right-table";
import SettingTable from "../../modal-elements/setting-table";

import mainConfig from "../../../config";
import BaseModal from "../base-modal";

class ModalAddEditTermCategory extends BaseModal {

    categoryTypes = ['positive', 'negative', 'allowedlist']

    state = {
        tabsSetting: 'user-right-tab',
    }


    refreshData() {
        const {index} = this.props;
        const data = getDataFromModals(this.props, index);
        const {id, rights, old_rights} = getDataFromModals(this.props, index);
        if ( id > 0 && (!old_rights || old_rights.length === 0)) {
            this.props.modalUpdateData(index,{
                old_rights: rights
            });
        }

    }

    componentDidMount() {
        this.refreshData();
    }

    onChangeSettingTabs = (event) => {
        this.setState({tabsSetting: event});

    }

    render() {
        const {index} = this.props;
        const {id, name, description, rights, locale_name, category_type, global_visible, parent_id} = getDataFromModals(this.props, index);
        const {
            t: translate,
            userData: {termCategories},
            i18n
        } = this.props;
        const {tabsSetting} = this.state;
        const optionSet = mainConfig["termCategoryOptionSet"];

        return (
            <Container>
                <Row>
                    <Col className="mx-auto my-1 text-center">
                        <strong>{translate('Multilanguage support')}</strong>
                        <NameDescriptionMultilang
                            nameField={['name', 'Enter name']}
                            descriptionField={['description', 'Enter Description']}
                            tName={name}
                            tDescription={description}
                            sefaultLanguage={i18n.language}
                            translate={translate}
                            updateData={(name, value) => this.handleChange({target:{name, value}})}
                        />
                    </Col>
                    <Col className="mx-auto my-1 text-center">
                        <strong>{translate('Blah blah')}</strong>
                        <Row>
                            <Col className="mx-auto my-1">{translate('Language')}</Col>
                            <Col className="mx-auto my-1">
                                <Form.Control
                                    size={"xs"}
                                    as="select"
                                    value={locale_name}
                                    name="locale_name"
                                    onChange={e => this.handleChange(e)}>
                                    {
                                        Object.keys(backendLanguages).map(lang =>
                                            <option key={lang}
                                                    value={lang}>{backendLanguages[lang]['nativeName']}</option>
                                        )
                                    }
                                </Form.Control>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mx-auto my-1">{translate('Category Type')}</Col>
                            <Col className="mx-auto my-1">
                                <Form.Control
                                    size={"xs"}
                                    as="select"
                                    value={category_type}
                                    name="category_type"
                                    onChange={e => this.handleChange(e)}>
                                    {
                                        this.categoryTypes.map(val =>
                                            <option key={val}
                                                    value={val}>{translate(val)}</option>
                                        )
                                    }
                                </Form.Control>
                            </Col>
                        </Row>

                        <Row>
                            <Col className="mx-auto my-1">{translate('Visibility')}</Col>
                            <Col className="mx-auto my-1">
                                <Form.Group controlId="global_visible">
                                    <Form.Check
                                        className="font-smaller"
                                        checked={global_visible}
                                        onChange={e => this.handleChange(e,false,true)}
                                        name="global_visible"
                                        type="checkbox"
                                        label={translate("Will the category be visible to all users?")}/>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col className="mx-auto my-1">{translate('Parent Category')}</Col>
                            <Col className="mx-auto my-1">
                                <Form.Control
                                    disabled={!!id}
                                    size={"xs"}
                                    as="select"
                                    value={parent_id}
                                    name="parent_id"
                                    onChange={e => this.handleChange(e)}
                                >
                                    <option key='0' value='0'>{translate('No parent category')}</option>
                                    {

                                        Object.entries(termCategories).map(([key, val]) => {
                                                if (val['id'] !== id)
                                                return (<option key={key}
                                                                value={key}>{val.name[this.props.i18n.language]}</option>)
                                            }
                                        )
                                    }
                                </Form.Control>
                            </Col>
                        </Row>


                    </Col>
                </Row>

                <h1 className="h5 mb-0 text-gray-800 my-3">Options</h1>

                <Tabs className="font-smaller" id="tab-set" activeKey={tabsSetting}
                      onSelect={(k) => this.onChangeSettingTabs(k)}>

                    <Tab tabClassName="font-smaller text-danger" key='user-right-tab' eventKey='user-right-tab'
                         title={translate('User right')}>
                        <UserRightTable
                            translate={translate}
                            updateData={(name, value) => this.handleChange({target:{name, value}})}
                            rights={rights}
                            rightFieldName='rights'
                        />
                    </Tab>
                    <Tab tabClassName="font-smaller" key='setting-tab' eventKey='setting-tab'
                         title={translate('Settings')}>
                        <SettingTable
                            addText='Add setting'
                            settingText='Setting'
                            valueText='Value'
                            valuePlaceholder='Add Value'
                            selectText='Select setting name'
                            settings={optionSet}
                            settingFieldName='settings'
                            {...this.props}
                        />
                    </Tab>

                </Tabs>

            </Container>
        )
    }
}

const mapStateToProps = ({userData, modalData, openData, adminData}) => {
    return {userData, openData, modalData, adminData};
};

const mapDispatchToProps = (dispatch, {tlService}) => {
    return bindActionCreators({
        ...getAllModalFunctions(),
    }, dispatch);
};

export default compose(
    withTranslation(),
    withTLService(),
    connect(mapStateToProps, mapDispatchToProps)
)(ModalAddEditTermCategory);