import React, {useState} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
import {getDataFromModals, isObjectEmpty} from "../../utils";
/*
<SettingTable
    addText='Add setting'
    settingText='Settings'
    valueText='Value'
    valuePlaceholder='Add Value'
    selectText='Select setting name'
    settings={config['termSettings']}
    settingFieldName={"settings"}
    {...this.props}
/>
*/
export default function SettingTable(props) {

    const {
        t: translate,
        addText,
        settingText,
        valueText,
        selectText,
        settings,
        index,
        settingFieldName,
        modalUpdateData,
        valuePlaceholder
    } = props;
    const [tmpSettings, setTmpSettings] = useState([]);
    const data = getDataFromModals(props, index);

    // get licenses on component mount


    let newSettings = [];
    if (!isObjectEmpty(data[settingFieldName])) {
        try {
            const objSetting = typeof data[settingFieldName] === "object" ? data[settingFieldName] : JSON.parse(data[settingFieldName]);
            newSettings = Object.keys(objSetting).map((setting) => {
                return {setting, value: objSetting[setting]}
            });
        } catch (e) {
            newSettings = [];
        }
    }

    if (JSON.stringify(tmpSettings) !== JSON.stringify(newSettings)) {
        setTmpSettings(newSettings || []);
    }

    const handleChangeSetting = (event) => {
        let newTmpSettings = [...tmpSettings];

        const {name, value} = event.target;

        if (value) {
            const foundSetting = name.match(/setting_(\d+)/)
            if (foundSetting) {
                newTmpSettings[parseInt(foundSetting[1])].setting = value;
            }
            const foundValue = name.match(/value_(\d+)/)
            if (foundValue) {
                newTmpSettings[parseInt(foundValue[1])].value = value;
            }
            setTmpSettings(newTmpSettings);
            saveToSetting(newTmpSettings);
        }
    };

    const addSetting = () => {
        const newTmpSettings = tmpSettings.concat([{setting: '', value: ''}]);
        saveToSetting(newTmpSettings);
    };

    const deleteSetting = (index) => {
        let newTmpSettings = [...tmpSettings];
        newTmpSettings.splice(index, 1);
        saveToSetting(newTmpSettings);
    }

    const saveToSetting = (insertData = undefined) => {
        const newTmpSettings = insertData || tmpSettings;
        modalUpdateData(index, {
            [settingFieldName]: newTmpSettings.reduce((accumulator, currentValue) => {
                accumulator[currentValue.setting] = currentValue.value;
                return accumulator
            }, {})
        })
    }

    console.log("tmpSettings", tmpSettings)

    return (
        <Row>
            <Col>
                <Row>
                    <Col md={4}><Form.Label>{translate(settingText)}</Form.Label></Col>
                    <Col md={7}><Form.Label>{translate(valueText)}</Form.Label></Col>
                </Row>
                {
                    tmpSettings.map((opt, index) => {
                        const {setting, value} = opt;

                        return (
                            <Row key={index}>
                                <Col md={4}>
                                    <Form.Group controlId={"setting_" + index}>
                                        <Form.Control
                                            size={"xs"}
                                            as="select"
                                            value={setting}
                                            name={"setting_" + index}
                                            onChange={handleChangeSetting}>
                                            <option key=""
                                                    value="">{translate(selectText)}</option>
                                            {
                                               settings.length > 0 && settings.map((key) => {
                                                    return (<option key={key}
                                                                    value={key}>{key}</option>)
                                                })
                                            }
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col md={7}>
                                    <Form.Group controlId={"value_" + index}>
                                        <Form.Control
                                            size={"xs"}
                                            type="text"
                                            name={"value_" + index}
                                            value={value}
                                            onChange={handleChangeSetting}
                                            placeholder={translate(valuePlaceholder)}/>
                                    </Form.Group>
                                </Col>
                                <Col md={1}>
                                    <Button onClick={() => deleteSetting(index)}
                                            className="ml-2d-none d-sm-inline-block btn btn-xs btn-primary shadow-sm"
                                    ><i
                                        className="fas fa-trash fa-sm"/></Button>
                                </Col>
                            </Row>

                        )
                    })
                }
                <Row>
                    <Col>
                        <Button
                            onClick={() => addSetting()}
                            className="d-none d-sm-inline-block btn btn-xs btn-primary shadow-sm"><i
                            className="fas fa-plus fa-sm text-white-50 mr-1"/>{translate(addText)}
                        </Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    );

}

