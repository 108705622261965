import React from "react";
import Dropzone from 'react-dropzone';
import {withTranslation} from 'react-i18next';
import './dnd.css'

const Dnd = props =>{
    const { onReadDropFiles, t: translate } = props;
    return (
        <Dropzone onDrop={onReadDropFiles}>
            {({getRootProps, getInputProps}) => (
                <section className="border border-dark rounded dnd-border">
                    <div {...getRootProps()}  className="mx-auto my-auto row">
                        <input {...getInputProps()} />
                        <div className="font-small mx-auto my-auto">{translate("dnd zone")}</div>
                    </div>
                </section>
            )}
        </Dropzone>
    );
};

export default withTranslation()(Dnd);
