import React, {useState} from "react";
import {Button, Col, Form, Row, Tab} from "react-bootstrap";
import {getDataFromModals} from "../../utils";

export default function RightTable(props) {

    const {
        i18n,
        t: translate,
        rights,
        groups,
        index,
        rightFieldName,
        modalUpdateData,
        checkForm
    } = props;
    const [tmpRights, setTmpRights] = useState([]);
    const data = getDataFromModals(props, index);

    // get rights on component mount
    if (JSON.stringify(tmpRights) !== JSON.stringify(data[rightFieldName])) {
        setTmpRights(data[rightFieldName]);
    }

    const onlyUniqueRight = (value, index, self) => {
        return self.findIndex(
            (innerValue) =>
                innerValue.right_id === value.right_id &&
                innerValue.user_group_id === value.user_group_id) === index;
    }

    const saveToRights = (insertData = undefined) => {
        const newRights = insertData || tmpRights;
        console.log('newRights', newRights.filter(onlyUniqueRight) );
        modalUpdateData(index, {
            [rightFieldName]: newRights.filter(onlyUniqueRight) || []
        })
        checkForm();
    }

    const deleteRight = (index) => {
        let newTmpRights = [...tmpRights];
        newTmpRights.splice(index, 1);
        saveToRights(newTmpRights);
    }

    const addRight = (fields = [{right_id: '0', user_group_id: '0'}]) => {
        saveToRights(tmpRights.concat(fields));
    }

    const handleChangeRight = (event) => {
        const {name, value} = event.target;
        let newTmpRights = [...tmpRights];

        const foundRight = name.match(/right(\d+)/)
        if (foundRight) {
            newTmpRights[parseInt(foundRight[1])].right_id = value;
        }
        const foundGroup = name.match(/user_group(\d+)/)
        if (foundGroup) {
            newTmpRights[parseInt(foundGroup[1])].user_group_id = value;
        }
        saveToRights(newTmpRights);
    };


    return (
        <>
            <h1 className="h5 mb-0 text-gray-800 my-3">{translate('Rights')}</h1>
            <Row>
                <Col>
                    <Row>
                        <Col md={5}><Form.Label>{translate('Right')}</Form.Label></Col>
                        <Col md={5}><Form.Label>{translate('Group')}</Form.Label></Col>
                    </Row>
                    {
                        tmpRights.map((value, index) => {
                            const {right_id, user_group_id} = value;
                            return (
                                <Row key={index}>
                                    <Col md={5}>
                                        <Form.Group controlId={"right" + index}>

                                            <Form.Control
                                                size="xs"
                                                as="select"
                                                value={right_id}
                                                name={"right" + index}
                                                onChange={handleChangeRight}>
                                                <option key="0"
                                                        value="0">{translate('Select right')}</option>
                                                {
                                                    Object.keys(rights).length > 0 && Object.keys(rights).map((key) => {
                                                        return (<option key={key}
                                                                        value={key}>{rights[key]['description'][i18n.language]}</option>)
                                                    })
                                                }
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col md={5}>
                                        <Form.Group controlId={"user_group" + index}>

                                            <Form.Control
                                                size="xs"
                                                onChange={handleChangeRight}
                                                value={user_group_id}
                                                name={"user_group" + index}
                                                as="select">
                                                <option key="0" value="0">{translate('No group')}</option>
                                                {
                                                    Object.keys(groups).length > 0 && Object.keys(groups).map((key) =>
                                                        <option key={key}
                                                                value={key}>{groups[key].name}</option>
                                                    )
                                                }
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col md={2}>
                                        <Button onClick={() => deleteRight(index)} className="ml-2"><i
                                            className="fas fa-trash  fa-sm "/></Button>
                                    </Col>

                                </Row>

                            )
                        })
                    }
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button
                        onClick={() => addRight()}
                        className="d-none d-sm-inline-block btn btn-xs btn-primary shadow-sm"><i
                        className="fas fa-plus fa-sm text-white-50 mr-1 "/>{translate('Add right')}</Button>
                </Col>
            </Row>
        </>
    );
}