import compose from './compose';
import backendLanguages from "./backendlanguages.json";
import LanguageDetect from "languagedetect";
import siteLanguages from "./sitelanguages.json";
import {getAllModalFunctions} from "./modal"


async function getResource(apiBase, url, method = 'GET', body = null, token = null, json = true, extraHeaders = {}) {
    let headers = token ? {Authorization: `Bearer ${token}`} : {};
    if (json) {
        headers['Content-Type'] = 'application/json';
        headers['Accept'] = 'application/json';
    }

    if (!isObjectEmpty(extraHeaders)) {
        headers = {...headers , ...extraHeaders};
    }

    console.log('headers',headers, extraHeaders ,json)
    const sendBody = (body && typeof body === 'object') ? JSON.stringify(body) : body;

    const res = await fetch(`${apiBase}${url}`, {
        async: true,
        method,
        headers,
        body: sendBody ? sendBody : null
    });
    if (!res.ok) {
        if (res.status && res.status >= 400 && res.status < 500) {
            let resBody = await res.text();
            resBody = resBody ? JSON.parse(resBody) : resBody;
            throw new Error(resBody.message !== undefined ? resBody.message : 'error number: ' + res.status);
        }
        throw new Error('protocol error')
    }
    let resBody = await res.text();
    resBody = resBody ? JSON.parse(resBody) : false;
    return resBody;
}


function roundWithPrecision (num, precision = 0) {
    return  +(num).toFixed(precision)
}


async function convertTextToTT(token, tlService, rawWords, locale_name) {

    let words = [];
    let lemma = [];
    let tag = [];

    let res;

    try {
        res = await tlService.getTreeTager(token, locale_name, rawWords);
    } catch (e) {
        console.log(e);
        return false;
    }
    if (res && res['dataTokenizer']) {
        for (let data of res['dataTokenizer']) {
            words.push(data.word);
            lemma.push(data.lemma);
            tag.push(data.tag);
        }
        return {words, lemma, tag};
    }
    return false;
}


function getDataFromModals(props, index) {
    const {modalData: {modal}} = props;
    return modal[index] ? modal[index].data : false;
}

function getModalFromModals(props, index) {
    const {modalData: {modal}} = props;
    return modal[index] ? modal[index] : false;
}

function createDefaultMultilangObject(values = {}) {
    return Object.keys(siteLanguages).reduce((prev, current) => {
        return {...prev, [current]: (values && values[current]) ? values[current] : ''}
    }, {})
}

function deepCopy(el) {
    return typeof el === 'object' ? JSON.parse(JSON.stringify(el)) : el;
}

function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}

function removeEmptyFromObject (object) {
      Object.entries(object).map(([key,value]) => {
          if (key.length === 0 || (typeof value  === 'string' && value.length === 0)) {
              delete object[key];
          }
          return false;
      })
    return object;
}


function convertLanguagesToFull(language, fullList) {
    if (language && fullList) {
        const langRegexp = new RegExp("^" + language + "\\_\\w{2}$")
        const langArray = Object.keys(fullList);
        const langIndex = langArray.findIndex((element) => element.match(langRegexp));
        if (langIndex > -1) {
            return langArray[langIndex]
        }

    }
    return false;
}

function convertLanguagesToShort(language, fullList) {
    if (language && fullList) {
        if (fullList[language] !== undefined) {
            return fullList[language]['shortName'];
        }
    }
    return false;
}

function readUploadedFileAsBinary(inputFile) {
    const temporaryFileReader = new FileReader();

    return new Promise((resolve, reject) => {

        temporaryFileReader.onerror = () => {
            temporaryFileReader.abort();
            reject(new DOMException("Problem parsing input file."));
        };

        temporaryFileReader.onload = () => {
            resolve(temporaryFileReader.result);
        };
        temporaryFileReader.readAsBinaryString(inputFile);
    });
}

function checkUserRight(user, right = []) {
    return (user.user_type_id === 1 ||
        (user.user_type_id === 2 && user.user_rights.findIndex(
            (rightTmp) => right.indexOf(rightTmp.right_id) > -1
        ) > -1))
}

function stripHtml(html) {
    const tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
}

function cumulativeOffset(element) {
    let top = 0, left = 0;
    do {
        top += element.offsetTop || 0;
        left += element.offsetLeft || 0;
        element = element.offsetParent;
    } while (element);

    return {
        top: top,
        left: left
    };
}

function convertPlainToHTML(input_str) {
    let text_input; //store input after beging trim()med
    let output_html = ""; //store output
    let counter;

    text_input = input_str.trim(); //trim() input
    if (text_input.length > 0) {
        output_html += "<p>"; //begin by creating paragraph
        for (counter = 0; counter < text_input.length; counter++) {
            switch (text_input[counter]) {
                case '\n':
                    if (text_input[counter + 1] === '\n') {
                        output_html += "</p>\n<p>";
                        counter++;
                    } else output_html += "<br />";
                    break;

                case ' ':
                    if (text_input[counter - 1] !== ' ' && text_input[counter - 1] !== '\t')
                        output_html += " ";
                    break;

                case '\t':
                    if (text_input[counter - 1] !== '\t')
                        output_html += " ";
                    break;

                case '&':
                    output_html += "&amp;";
                    break;

                case '"':
                    output_html += "&quot;";
                    break;

                case '>':
                    output_html += "&gt;";
                    break;

                case '<':
                    output_html += "&lt;";
                    break;

                default:
                    output_html += text_input[counter];

            }

        }
        output_html += "</p>"; //finally close paragraph
    }
    return output_html; // display output html
}

function removeTagMark(text) {
    return text.replace(/<\/mark>/gm, '').replace(/<mark [^>]+>/gm, '');
}

function setLanguage(text) {
    const lngDetector = new LanguageDetect();
    lngDetector.setLanguageType('iso2');
    const detectedLanguage = lngDetector.detect(stripHtml(text));
    if (detectedLanguage && detectedLanguage[0] !== undefined && detectedLanguage[0][0] !== undefined) {
        const lang = convertLanguagesToFull(detectedLanguage[0][0], backendLanguages);
        if (lang) {
            return lang;
        }
    }
    return false;
}

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function convertArrayToText(text) {

    const oscillatingFirst = ["'", '"', '‹', '‘', '»', '“'];
    const oscillatingSecond = ["'", '"', '›', '‘', '«', '”'];
    let oscillatingCounter = {0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0}

    let newtext = text.map(val => val + ' ')
    newtext[newtext.length - 1] = newtext[newtext.length - 1].trimEnd();

    for (let i in text) {

        // No space BEFORE following characters: .,;!?:)]}…%‰
        if (text[i].match(/[.,;!?:)\]}…%‰]/)) {
            if (i > 0) {
                newtext[i - 1] = newtext[i - 1].trimEnd();
            }
        } else if (text[i].match(/[([{„¿¡‚†‡]/)) {
            //No space AFTER following characters: ([{„“[¿¡{‚„†‡
            if (i < newtext.length - 1) {
                newtext[i] = newtext[i].trimEnd();
            }
        }

        //oscillating
        const firstFind = oscillatingFirst.indexOf(text[i]);
        const secondFind = oscillatingSecond.indexOf(text[i]);

        if (firstFind !== -1 && oscillatingCounter[firstFind] === 0) {
            newtext[i] = newtext[i].trimEnd();
            oscillatingCounter[firstFind] = 1;
        } else if (secondFind !== -1 && oscillatingCounter[secondFind] === 1) {
            if (i > 0) {
                newtext[i - 1] = newtext[i - 1].trimEnd();
            }
            oscillatingCounter[secondFind] = 0;
        }
    }
    return newtext.join('');
}

function convertJSONToObject(jsonString, defaultValue = {}) {
    if (typeof jsonString === 'object')
        return jsonString;
    try {
        return JSON.parse(jsonString);
    } catch (e) {
        return false;
    }
}

function isObjectEmpty(obj) {
    if (typeof obj !== 'object')
        return obj === undefined

    for (const x in obj) {
        return false;
    }
    return true;
}

export {
    getResource,
    isObjectEmpty,
    convertJSONToObject,
    stripHtml,
    compose,
    checkUserRight,
    readUploadedFileAsBinary,
    convertLanguagesToFull,
    convertPlainToHTML,
    convertLanguagesToShort,
    removeTagMark,
    setLanguage,
    sleep,
    cumulativeOffset,
    onlyUnique,
    deepCopy,
    convertArrayToText,
    createDefaultMultilangObject,
    getDataFromModals,
    getModalFromModals,
    convertTextToTT,
    removeEmptyFromObject,
    getAllModalFunctions,
    roundWithPrecision
}

