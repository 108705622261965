import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from 'react-router-dom';
import App from './components/app';
import {HelmetProvider} from "react-helmet-async";
import TLService from './services/tl-service';
import {TLServiceProvider} from './components/tl-api-context';
import {Provider} from 'react-redux';
import Spinner from "./components/elements/spinner";

import store from './store';

import './i18n';
import {userSetToken} from "./actions";
// is for const LazyPDFDocument = React.lazy(() => import("./PDFPreview");


// disable console.log in production!

if (process.env.REACT_APP_CONSOLE_LOG === "false") {

    console = {};
    console.log = (...args) => {
    };
    console.error = (...args) => {
    };
    window.console = console;
}

const tlService = new TLService();

// get storageToken
const storageToken = localStorage.getItem('token');
if (storageToken && storageToken !== 'null') {
    store.dispatch(userSetToken(storageToken));
}

ReactDOM.render(
    <Provider store={store}>
        <Suspense fallback={<Spinner/>}>
            <TLServiceProvider value={tlService}>
                <HelmetProvider>
                    <Router>
                        <App/>
                    </Router>
                </HelmetProvider>
            </TLServiceProvider>
        </Suspense>
    </Provider>
    , document.getElementById('root'));
