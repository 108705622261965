import {Button, Col, Container, Form, Row} from "react-bootstrap";
import React, {Component} from "react";
import {compose} from "../../../utils";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {clearUser} from "../../../actions";
import {Redirect} from "react-router-dom";
import {withTLService} from "../../hoc";

class MustChangePassword extends Component {

    state = {
        oldPassword: '',
        password1: '',
        password2: '',
        loading: false,
        error: '',
        passwordInvalid: false
    }

    handleChangePassword = (event) => {
        const {name, value} = event.target;
        this.setState({[name]: value});
    }

    async handleSubmit() {
        const {
            userData: {token},
            data: {id},
            tlService
        } = this.props;
        const {oldPassword, password1, password2} = this.state

        if (!oldPassword || !password1 || !password2 || (password2 !== password1) || password1 === oldPassword) {
            this.setState({passwordInvalid: true})
            return false;
        }
        try {
            await tlService.updateUser(token, id, {password: password1, old_password: oldPassword});
        } catch (e) {
            console.log(e);
            this.setState({passwordInvalid: true});
            return false;
        }

        try {
            await this.props.tlService.setMessageRead(token, id);
        } catch (e) {
            console.log(e);
            return false;
        }

        this.logout();
    }

    logout() {
        this.props.clearUser();
        localStorage.removeItem('token');
        return <Redirect to="/"/>;
    }

    render() {
        const {oldPassword, password1, password2, passwordInvalid} = this.state
        const {t: translate} = this.props;
        return (
            <Container className="d-flex justify-content-center bg-danger text-black-50 align-items-center w-100 h-100">
                <Row className="mx-auto my-auto">
                    <Col>
                        <Row>
                            <Col md={10}></Col>
                            <Col md={2}>
                                <Button
                                    onClick={() => this.logout()}
                                    variant="secondary">
                                    <i className="fas fa-sign-out-alt fa-sm fa-fw text-gray-400"/>
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h2>{translate('You must change your password first!')}</h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="oldPassword">
                                    <Form.Label>{translate('Old password')}</Form.Label>
                                    <Form.Control
                                        isInvalid={passwordInvalid}
                                        type="password"
                                        name="oldPassword"
                                        value={oldPassword}
                                        onChange={this.handleChangePassword}
                                        placeholder="Enter password"/>
                                </Form.Group>

                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group controlId="password1">
                                    <Form.Label>{translate('Password')}</Form.Label>
                                    <Form.Control
                                        isInvalid={passwordInvalid}
                                        type="password"
                                        name="password1"
                                        value={password1}
                                        onChange={this.handleChangePassword}
                                        placeholder="Enter password"/>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="password2">
                                    <Form.Label>{translate('Repeat password')}</Form.Label>
                                    <Form.Control
                                        isInvalid={passwordInvalid}
                                        type="password"
                                        name="password2"
                                        value={password2}
                                        onChange={this.handleChangePassword}
                                        placeholder="Re-enter password"/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button
                                    onClick={() => this.handleSubmit()}
                                    className="d-none d-sm-inline-block btn btn-xs btn-dark shadow-sm">Change</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>

            </Container>
        );
    }
}

const
    mapStateToProps = ({userData}) => {
        return {userData};
    };

const
    mapDispatchToProps = (dispatch) => {
        return bindActionCreators({
            clearUser: clearUser()
        }, dispatch);
    };

export default compose(
    withTranslation(),
    withTLService(),
    connect(mapStateToProps, mapDispatchToProps)
)(MustChangePassword);
